import { ModalAction as Modal } from '@get-e/react-components';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';

const useStyles = makeStyles({ confirmButtonDeactivate: { backgroundColor: COLORS.RED } });

interface ConfirmToggleVehicleActivationModalProps {
    id: number;
    isDeactivated: boolean;
    onClose: () => void;
    onToggle: (id: number, isDeactivated: boolean) => void;
    isLoading?: boolean;
}

const ConfirmToggleVehicleActivationModal = ({
    id,
    isDeactivated,
    onClose,
    onToggle,
    isLoading,
}: ConfirmToggleVehicleActivationModalProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={() => onToggle(id, isDeactivated)}
            title={t(
                isDeactivated ? 'modals.toggleVehicleActivation.titleActivate' : 'modals.toggleVehicleActivation.titleDeactivate'
            )}
            confirmButtonLabel={t(isDeactivated ? 'buttonName.activate' : 'buttonName.deactivate')}
            cancelButtonLabel={t('buttonName.cancel')}
            maxWidth="sm"
            confirmButtonClassName={clsx({ [classes.confirmButtonDeactivate]: !isDeactivated })}
            isDisabled={isLoading}
        >
            <Typography marginBottom="2rem">
                {t(
                    isDeactivated
                        ? 'modals.toggleVehicleActivation.descriptionActivate'
                        : 'modals.toggleVehicleActivation.descriptionDeactivate'
                )}
            </Typography>
            <Typography fontWeight="700">{t('areYouSureToProceed')}</Typography>
        </Modal>
    );
};

export default ConfirmToggleVehicleActivationModal;
