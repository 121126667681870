import { Box, Grid } from '@mui/material';
import React, { useContext, useMemo } from 'react';

import GoogleMap from '../../../../components/navigation/GoogleMap';
import { COLORS } from '../../../../constants/colors';
import { getRidePoints } from '../../../../helpers/googleMaps/getRidePoints';
import { RideContext } from '../context/RideContext';
import RideSummary from './rideSummaryCard/components/RideSummary';

interface RideMapCardProps {
    mapKey: number;
    isVisible?: boolean;
    marginBottom?: string;
}

const RideMapCard = ({ mapKey, isVisible, marginBottom: marginBotton }: RideMapCardProps) => {
    const { rideResponse } = useContext(RideContext);

    const points = useMemo(() => {
        return getRidePoints({
            pickupLatitude: rideResponse?.pickUp?.location?.coordinates?.lat,
            pickupLongitude: rideResponse?.pickUp?.location?.coordinates?.lon,
            dropOffLatitude: rideResponse?.dropOff?.location?.coordinates?.lat,
            dropOffLongitude: rideResponse?.dropOff?.location?.coordinates?.lon,
        });
    }, [
        rideResponse?.dropOff?.location?.coordinates?.lat,
        rideResponse?.dropOff?.location?.coordinates?.lon,
        rideResponse?.pickUp?.location?.coordinates?.lat,
        rideResponse?.pickUp?.location?.coordinates?.lon,
    ]);

    if (!isVisible) {
        return null;
    }

    return (
        <Box marginBottom={marginBotton ?? 0}>
            <Grid
                item
                style={{
                    height: '250px',
                    width: '100%',
                    background: COLORS.EXTRA_LIGHT_GRAY,
                }}
            >
                <GoogleMap
                    key={mapKey}
                    points={points}
                    placeIds={[rideResponse?.pickUp?.location?.id ?? '', rideResponse?.dropOff?.location?.id ?? '']}
                    subscribeChannelId={`private-trip-${rideResponse?.prettifiedUnid}`}
                    driverPosition={rideResponse?.lastPositionUpdate}
                />
            </Grid>
            <RideSummary distanceInMeters={rideResponse?.distanceInMeters} durationInSeconds={rideResponse?.durationInSeconds} />
        </Box>
    );
};

export default RideMapCard;
