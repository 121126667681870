import CarRepairIcon from '@mui/icons-material/CarRepair';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreateIcon from '@mui/icons-material/Create';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import { Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GridRowParams, GridActionsCellItem } from '@mui/x-data-grid-pro';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import useDataGridStyles from '../../../styles/DataGrid';
import theme from '../../../styles/theme';
import { ChangeStatus, Vehicle } from '../api/types';
import { colorOptions } from '../colorOptions';
import ColorCircle from '../colorOptions/ColorCircle';
import SmallVehicleImage from '../components/SmallVehicleImage';

const useStyles = makeStyles({ redMenuItem: { color: COLORS.RED } });

const COLUMN_WIDTH = 200;
const COLUMN_MOBILE_WIDTH = 150;

export const useVehiclesColumns = (
    onToggleStatus: (vehicle: Vehicle) => void,
    onEdit: (vehicle: Vehicle) => void,
    onDuplicateClick: (vehicle: Vehicle) => void
) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dataGridClases = useDataGridStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const onActionItemClick = useCallback(
        (vehicle: Vehicle, action: ChangeStatus) => {
            switch (action) {
                case ChangeStatus.EDIT:
                    onEdit(vehicle);
                    break;
                case ChangeStatus.DUPLICATE:
                    onDuplicateClick(vehicle);
                    break;
                case ChangeStatus.DEACTIVATE:
                    onToggleStatus(vehicle);
                    break;
                case ChangeStatus.ACTIVATE:
                    onToggleStatus(vehicle);
                    break;
                default:
                    throw new Error('Driver action not supported');
            }
        },
        [onDuplicateClick, onEdit, onToggleStatus]
    );

    return useMemo(
        () => [
            {
                field: 'photo',
                headerName: '',
                width: 42,
                renderCell: (params: GridRowParams) => (
                    <SmallVehicleImage
                        photo={
                            (params.row as Vehicle).pictures.find(el => el.isDefault)?.url ||
                            (params.row as Vehicle).pictures?.[0]?.url
                        }
                        boxSize="36px"
                    />
                ),
            },
            {
                field: 'licensePlate',
                headerName: t('licensePlate'),
                minWidth: isMobile ? COLUMN_MOBILE_WIDTH : COLUMN_WIDTH,
                flex: 1,
            },
            {
                field: 'brand',
                headerName: t('brand'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRowParams) => <Typography>{params.row.brand?.name ?? '-'}</Typography>,
            },
            {
                field: 'model',
                headerName: t('model'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRowParams) => <Typography>{params.row.model?.name ?? '-'}</Typography>,
            },
            {
                field: 'color',
                headerName: t('color'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRowParams) => {
                    return (
                        <ColorCircle
                            option={colorOptions.find(el => el.id === params.row?.color) || colorOptions[0]}
                            paddingBox="0"
                        />
                    );
                },
            },
            {
                field: 'identifier',
                headerName: t('identifier'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRowParams) => <Typography>{params.row.identifier || '-'}</Typography>,
            },
            {
                field: 'status',
                headerName: t('status'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRowParams) => (
                    <Typography>{params.row.isArchived ? t('archived') : t('active')}</Typography>
                ),
            },
            {
                field: 'actions',
                type: 'actions',
                width: 20,
                headerClassName: dataGridClases.hideSeparator,
                getActions: (params: GridRowParams) => {
                    const vehicle = params.row as Vehicle;

                    return [
                        <GridActionsCellItem
                            key={ChangeStatus.EDIT}
                            className={dataGridClases.menuItem}
                            icon={<CreateIcon style={{ color: COLORS.BLUE_DARK }} />}
                            label={t('buttonName.edit')}
                            onClick={() => onActionItemClick(vehicle, ChangeStatus.EDIT)}
                            showInMenu
                            nonce=""
                        />,
                        <GridActionsCellItem
                            key={ChangeStatus.DUPLICATE}
                            className={dataGridClases.menuItem}
                            label={t('duplicate')}
                            onClick={() => onActionItemClick(vehicle, ChangeStatus.DUPLICATE)}
                            showInMenu
                            icon={<ContentCopyIcon fontSize="small" style={{ fill: COLORS.BLUE_DARK }} />}
                        />,
                        <GridActionsCellItem
                            key={vehicle.isArchived ? ChangeStatus.ACTIVATE : ChangeStatus.DEACTIVATE}
                            className={vehicle.isArchived ? dataGridClases.menuItem : classes.redMenuItem}
                            icon={
                                vehicle.isArchived ? (
                                    <NoCrashIcon style={{ color: COLORS.BLUE_DARK }} />
                                ) : (
                                    <CarRepairIcon style={{ color: COLORS.RED }} />
                                )
                            }
                            label={t(vehicle.isArchived ? 'buttonName.activate' : 'buttonName.deactivate')}
                            onClick={() =>
                                onActionItemClick(vehicle, vehicle.isArchived ? ChangeStatus.ACTIVATE : ChangeStatus.DEACTIVATE)
                            }
                            showInMenu
                            nonce=""
                        />,
                    ];
                },
            },
        ],
        [t, isMobile, dataGridClases.hideSeparator, dataGridClases.menuItem, classes.redMenuItem, onActionItemClick]
    );
};
