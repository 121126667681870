import { useQuery } from 'react-query';

import { USE_FILES } from '../../../constants/queryKeys';
import apiClient from '../../../services/api';
import { FILES } from '../../../services/routes';
import { FileItem } from './types';

export function useFiles() {
    const { data, isLoading } = useQuery([USE_FILES], () => getFiles(), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 500,
    });

    return {
        data: data ?? [],
        isLoading,
    };
}

export const getFiles = async (): Promise<FileItem[]> => {
    const { data } = await apiClient.get<FileItem[]>(FILES);

    return data;
};
