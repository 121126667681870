import React, { FunctionComponent } from 'react';

import iframeStyles from '../../styles/Iframe';
import UnexpectedError from '../errorPages/UnexpectedError';
import { useBookingTool } from './api/useBookingTool';
import BookingToolSkeleton from './components/BookingToolSkeleton';

const BookingTool: FunctionComponent = () => {
    const classes = iframeStyles();

    const { data, isLoading, isFetching, isError, refetch } = useBookingTool();

    if (isLoading || isFetching || !data) {
        return <BookingToolSkeleton />;
    }

    if (isError) {
        return <UnexpectedError onRetry={refetch} />;
    }

    const handleIfrmeLoad = () => {
        const iframe = document.getElementById('booking-tool-iframe') as HTMLIFrameElement | null;
        const iWindow = iframe ? (iframe.contentWindow as Window) : null;
        const iDocument = iWindow ? iWindow.document : null;

        const chatButton = iDocument ? (iDocument.querySelector('[aria-label="Chat"]') as HTMLElement) : null;

        if (chatButton) {
            chatButton.style.cssText = 'display:none !important';
        }
    };

    return (
        <iframe
            id="booking-tool-iframe"
            className={classes.iframe}
            src={data.iframeUrl}
            title="New ride"
            data-testid="booking-tool-iframe"
            onLoad={handleIfrmeLoad}
        />
    );
};

export default BookingTool;
