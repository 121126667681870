import { SecondaryButton } from '@get-e/react-components';
import { Grid, Link, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import config from '../../config';

import car from '../../public/assets/icons/car.svg';
import useButtonStyles from '../../styles/Button';
import containerStyles from '../../styles/Container';
import typographyStyles from '../../styles/Typography';

const LogInMessage: FunctionComponent = () => {
    const classes = useButtonStyles();
    const typeClasses = typographyStyles();
    const containerClasses = containerStyles();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={containerClasses.fullHeight}
            data-testid="log-in-again"
        >
            <Grid item>
                <img src={car} alt={'Something went wrong!'} />
            </Grid>
            <Grid item style={{ margin: '1em 0' }}>
                <Typography variant="body2" align="center">
                    Something went wrong!
                </Typography>
                <Typography variant="body2" align="center" className={typeClasses.subtitle} style={{ whiteSpace: 'pre-line' }}>
                    <Link href={'mailto:operations@get-e.com'}>operations@get-e.com</Link>
                    <Link href={'tel:+44 203 856 8655'}>+44 203 856 8655</Link>
                </Typography>
                <br />
            </Grid>
            <Grid item>
                <SecondaryButton onClick={() => window.location.reload()} className={classes.inlineButtonMargin}>
                    Refresh page
                </SecondaryButton>
                <SecondaryButton
                    onClick={() => {
                        window.location.href = config.logOutUrl;
                        localStorage.clear();
                        sessionStorage.clear();
                    }}
                >
                    Log in again
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default LogInMessage;
