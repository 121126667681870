import { TextField } from '@get-e/react-components';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RidesContext } from '../context/RidesContext';

interface SearchInputProps {
    onChange?: (value: string) => void;
}

const SearchInput = ({ onChange }: SearchInputProps) => {
    const { t } = useTranslation();

    const { searchPhrase } = useContext(RidesContext);

    return (
        <TextField
            id="searchbyTravellerReferenceRideNumber"
            value={searchPhrase}
            autoComplete="off"
            noHelperTextSpace
            label={t('pages.rides.searchByPassengerDriver')}
            name="search"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            onChange={event => {
                onChange && onChange(event.target.value);
            }}
        />
    );
};

export default React.memo(SearchInput, () => true);
