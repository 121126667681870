/* eslint-disable @typescript-eslint/no-explicit-any */
import * as sessionReplay from '@amplitude/session-replay-browser';
import amplitude from 'amplitude-js';

import config from '../config';

export const initAmplitude = () => {
    amplitude.getInstance().init(config.amplitudeApi ?? '');
};

export const setAmplitudeUserId = (userId: string) => {
    amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties: any) => {
    amplitude.getInstance().setUserProperties(properties);
};

export const logAmplitudeEvent = (event: any, eventProperties = {}) => {
    sessionReplay.init(config.amplitudeApi, {
        deviceId: amplitude.getInstance().getDeviceId(),
        sessionId: amplitude.getInstance().getSessionId(),
    });

    // Call whenever the session id changes
    sessionReplay.setSessionId(amplitude.getInstance().getSessionId());

    /*
     * When you send events to Amplitude, call this event to get
     * The most up to date session replay properties for the event
     */
    const sessionReplayProperties = sessionReplay.getSessionReplayProperties();

    amplitude.getInstance().logEvent(event, {
        ...eventProperties,
        ...sessionReplayProperties,
    });
};
