/* eslint-disable max-depth */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField, ModalAction as Modal } from '@get-e/react-components';
import Crop169Icon from '@mui/icons-material/Crop169';
import PinIcon from '@mui/icons-material/Pin';
import { Autocomplete, Grid, InputAdornment, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { random } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import {
    ADD_OR_EDIT_NEW_VEHICLE_ADD_UPDATE_BUTTON,
    ADD_OR_EDIT_NEW_VEHICLE_REMOVE_PHOTO_BUTTON,
    ADD_OR_EDIT_VEHICLE_BRAND_INPUT,
    ADD_OR_EDIT_VEHICLE_COLOR_INPUT,
    ADD_OR_EDIT_VEHICLE_IDENTIFIER_INPUT,
    ADD_OR_EDIT_VEHICLE_LICENSE_PLATE_INPUT,
    ADD_OR_EDIT_VEHICLE_MODEL_INPUT,
    ADD_OR_EDIT_VEHICLE_PHOTO_INPUT,
} from '../../../constants/amplitude/supplierKeys';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { InputError } from '../../../helpers/inputValidation/InputError';
import { IS_LETTER_AND_NUMBER } from '../../../helpers/inputValidation/regexRules';
import and from '../../../helpers/inputValidation/validators/and';
import isFilledString from '../../../helpers/inputValidation/validators/isFilledString';
import isOnlyLettersAndNumbersString from '../../../helpers/inputValidation/validators/isOnlyLettersAndNumbersString';
import theme from '../../../styles/theme';
import { useUserProfile } from '../../users/api';
import { useBrands, useModels, createVehicle, useFindVehicleByLicensePlate, updateVehicle } from '../api';
import { Entity, ImageUrl, Vehicle } from '../api/types';
import { useUploadedImageUrls } from '../api/useUploadedImageUrls';
import { ColorOption, colorOptions } from '../colorOptions';
import ColorCircle from '../colorOptions/ColorCircle';
import { getUnionPicturesToUploadByUrl } from './imageUploader/helpers';
import ImageUploader from './imageUploader/ImageUploader';

const useStyles = makeStyles(() => ({
    formField: {
        marginBottom: '1rem',
        width: '100%',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
}));

interface AddVehicleErrors {
    licensePlate: InputError | null;
    color: InputError | null;
}

interface AddVehicleModalProps {
    onClose: () => void;
    onSuccess: () => void;
    isOpen: boolean;
    vehicle?: Vehicle;
}

const AddVehicleModal = ({ onClose, isOpen, vehicle, onSuccess }: AddVehicleModalProps) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();
    const { t } = useTranslation();
    const { data: userProfile } = useUserProfile();
    const { showNotification } = useNotificationContext();

    const [searchBrandTerm, setBrandTerm] = useState('');
    const [searchModelTerm, setModelTerm] = useState('');
    const [files, setFiles] = useState<string[]>([]);
    const [isDone, setIsDone] = useState(false);
    const [isLoadingImages, setIsLoadingImages] = useState(false);
    const [newPictures, setNewPictures] = useState<string[]>(vehicle?.pictures?.map(el => el.url) ?? []);

    const [values, setValues] = useState<Vehicle>(
        vehicle ?? {
            id: random(),
            licensePlate: '',
            brand: null,
            model: null,
            color: undefined,
            identifier: '',
            pictures: [],
            status: '',
        }
    );

    const [formErrors, setFormErrors] = useState<AddVehicleErrors>({
        licensePlate: null,
        color: null,
    });

    const { data: brandOptions, isLoading: isLoadingBrands } = useBrands(searchBrandTerm);

    const { data: isVehicleFounded } = useFindVehicleByLicensePlate(
        values.licensePlate,
        vehicle?.licensePlate !== values.licensePlate
    );

    const { data: modelOptions, isLoading: isLoadingModels } = useModels(searchModelTerm, values.brand?.id);

    const { mutate: createVehicleMutation, isLoading: isLoadingCreate } = useMutation(createVehicle, {
        onSuccess: () => {
            showNotification(t('pages.vehicles.alert.success'), Severity.Info);
            onSuccess();
        },
        onError: (error: any) => {
            if (error.response?.data?.errors?.licensePlate[0]?.code === 'UNIQUE_VIOLATION') {
                setFormErrors({
                    ...formErrors,
                    licensePlate: InputError.LicensePlateAlreadyTaken,
                });
            } else {
                showNotification(t('pages.vehicles.alert.error'), Severity.Error);
            }
        },
    });

    const { mutate: editVehicleMutation, isLoading: isLoadingEdit } = useMutation(updateVehicle, {
        onSuccess: () => {
            showNotification(t('pages.vehicles.alert.successUpdate'), Severity.Info);
            onSuccess();
        },
        onError: (error: any) => {
            if (error.response?.data?.errors?.licensePlate[0]?.code === 'UNIQUE_VIOLATION') {
                setFormErrors({
                    ...formErrors,
                    licensePlate: InputError.LicensePlateAlreadyTaken,
                });
            } else {
                showNotification(t('pages.vehicles.alert.errorUpdate'), Severity.Error);
            }
        },
    });

    const validateFields = (): boolean => {
        const validated = {
            licensePlate: and(isFilledString(values.licensePlate, InputError.Empty), () =>
                isOnlyLettersAndNumbersString(values.licensePlate, InputError.OnlyLettersAndNumbersAllowed)
            ),
            color: isFilledString((values.color as ColorOption)?.name ?? '', InputError.Empty),
        };

        const fieldErros: AddVehicleErrors = {
            licensePlate: validated.licensePlate.isValid ? null : validated.licensePlate.error,
            color: validated.color.isValid ? null : validated.color.error,
        };

        const isValid = Object.values(fieldErros).every(error => error === null);

        !isValid && setFormErrors(fieldErros);

        return isValid;
    };

    const resultsOfImages = useUploadedImageUrls(isDone, files);

    useEffect(() => {
        if (isVehicleFounded && formErrors.licensePlate !== InputError.LicensePlateAlreadyTaken && !vehicle) {
            setFormErrors({
                ...formErrors,
                licensePlate: InputError.LicensePlateAlreadyTaken,
            });
        } else {
            setFormErrors({
                ...formErrors,
                licensePlate: null,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVehicleFounded]);

    useEffect(() => {
        setIsLoadingImages(resultsOfImages.some(result => result.isLoading || result.isFetching));

        if (isDone) {
            const allSettled = resultsOfImages.every(result => result.isSuccess || result.isError);

            if (allSettled) {
                const successfulUploads = resultsOfImages
                    .filter(result => result.isSuccess)
                    .map(result => result.data) as ImageUrl[];

                const failureUploads = resultsOfImages.filter(result => result.isError);

                // Is editing or creating new
                if (vehicle && failureUploads?.length === 0) {
                    const result = getUnionPicturesToUploadByUrl(newPictures, vehicle?.pictures);
                    const newArrayOfPictures = result.concat(successfulUploads as any);

                    if (newArrayOfPictures.length > 0) {
                        if (!newArrayOfPictures.find(el => el?.isDefault)) {
                            newArrayOfPictures[0].isDefault = true;
                        }
                    }

                    editVehicleMutation({
                        id: vehicle.id,
                        licensePlate: values.licensePlate,
                        identifier: values.identifier,
                        color: (values?.color as ColorOption)?.id,
                        pictures: newArrayOfPictures,
                        ...(Boolean(values.brand?.id) && { brandId: values.brand?.id }),
                        ...(Boolean(values.model?.id) && { modelId: values.model?.id }),
                    });
                } else if (failureUploads?.length === 0) {
                    if (successfulUploads.length > 0) {
                        successfulUploads[0].isDefault = true;
                    }

                    createVehicleMutation({
                        licensePlate: values.licensePlate,
                        identifier: values.identifier,
                        color: (values?.color as ColorOption)?.id,
                        pictures: successfulUploads,
                        ...(Boolean(values.brand?.id) && { brandId: values.brand?.id }),
                        ...(Boolean(values.model?.id) && { modelId: values.model?.id }),
                    });
                } else {
                    showNotification(t('pages.vehicles.alert.error'), Severity.Error);
                }

                setIsDone(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultsOfImages, isDone, createVehicleMutation, values]);

    const handleSubmit = () => {
        if (!validateFields()) {
            return;
        }

        setIsDone(true);
        logAmplitudeEvent(ADD_OR_EDIT_NEW_VEHICLE_ADD_UPDATE_BUTTON);
    };

    const handleChange = <T extends keyof Vehicle>(key: T, newValue: Vehicle[T]): void => {
        if (key === 'brand') {
            setValues({
                ...values,
                brand: newValue as Entity,
                model: undefined,
            });
        } else {
            setValues({
                ...values,
                [key]: newValue,
            });
        }

        if (key === 'licensePlate') {
            const value = newValue as string;

            if (value.length > 10) {
                setFormErrors({
                    ...formErrors,
                    licensePlate: InputError.MaxLengthExceeded,
                });
            } else if (!IS_LETTER_AND_NUMBER.test(value)) {
                setFormErrors({
                    ...formErrors,
                    licensePlate: InputError.OnlyLettersAndNumbersAllowed,
                });
            } else {
                setFormErrors(prevStateForm => ({
                    ...prevStateForm,
                    [key]: null,
                }));
            }
        } else {
            setFormErrors(prevStateForm => ({
                ...prevStateForm,
                [key]: null,
            }));
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title={t(vehicle && vehicle.id !== -1 ? 'pages.vehicles.editVehicle' : 'pages.vehicles.addNewVehicle')}
            confirmButtonLabel={t(vehicle ? 'buttonName.update' : 'buttonName.add')}
            cancelButtonLabel={t('buttonName.cancel')}
            maxWidth="md"
            fullWidth
            isDisabled={isLoadingImages || isLoadingCreate || isLoadingEdit}
            isFullScreen={isMobile}
        >
            <Grid container display="flex" flexDirection="column">
                <Grid display="flex" flexDirection={'column'}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.formField}
                            error={formErrors.licensePlate !== null}
                            helperText={formErrors.licensePlate ? t(formErrors.licensePlate) : null}
                            label={t('licensePlate')}
                            name="licensePlate"
                            onChange={event => {
                                handleChange('licensePlate', event.target.value.toUpperCase());
                            }}
                            required
                            autoComplete="off"
                            value={values.licensePlate}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Crop169Icon />
                                    </InputAdornment>
                                ),
                                inputProps: { maxLength: 10 },
                            }}
                            onBlur={() => logAmplitudeEvent(ADD_OR_EDIT_VEHICLE_LICENSE_PLATE_INPUT)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        columnGap={2}
                        rowGap={1.5}
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                        marginBottom=".75rem"
                    >
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                loading={isLoadingBrands}
                                onChange={(_event, newValue) => {
                                    handleChange('brand', newValue);
                                    logAmplitudeEvent(ADD_OR_EDIT_VEHICLE_BRAND_INPUT);
                                }}
                                options={brandOptions ?? []}
                                getOptionLabel={(option: Entity) => option?.name}
                                value={values.brand}
                                renderInput={params => <TextField {...params} label={t('brand')} autoComplete="off" />}
                                noOptionsText={
                                    <a
                                        href={
                                            'mailto:operations@get-e.com?' +
                                            'subject=Add%20a%20vehicle%20brand%20and%20model%20&body=Hello%20Get-e%20team!' +
                                            "%0A%0AI'm%20requesting%20to%20add%20a%20new%20vehicle%20brand%20or%20model%3A%20" +
                                            `%0A%0ABrand%3A%20${encodeURIComponent(searchBrandTerm)}%20` +
                                            '%0AModel%3A%20%5B%5D%20' +
                                            '%0A%0ABest%20regards,%20' +
                                            `%0A%20${encodeURIComponent(userProfile?.fullName || '')}%20` +
                                            `%0A%20${encodeURIComponent(userProfile?.accountName || '')}`
                                        }
                                    >
                                        {t('pages.vehicles.emailSupportToAddBrand')} <q>{searchBrandTerm}</q>
                                    </a>
                                }
                                onInputChange={(_, newValue) => setBrandTerm(newValue)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                loading={isLoadingModels}
                                onChange={(_event, newValue) => {
                                    handleChange('model', newValue);
                                    logAmplitudeEvent(ADD_OR_EDIT_VEHICLE_MODEL_INPUT);
                                }}
                                options={modelOptions ?? []}
                                getOptionLabel={(option: Entity) => option?.name}
                                value={values.model}
                                renderInput={params => <TextField {...params} label={t('model')} autoComplete="off" />}
                                onInputChange={(_, newValue) => setModelTerm(newValue)}
                                noOptionsText={
                                    <a
                                        href={
                                            'mailto:operations@get-e.com?' +
                                            'subject=Add%20a%20vehicle%20brand%20and%20model%20&body=Hello%20Get-e%20team!' +
                                            "%0A%0AI'm%20requesting%20to%20add%20a%20new%20vehicle%20brand%20or%20model%3A%20" +
                                            `%0A%0ABrand%3A%20${encodeURIComponent(values?.brand?.name || ('' as any))}` +
                                            `%0AModel%3A%20${encodeURIComponent(searchModelTerm)}%20` +
                                            '%0A%0ABest%20regards,%20' +
                                            `%0A%20${encodeURIComponent(userProfile?.fullName || '')}%20` +
                                            `%0A%20${encodeURIComponent(userProfile?.accountName || '')}`
                                        }
                                    >
                                        {t('pages.vehicles.emailSupportToAddBrand')} <q>{searchModelTerm}</q>
                                    </a>
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} marginBottom=".75rem">
                        <Autocomplete
                            disableClearable
                            onChange={(_event, newValue) => {
                                handleChange('color', newValue);
                                logAmplitudeEvent(ADD_OR_EDIT_VEHICLE_COLOR_INPUT);
                            }}
                            options={colorOptions}
                            getOptionLabel={(option: ColorOption) => t(option?.name)}
                            value={values.color as ColorOption}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={t('color')}
                                    placeholder={t('pages.vehicles.selectColor')}
                                    required
                                    error={formErrors.color !== null}
                                    helperText={formErrors.color ? t(formErrors.color) : null}
                                    autoComplete="off"
                                />
                            )}
                            renderOption={(prop, option: ColorOption) => {
                                return <ColorCircle props={prop} option={option} key={option.id} />;
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.formField}
                            label={t('identifier')}
                            name="identifier"
                            onChange={event => handleChange('identifier', event.target.value)}
                            autoComplete="off"
                            value={values.identifier}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <PinIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onBlur={() => logAmplitudeEvent(ADD_OR_EDIT_VEHICLE_IDENTIFIER_INPUT)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ImageUploader
                            setFiles={setFiles}
                            isLoading={isLoadingImages}
                            defaultImages={vehicle?.pictures
                                ?.sort((item1, item2) => (item2.isDefault ? 1 : 0) - (item1.isDefault ? 1 : 0))
                                ?.map(el => el.url)}
                            setImagesChange={setNewPictures}
                            logPhotoInputEvent={() => logAmplitudeEvent(ADD_OR_EDIT_VEHICLE_PHOTO_INPUT)}
                            logPhotoDeleteEvent={() => logAmplitudeEvent(ADD_OR_EDIT_NEW_VEHICLE_REMOVE_PHOTO_BUTTON)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default AddVehicleModal;
