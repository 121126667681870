import { PrimaryButton, SecondaryButton, SignedOutLayout, TextField } from '@get-e/react-components';
import { Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import config from '../../config';
import { COLORS } from '../../constants/colors';
import { Severity, useNotificationContext } from '../../context/NotificationContext';
import { InputError } from '../../helpers/inputValidation/InputError';
import allValid from '../../helpers/inputValidation/validators/allValid';
import and from '../../helpers/inputValidation/validators/and';
import isEmail from '../../helpers/inputValidation/validators/isEmail';
import isFilledString from '../../helpers/inputValidation/validators/isFilledString';
import { logoIconLightBackground } from '../../public/assets/images';
import theme from '../../styles/theme';
import { forgotPassword } from './api';

const useStyles = makeStyles({
    container: { minHeight: '100%' },
    emailField: { margin: '2rem 0' },
    buttons: { width: '45%' },
    signInButton: {
        width: '100%',
        marginTop: '2rem',
    },
    logoContainer: {
        display: 'block',
        margin: '0 auto',
        maxWidth: 110,
        marginBottom: '2rem',
    },
});

const ForgotPassword: FunctionComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const [isSuccessCardVisible, setIsSuccessCardVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<InputError | null>(null);
    const { showNotification } = useNotificationContext();

    useEffect(() => {
        setIsSuccessCardVisible(false);
    }, []);

    const { mutate: forgotPasswordMutation, isLoading } = useMutation(forgotPassword, {
        onSuccess: () => {
            setIsSuccessCardVisible(true);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: any) => {
            showNotification(error?.response?.data?.message || t('errors.defaultError'), Severity.Error);
        },
    });

    const validateFormFields = (): boolean => {
        const validated = {
            email: and(isFilledString(email, InputError.EmptyEmail), () => isEmail(email, InputError.InvalidEmail)),
        };

        if (!allValid(validated)) {
            setEmailError(validated.email.isValid ? null : validated.email.error);

            return false;
        }

        return true;
    };

    const handleSend = () => {
        if (validateFormFields()) {
            forgotPasswordMutation({ email });
        }
    };

    const handelBack = () => {
        history.goBack();
    };

    const handleSignIn = () => {
        window.location.href = config.signInUrl;
    };

    return (
        <SignedOutLayout linkPrefix={t('visit').toLowerCase()}>
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.container}>
                <Card variant="outlined" sx={{ width: isMobile ? '100%' : '500px' }}>
                    {!isSuccessCardVisible && (
                        <CardContent sx={{ padding: isMobile ? '1rem' : '2rem' }}>
                            <Grid container direction="row" alignItems="center">
                                <img src={logoIconLightBackground} alt={'Logo'} className={classes.logoContainer} />
                            </Grid>
                            <Typography variant="h2" component="h2" color={COLORS.BLUE_TEXT}>
                                {t('pages.forgotPassword.forgotYourPassword')}
                            </Typography>
                            <Typography>{t('pages.forgotPassword.pleaseEnterTheEmail')}</Typography>
                            <TextField
                                className={classes.emailField}
                                value={email}
                                onChange={event => {
                                    setEmail(event.target.value);
                                    setEmailError(null);
                                }}
                                label={t('email')}
                                type="text"
                                autoComplete="email"
                                name="email"
                                error={emailError !== null}
                                helperText={emailError ? t(emailError) : undefined}
                                margin="normal"
                                required
                            />

                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <PrimaryButton onClick={handleSend} className={classes.buttons} loading={isLoading}>
                                    {t('buttonName.send')}
                                </PrimaryButton>
                                <SecondaryButton onClick={handelBack} className={classes.buttons}>
                                    {t('buttonName.back')}
                                </SecondaryButton>
                            </Grid>
                        </CardContent>
                    )}
                    {isSuccessCardVisible && (
                        <CardContent sx={{ padding: isMobile ? '1rem' : '2rem' }}>
                            <Grid container direction="row" alignItems="center">
                                <img src={logoIconLightBackground} alt={'Logo'} className={classes.logoContainer} />
                            </Grid>
                            <Typography variant="h2" component="h2" color={COLORS.BLUE_TEXT}>
                                {t('pages.forgotPassword.yourPasswordHasBeenReset')}
                            </Typography>
                            <Typography>{t('pages.forgotPassword.yourEmailNotInOurSystem')} </Typography>
                            <Typography marginTop="1rem">{t('pages.forgotPassword.pleaseCheckYourInbox')}</Typography>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <PrimaryButton onClick={handleSignIn} className={classes.signInButton}>
                                    {t('buttonName.signIn')}
                                </PrimaryButton>
                            </Grid>
                        </CardContent>
                    )}
                </Card>
            </Grid>
        </SignedOutLayout>
    );
};

export default ForgotPassword;
