import { TertiaryButton } from '@get-e/react-components';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useRidesStyles from './Rides.styles';

export const ResetFilterButton = ({ handleResetFilters }: { handleResetFilters: () => void }) => {
    const classes = useRidesStyles();
    const { t } = useTranslation();

    return (
        <TertiaryButton className={classes.resetFilters} onClick={handleResetFilters}>
            <Box display="flex" alignItems="center">
                <TuneOutlinedIcon style={{ paddingRight: '0.5rem' }} />
                {t('buttonName.resetFilters')}
            </Box>
        </TertiaryButton>
    );
};
