import { Validated } from '../Validator';

export default function isNotNull<TValue>(value: TValue | null): Validated<TValue, unknown>;

export default function isNotNull<TValue, TError>(value: TValue | null, error: TError): Validated<TValue, TError>;

export default function isNotNull<TValue, TError = unknown>(
    value: TValue | null,
    error?: TError
): Validated<TValue, TError | unknown> {
    return value === null
        ? {
              isValid: false,
              error: error ?? undefined,
          }
        : {
              isValid: true,
              value,
          };
}
