const RIDE_ID_URLS = [];

const IFRAME_URL = ['^\\/booking-tool$', '^\\/pending-changes$', ...RIDE_ID_URLS];

export const isIframeView = (pathname: string): boolean => {
    const regex = new RegExp(IFRAME_URL.join('|'), 'i');

    return regex.test(pathname);
};

export const isRideIdView = (pathname: string): boolean => {
    const regex = new RegExp(RIDE_ID_URLS.join('|'), 'i');

    return regex.test(pathname);
};
