import { useQuery } from 'react-query';

import apiClient from '../../../services/api';
import { getSupplierInvoicesRoute } from '../../../services/routes';
import { GetSupplierInvoicesResponse } from './types';

const getSupplierInvoices = async (page): Promise<GetSupplierInvoicesResponse> => {
    const { data } = await apiClient.get<GetSupplierInvoicesResponse>(getSupplierInvoicesRoute(page));

    return data;
};

export const useSupplierInvoices = (page = 1) => {
    const { data, isError, isFetching } = useQuery(
        [getSupplierInvoicesRoute(page)],
        () => getSupplierInvoices(page),

        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 0,
        }
    );

    return {
        data: data?.data || [],
        meta: { lastPage: data?.meta.lastPage || 1 },
        isError,
        isFetching,
    };
};
