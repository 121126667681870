import { Typography, Box, Grid, Rating, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GridRowParams } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { COLORS } from '../../../../constants/colors';
import theme from '../../../../styles/theme';
import { FeedbackTopics } from '../../../ride/api/types';
import { getTopicLabel } from '../tabs/ReviewsTab';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '1rem .5rem .5rem .5rem',
        borderBottom: `1px solid ${COLORS.BLACK_12}`,
    },
    title: {
        color: COLORS.SLATE_GREY,
        fontWeight: 700,
    },
    ratingBox: {
        display: 'flex',
        alignItems: 'center',
    },
    serviceBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '.5rem',
    },
});

export const useReviewsDetailsContent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return useMemo(
        () =>
            ({ row }: GridRowParams) => (
                <Box className={classes.container}>
                    <Grid container spacing={isMobile ? 1 : 4}>
                        {isMobile && (
                            <>
                                <Grid item xs={12} display="flex">
                                    <Typography fontWeight="700">{t('rideNumber')}:&nbsp;</Typography>
                                    <Link to={'/'}>
                                        <Typography color={COLORS.BLUE}>{row.ride.rideNumber}</Typography>
                                    </Link>
                                </Grid>
                                <Grid item xs={12} display="flex">
                                    <Typography fontWeight="700">{t('driver')}:&nbsp;</Typography>
                                    <Typography>{row.ride.driver}</Typography>
                                </Grid>
                            </>
                        )}
                        <Grid item lg={5} md={5} xs={12}>
                            {row.topics?.map(({ topic, rate }: { topic: string; rate: number }, index: string) => (
                                <Box className={classes.serviceBox} key={index}>
                                    <Typography className={classes.title}>
                                        {getTopicLabel(topic as keyof typeof FeedbackTopics, t)}
                                    </Typography>
                                    <Box className={classes.ratingBox}>
                                        {rate?.toFixed(1)}
                                        <Rating name="ratingBox" value={rate ?? 0} readOnly sx={{ paddingLeft: '1rem' }} />
                                    </Box>
                                </Box>
                            ))}
                        </Grid>
                        <Grid item xs={12} md={7} lg={7}>
                            <Typography className={classes.title}>{t('comments')}</Typography>
                            {row.comments.map((comment: string, index: number) => (
                                <Box sx={{ padding: '.5rem .5rem 0 0' }} key={index}>
                                    {comment}
                                </Box>
                            ))}
                            {row.adminComment && (
                                <>
                                    <Typography className={classes.title}>{t('pages.reports.adminComment')}</Typography>
                                    <Box sx={{ padding: '.5rem .5rem 0 0' }}>{row.adminComment}</Box>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            ),
        [classes.container, classes.ratingBox, classes.serviceBox, classes.title, t, isMobile]
    );
};
