/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { Fragment } from 'react';

import GoogleMap from '../../../../components/navigation/GoogleMap';
import { RideStatus, getRideStatusKey } from '../../../../helpers/rideStatusUtil';
import { RideResponse } from '../../api/types';
import CancellationPoliciesCard from '../../components/CancellationPoliciesCard';
import DriverReferencesCard from '../../supplier/components/DriverReferencesCard';
import FlightInformationCard from '../components/FlightInformationCard';
import MeetingInstructionsCard from '../components/MeetingInstructionsCard';
import PassengerInformationCard from '../components/PassengerInformationCard';
import ReferencesCard from '../components/ReferencesCard';
import ReviewRideCard from '../components/ReviewRideCard';
import RideInformationCard from '../components/RideInformationCard';
import SelectedVehicleCard from '../components/SelectedVehicleCard';

export const renderDesktopView = (
    data: RideResponse | undefined,
    classes: any,
    refetch: () => any,
    showUtcTime: boolean,
    setShowUtcTime: (show: boolean) => void
) => {
    const defaultOrder = ['GoogleMap', 'SelectedVehicle', 'MeetingInstructions', 'DriverInformation', 'ReviewRide'];

    const componentOrder = {
        DRIVER_ON_THE_WAY: ['GoogleMap', 'DriverInformation', 'SelectedVehicle', 'MeetingInstructions', 'ReviewRide'],
        DRIVER_AT_PICKUP: ['GoogleMap', 'DriverInformation', 'SelectedVehicle', 'MeetingInstructions', 'ReviewRide'],
        PASSENGER_ON_BOARD: ['GoogleMap', 'DriverInformation', 'SelectedVehicle', 'MeetingInstructions', 'ReviewRide'],
        COMPLETED: ['GoogleMap', 'ReviewRide', 'SelectedVehicle', 'DriverInformation', 'MeetingInstructions'],
    };

    const currentOrder = componentOrder[data?.status || 'BOOKED'] || defaultOrder;

    const renderComponent = (component: string) => {
        switch (component) {
            case 'GoogleMap':
                return (
                    <Grid className={clsx(classes.contentContainer, classes.googleMapContainer)}>
                        <GoogleMap
                            points={[data?.pickUp?.location?.coordinates, data?.dropOff?.location?.coordinates]}
                            placeIds={[data?.pickUp?.location?.id ?? '', data?.dropOff.location.id ?? '']}
                            subscribeChannelId={`private-trip-${data?.prettifiedUnid}`}
                            driverPosition={data?.lastPositionUpdate}
                        />
                    </Grid>
                );
            case 'SelectedVehicle':
                return (
                    data?.vehicle && (
                        <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                            <SelectedVehicleCard data={data} isSelectedHeaderVisible />
                        </Grid>
                    )
                );
            case 'MeetingInstructions':
                return (
                    data?.pickupDescription && (
                        <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                            <MeetingInstructionsCard data={data} />
                        </Grid>
                    )
                );
            case 'DriverInformation':
                return (
                    data?.driver && (
                        <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                            <DriverReferencesCard data={data} refetch={refetch} isCustomerPage />
                        </Grid>
                    )
                );
            case 'ReviewRide':
                return (
                    data?.status === 'COMPLETED' &&
                    Boolean(data?.feedbacks?.length) && (
                        <Grid item xs className={classes.contentContainer} marginTop="1.5rem">
                            <ReviewRideCard feedbacks={data.feedbacks} />
                        </Grid>
                    )
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Grid xs={12} md={12} lg={7.5} item marginTop="1rem">
                <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                    <RideInformationCard data={data} showUtcSwitch showUtcTime={showUtcTime} setShowUtcTime={setShowUtcTime} />
                </Grid>
                <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                    <FlightInformationCard rideId={data?.unid || ''} showUtcTime={showUtcTime} />
                </Grid>
                {Boolean(data?.passengers?.length) && (
                    <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                        <PassengerInformationCard data={data} />
                    </Grid>
                )}
                <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                    <ReferencesCard data={data} />
                </Grid>
                {Boolean(data?.cancellationPolicies?.length) && (
                    <Grid
                        item
                        xs
                        display="flex"
                        justifyItems="flex-start"
                        className={clsx(classes.contentContainer, classes.cancellationPolicies)}
                    >
                        <CancellationPoliciesCard
                            isUtc
                            isVisible
                            cancelledAt={data?.cancelledAt || null}
                            cancellationPolicies={data?.cancellationPolicies ?? []}
                            status={data?.status || null}
                            pickUpDate={dayjs(data?.pickUp?.departAt).toDate()}
                            pickUpTime={dayjs(data?.pickUp?.departAt).toDate()}
                            vehicleType={data?.vehicle.type}
                            departureTimeZone={data?.pickUp.departureTimeZone}
                            appliedCancellationPolicy={data?.appliedCancellationPolicy}
                            order={0}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} md={12} lg={4.5} width="100%" marginTop="1rem">
                {currentOrder?.map((component: string) => {
                    return <Fragment key={component}>{renderComponent(component)}</Fragment>;
                })}
            </Grid>
        </>
    );
};

export const renderMobileView = (
    data: RideResponse | undefined,
    classes: any,
    refetch: () => any,
    showUtcTime: boolean,
    setShowUtcTime: (show: boolean) => void
) => {
    return (
        <>
            <Grid item xs>
                <Grid className={clsx(classes.contentContainer, classes.googleMapContainerMobile)}>
                    <GoogleMap
                        points={[data?.pickUp?.location?.coordinates, data?.dropOff?.location?.coordinates]}
                        placeIds={[data?.pickUp?.location?.id ?? '', data?.dropOff.location.id ?? '']}
                        subscribeChannelId={`private-trip-${data?.prettifiedUnid}`}
                        driverPosition={data?.lastPositionUpdate}
                    />
                </Grid>
                <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                    <RideInformationCard data={data} showUtcSwitch showUtcTime={showUtcTime} setShowUtcTime={setShowUtcTime} />
                </Grid>
                {data?.status === getRideStatusKey(RideStatus.COMPLETED) && Boolean(data?.feedbacks?.length) && (
                    <Grid item xs className={classes.contentContainer} marginTop="1.5rem">
                        <ReviewRideCard feedbacks={data.feedbacks} />
                    </Grid>
                )}
                {data?.vehicle && (
                    <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                        <SelectedVehicleCard data={data} isSelectedHeaderVisible />
                    </Grid>
                )}
                <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                    <FlightInformationCard rideId={data?.unid || ''} showUtcTime={showUtcTime} />
                </Grid>
                {data?.pickupDescription && (
                    <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                        <MeetingInstructionsCard data={data} />
                    </Grid>
                )}
                {data?.driver && (
                    <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                        <DriverReferencesCard data={data} refetch={refetch} isCustomerPage />
                    </Grid>
                )}
                {Boolean(data?.passengers?.length) && (
                    <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                        <PassengerInformationCard data={data} />
                    </Grid>
                )}
                <Grid item xs display="flex" justifyItems="flex-start" className={classes.contentContainer}>
                    <ReferencesCard data={data} />
                </Grid>
                {Boolean(data?.cancellationPolicies?.length) && (
                    <Grid
                        item
                        xs
                        display="flex"
                        justifyItems="flex-start"
                        className={clsx(classes.contentContainer, classes.cancellationPolicies)}
                    >
                        <CancellationPoliciesCard
                            isUtc
                            isVisible
                            cancelledAt={data?.cancelledAt || null}
                            cancellationPolicies={data?.cancellationPolicies ?? []}
                            status={data?.status || null}
                            pickUpDate={dayjs(data?.pickUp?.departAt).toDate()}
                            pickUpTime={dayjs(data?.pickUp?.departAt).toDate()}
                            vehicleType={data?.vehicle.type}
                            departureTimeZone={data?.pickUp.departureTimeZone}
                            appliedCancellationPolicy={data?.appliedCancellationPolicy}
                            order={3}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};
