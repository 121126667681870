import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from '@mui/material';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';
import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import { IconedLabel } from '../../../../components/iconedLabel/IconedLabel';
import { DONWLOAD_DOCUMENTATION, SEND_CONFIRMATION } from '../../../../constants/amplitude/customerKeys';
import { COLORS } from '../../../../constants/colors';
import { DATE_FORMATS } from '../../../../constants/dateFormats';
import { getRideStatusOptions } from '../../../../helpers/rideStatusUtil';
import { PrimaryTimeZone } from '../../../../services/types';
import useDataGridStyles from '../../../../styles/DataGrid';
import { CustomField } from '../../../ride/api/types';
import { Passenger } from '../../api/types';
import CellText from '../../components/CellText';
import CellWrapper from '../../components/CellWrapper';
import StatusBadge from '../../components/StatusBadge';
import StatusOptionBadge from '../../components/StatusOptionBadge';
import downloadBookingConfirmation from '../api/downloadBookingConfirmation';
import downloadReceipt from '../api/downloadReceipt';
import { RideAction } from '../Rides';

export interface BookingConfirmationRow {
    rideId: string;
    passengers: Passenger[];
}

export const useRidesColumns = (
    handleSendConfirmation: (row: BookingConfirmationRow) => void,
    handleEditRide: (rideId: string) => void,
    primaryTimeZone?: keyof typeof PrimaryTimeZone
) => {
    const dataGridClases = useDataGridStyles();
    const { t } = useTranslation();
    const { useManagedByCars } = useFlags();

    const { mutate: downloadBookingConfirmationMutation, isLoading: isDownloadingBookingConfirmation } =
        useMutation(downloadBookingConfirmation);

    const { mutate: downloadReceiptMutation, isLoading: isDownloadingReceipt } = useMutation(downloadReceipt);

    const onActionItemClick = useCallback((unid: number, prettifiedUnid: string, action: RideAction, row?: GridRowParams) => {
        switch (action) {
            case RideAction.EDIT_RIDE:
                handleEditRide(unid.toString());
                break;
            case RideAction.SEND_CONFIRMATION:
                handleSendConfirmation({
                    rideId: unid.toString(),
                    passengers: row?.row?.passengers,
                });

                logAmplitudeEvent(SEND_CONFIRMATION);
                break;
            case RideAction.DOWNLOAD_CONFIRMATION:
                downloadBookingConfirmationMutation({
                    unid: unid.toString(),
                    prettifiedUnid,
                });

                logAmplitudeEvent(DONWLOAD_DOCUMENTATION);
                break;
            case RideAction.DOWNLOAD_RECEIPT:
                downloadReceiptMutation({
                    unid: unid.toString(),
                    prettifiedUnid,
                });
                break;
            default:
                throw new Error('Ride action not supported');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(() => {
        const columns = [
            {
                field: 'status',
                headerName: t('status'),
                width: 180,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <StatusBadge
                            status={params.row.status}
                            trackingStatus={params.row.trackingStatus}
                            pickupDateTimeUtc={params.row.originalPickupUtc}
                        />
                        <StatusOptionBadge statusOptions={getRideStatusOptions(params.row.newStatusOptions)} />
                        {params.row.isManagedByApi && useManagedByCars && (
                            <IconedLabel
                                icon={WarningIcon}
                                label={t('pages.rides.systemAutomated')}
                                colorOfIconAndLabel={COLORS.ORANGE_WARNING}
                                excludeAnotation
                            />
                        )}
                    </CellWrapper>
                ),
            },
            {
                field: 'pickup',
                headerName: primaryTimeZone === 'UTC' ? t('pages.rides.pickupUTC') : t('pickUp'),
                width: 140,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <CellText>
                            {dayjs(params.row.primaryTimeZone === 'UTC' ? params.row.pickup : params.row.pickupLocal).format(
                                DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A']
                            )}
                        </CellText>
                        {params.row.primaryTimeZone === 'UTC' && (
                            <CellText>
                                <Typography
                                    sx={{
                                        fontSize: '0.75rem',
                                        color: COLORS.SLATE_GREY,
                                        lineHeight: '0.875rem',
                                    }}
                                >
                                    Local: {dayjs(params.row.pickupLocal).format(DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A'])}
                                </Typography>
                            </CellText>
                        )}
                    </CellWrapper>
                ),
            },
            {
                field: 'passengers',
                headerName: t('passengers'),
                width: 180,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        {params.row.passengers.map((passenger: Passenger) => (
                            <CellText key={passenger.id}>
                                {passenger.firstName} {passenger.lastName}
                            </CellText>
                        ))}
                    </CellWrapper>
                ),
            },
            {
                field: 'route',
                headerName: t('pages.rides.route'),
                flex: 1,
                minWidth: 250,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <CellText>
                            <strong>{t('pickUp')}: </strong>
                            {params.row.route.from}
                        </CellText>
                        <CellText>
                            <strong>{t('dropOff')}: </strong>
                            {params.row.route.to}
                        </CellText>
                        {params.row.route.flight && (
                            <Typography
                                sx={{
                                    fontSize: '0.75rem',
                                    lineHeight: '0.875rem',
                                    color: COLORS.SLATE_GREY,
                                    paddingTop: '0.5rem',
                                    overflow: 'hidden',
                                }}
                            >
                                <strong>{t('flight')}: </strong>
                                {params.row.route.flight}
                            </Typography>
                        )}
                    </CellWrapper>
                ),
            },
            {
                field: 'reference',
                headerName: t('reference'),
                width: 200,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <CellText>
                            <strong>{t('reference')}: </strong>
                            {params.row.customerReference ?? '-'}
                        </CellText>
                        {params.row.customFields?.map((customField: CustomField) => (
                            <CellText key={customField.key}>
                                <strong>{customField.label}: </strong>
                                {customField.value}
                            </CellText>
                        ))}
                    </CellWrapper>
                ),
            },
            {
                field: 'rideNumber',
                headerName: t('rideNumber'),
                width: 120,
                sortable: false,
                headerClassName: dataGridClases.hideSeparator,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <CellText>{params.row.rideNumber}</CellText>
                    </CellWrapper>
                ),
            },
            {
                field: 'actions',
                type: 'actions',
                width: 20,
                headerClassName: dataGridClases.hideSeparator,
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                        key={RideAction.EDIT_RIDE}
                        className={dataGridClases.menuItem}
                        icon={<CreateOutlinedIcon />}
                        label="Edit ride"
                        onClick={() => onActionItemClick(params.row.id, params.row.rideNumber, RideAction.EDIT_RIDE)}
                        showInMenu
                        onResize={undefined}
                        onResizeCapture={undefined}
                        nonce=""
                    />,
                    <GridActionsCellItem
                        key={RideAction.SEND_CONFIRMATION}
                        className={dataGridClases.menuItem}
                        icon={<EmailOutlinedIcon />}
                        label={t('pages.rides.sendBookingConfirmation')}
                        onClick={() =>
                            onActionItemClick(params.row.id, params.row.rideNumber, RideAction.SEND_CONFIRMATION, params)
                        }
                        showInMenu
                        onResize={undefined}
                        onResizeCapture={undefined}
                        nonce=""
                    />,
                    <GridActionsCellItem
                        key={RideAction.DOWNLOAD_CONFIRMATION}
                        disabled={isDownloadingBookingConfirmation}
                        className={dataGridClases.menuItem}
                        icon={<DownloadOutlinedIcon />}
                        label={t('pages.rides.downloadBookingConfirmation')}
                        onClick={() => onActionItemClick(params.row.id, params.row.rideNumber, RideAction.DOWNLOAD_CONFIRMATION)}
                        showInMenu
                        onResize={undefined}
                        onResizeCapture={undefined}
                        nonce=""
                    />,
                    <GridActionsCellItem
                        key={RideAction.DOWNLOAD_RECEIPT}
                        disabled={isDownloadingReceipt}
                        className={params.row.isPaymentMethodCreditCard ? dataGridClases.menuItem : dataGridClases.hideItem}
                        icon={<ReceiptLongOutlinedIcon />}
                        label={t('pages.rides.downloadReceipt')}
                        onClick={() => onActionItemClick(params.row.id, params.row.rideNumber, RideAction.DOWNLOAD_RECEIPT)}
                        showInMenu
                        onResize={undefined}
                        onResizeCapture={undefined}
                        nonce=""
                    />,
                ],
            },
        ];

        return columns;
    }, [
        t,
        primaryTimeZone,
        dataGridClases.hideSeparator,
        dataGridClases.menuItem,
        dataGridClases.hideItem,
        useManagedByCars,
        isDownloadingBookingConfirmation,
        isDownloadingReceipt,
        onActionItemClick,
    ]);
};
