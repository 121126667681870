import { PrimaryButton } from '@get-e/react-components';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { Typography, Grid, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import { BUTTON_WIDTH } from '../../../constants/layout';
import theme from '../../../styles/theme';

const useStyles = makeStyles({ exportButton: { width: BUTTON_WIDTH } });

interface ExportGridContainerProps {
    title?: string;
    description?: string;
    children: React.ReactElement;
    isButtonDisabled: boolean;
    isLoading: boolean;
    buttonOnClick: () => void;
    isShowingDownloadButton: boolean;
}

const ExportGridContainer = ({
    title,
    description,
    children,
    isButtonDisabled,
    isLoading,
    buttonOnClick,
    isShowingDownloadButton,
}: ExportGridContainerProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <>
            <Grid container marginTop={title ? '1.5rem' : 0} flexDirection="column" maxWidth={isMobile ? '100%' : '50%'}>
                <Typography
                    component="div"
                    display="block"
                    sx={{
                        fontWeight: '700 !important',
                        color: `${COLORS.BLUE_DARK}`,
                        marginBottom: title ? '1rem' : 0,
                    }}
                >
                    {title}
                </Typography>
                <Typography component="div" display="block" sx={{ color: `${COLORS.BLACK} !important` }}>
                    {description}
                </Typography>
                <Grid
                    container
                    spacing={1}
                    style={{
                        marginBottom: !title ? '2rem' : 0,
                        marginTop: title ? '1rem' : 0,
                    }}
                >
                    {children}
                </Grid>
            </Grid>
            {isShowingDownloadButton && (
                <PrimaryButton
                    className={classes.exportButton}
                    disabled={isButtonDisabled}
                    loading={isLoading}
                    onClick={buttonOnClick}
                    icon={<CloudDownloadOutlinedIcon />}
                >
                    {t('pages.export.download')}
                </PrimaryButton>
            )}
        </>
    );
};

export default ExportGridContainer;
