import { useQueries } from 'react-query';

import { USE_IMAGES } from '../../../constants/queryKeys';
import apiClient from '../../../services/api';
import { IMAGE_UPLOAD } from '../../../services/routes';
import { ImageUrl } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useUploadedImageUrls = (isClicked: boolean, files: any) => {
    const results = useQueries(
        files.map(file => {
            const formData = new FormData();

            formData.append('image', file);
            return {
                queryKey: [USE_IMAGES, file.name],
                queryFn: () => uploadImage(formData),
                enabled: isClicked,
            };
        })
    );

    return results;
};

export const uploadImage = async (formData: FormData): Promise<ImageUrl> => {
    const response = await apiClient.post(IMAGE_UPLOAD, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

    return response?.data as ImageUrl;
};
