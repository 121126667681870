import { TabPanel } from '@get-e/react-components';
import { Box, useMediaQuery } from '@mui/material';
import { DataGridPro, GridColDef, GridColumnVisibilityModel, GridRowId, useGridApiRef } from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { CustomNoRowsOverlay } from '../../../components/noRowsDataGridOverlay/CustomNoRowsOverlay';
import useDataGridStyles from '../../../styles/DataGrid';
import theme from '../../../styles/theme';
import { User } from '../api/types';
import { useUsersColumns } from '../hooks/useUsersColumns';
import { useUsersColumnsMobile } from '../hooks/useUsersColumnsMobile';
import { ActiveTab, ActiveTabUrl, getTableHeight, getTableWrapperHeight } from '../Users';

const DeactivatedUsersDataGrid: FunctionComponent<{
    data: User[];
    isFetching: boolean;
    value: ActiveTab;
    onEdit: (user: User) => void;
    onChangeStatus: (user: User, isActive: boolean) => void;
    columnVisibilityModel: GridColumnVisibilityModel;
}> = ({ data, isFetching, value, onEdit, onChangeStatus, columnVisibilityModel }) => {
    const { path } = useRouteMatch();
    const classes = useDataGridStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const gridApiRef = useGridApiRef();

    const handleEdit = (user: User) => {
        onEdit(user);
    };

    const handleReactivate = (user: User) => {
        onChangeStatus(user, true);
    };

    const onRowClick = (params: { id: GridRowId; row: User }) => {
        if (isMobile) {
            gridApiRef.current.toggleDetailPanel(params.id);
        }
    };

    return (
        <TabPanel selectedValue={value} value={ActiveTab.Deactivated}>
            <Switch>
                <Route exact path={[`${path}/${ActiveTabUrl.Deactivated}`, path]}>
                    <Box
                        sx={{
                            width: '100%',
                            height: getTableWrapperHeight(isMobile, data?.length === 0),
                        }}
                    >
                        <DataGridPro
                            apiRef={gridApiRef}
                            style={{ height: getTableHeight(isMobile, data?.length === 0) }}
                            className={clsx({
                                [classes.dataGrid]: true,
                                [classes.dataGridNoRows]: data.length === 0,
                                [classes.hideHeader]: isMobile,
                                [classes.rowMinHeight52]: isMobile && data.length > 0,
                            })}
                            hideFooter
                            disableColumnSelector
                            rows={data}
                            columns={
                                useUsersColumns({
                                    handleEdit,
                                    handleReactivate,
                                }) as GridColDef[]
                            }
                            slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                            getDetailPanelContent={useUsersColumnsMobile({
                                activeTab: ActiveTab.Deactivated,
                                handleEdit,
                                handleReactivate,
                            })}
                            getDetailPanelHeight={() => 'auto'}
                            loading={isFetching}
                            onRowClick={onRowClick}
                            columnVisibilityModel={columnVisibilityModel}
                        />
                    </Box>
                </Route>
            </Switch>
        </TabPanel>
    );
};

export default DeactivatedUsersDataGrid;
