/* eslint-disable import/no-anonymous-default-export */
export default {
    appUrl: 'http://localhost:3000',
    logOutUrl: 'http://localhost/portal/auth/logout',
    iframeUrl: 'http://localhost/portal/views',
    signInUrl: 'http://localhost/portal/login',
    forgottenPasswordUrl: 'http://localhost/password/forget',
    bookingToolUrl: 'https://{subDomain}.book-test.get-e.com',
    setCookieUrl: 'http://localhost:3002/authorize',
    refreshCookieUrl: 'http://localhost:3003/refresh',
    deleteCookieUrl: 'http://localhost:3004/log-out',
    googleAddressKey: 'AIzaSyBwTMpGcV7qZPjCvF8I6-k9oMGjowxbeJ0',
    apiUrl: 'http://localhost:80/',
    useNewAuthentication: true,
    amplitudeApi: 'e8a7983067bd030e339fbe82738422e3',

    // For testing via ngrok + remote desktop

    /*
     * AppUrl: 'http://portal.get-e.ngrok.io',
     * logOutUrl: 'http://core.get-e.ngrok.io/portal/auth/logout',
     * iframeUrl: 'http://core.get-e.ngrok.io/portal/views',
     * signInUrl: 'http://core.get-e.ngrok.io/portal/login',
     * forgottenPasswordUrl: 'http://portal.get-e.ngrok.io/password/forget',
     * bookingToolUrl: 'http://{subDomain}.book.get-e.com',
     * setCookieUrl: 'http://cookie-authorize.get-e.ngrok.io/authorize',
     * refreshCookieUrl: 'http://cookie-refresh.get-e.ngrok.io/refresh',
     * deleteCookieUrl: 'http://cookie-log-out.get-e.ngrok.io/log-out',
     * googleAddressKey: 'AIzaSyBwTMpGcV7qZPjCvF8I6-k9oMGjowxbeJ0',
     */
};
