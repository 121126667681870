import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { logAmplitudeEvent } from '../../amplitude/amplitude';
import ErrorPage from '../../components/errorPage/ErrorPage';
import { TO_MANY_REQUEST_PAGE } from '../../constants/amplitude/commonKeys';
import { RIDES } from '../../constants/urlPaths';
import { tooManyRequestsIcon } from '../../public/assets/icons';

const TooManyRequest = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const isPageOpened = useRef(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            history.push(RIDES);
        }, 10000);

        return () => clearTimeout(timer);
    });

    if (!isPageOpened.current) {
        isPageOpened.current = true;
        logAmplitudeEvent(TO_MANY_REQUEST_PAGE);
    }

    return (
        <ErrorPage
            icon={tooManyRequestsIcon}
            header={t('errors.tooManyRequests.description')}
            description={t('errors.tooManyRequests.nextSteps')}
            isButtonVisable={false}
        />
    );
};

export default TooManyRequest;
