import { Grid, Skeleton, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import theme from '../../../../styles/theme';

const useStyles = makeStyles(() => ({
    bigCard: { marginTop: '1.25rem' },
    smallCard: { marginTop: '1.25rem' },
}));

const RideViewLoadingSkeleton = () => {
    const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const classes = useStyles();

    return (
        <>
            <Grid container marginTop="1rem">
                <Grid item xs>
                    <Grid
                        item
                        xs
                        display="flex"
                        flexDirection={isLargeScreen ? 'column' : 'row'}
                        alignItems={isLargeScreen ? 'flex-start' : 'center'}
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Grid display="flex" alignItems="center">
                                <Skeleton variant="rectangular" width={310} height={30} />
                            </Grid>
                            <Grid
                                item
                                xs
                                display="flex"
                                alignItems={isLargeScreen ? 'flex-start' : 'center'}
                                flexDirection={isLargeScreen ? 'column' : 'row'}
                                marginTop="1rem"
                            >
                                <Skeleton variant="rectangular" width={155} height={20} />
                                <Skeleton
                                    sx={{ marginTop: isLargeScreen ? '1rem' : '0' }}
                                    variant="rectangular"
                                    width={155}
                                    height={20}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            item
                            marginTop={isLargeScreen ? '1rem' : '0'}
                        >
                            <Skeleton variant="rectangular" width={152} height={44} sx={{ marginRight: '1.5rem' }} />
                            <Skeleton variant="rectangular" width={152} height={44} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {isLargeScreen ? (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12} xl={6}>
                            <Skeleton height={620} variant="rectangular" className={classes.smallCard} />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} xl={6}>
                            <Skeleton height={200} variant="rectangular" className={classes.smallCard} />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} xl={6}>
                            <Skeleton height={400} variant="rectangular" className={classes.smallCard} />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} xl={6}>
                            <Skeleton variant="rectangular" className={classes.smallCard} />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid container spacing={4}>
                    <Grid item xs={6} xl={7.5} lg={7.5}>
                        <Skeleton variant="rectangular" height={400} className={classes.bigCard} />
                        <Skeleton variant="rectangular" height={250} className={classes.bigCard} />
                        <Skeleton variant="rectangular" height={180} className={classes.bigCard} />
                        <Skeleton variant="rectangular" height={180} className={classes.bigCard} />
                    </Grid>
                    <Grid item xs={6} xl={4.5} lg={4.5}>
                        <Skeleton variant="rectangular" height={320} className={classes.smallCard} />
                        <Skeleton variant="rectangular" height={150} className={classes.smallCard} />
                        <Skeleton variant="rectangular" height={200} className={classes.smallCard} />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default RideViewLoadingSkeleton;
