import dayjs from 'dayjs';
import { ADJUSTMENT_REPEATS, ADJUSTMENT_REPEATS_VALUES, REPEATS, WEEKDAYS, WEEKDAY_SHORT_NAMES } from '../constants';
import { DATE_FORMATS } from '../constants/dateFormats';
import { TIME_FORMATS } from '../constants/timeFormats';

export interface BlockedPeriodMessage {
    part1: string;
    part2: string;
    part3: string;
}

export const resolveRepeatsValue = (value: keyof typeof WEEKDAYS): REPEATS => {
    switch (value) {
        case 'MONDAY':
            return REPEATS.EVERY_MONDAY;
        case 'TUESDAY':
            return REPEATS.EVERY_TUESDAY;
        case 'WEDNESDAY':
            return REPEATS.EVERY_WEDNESDAY;
        case 'THURSDAY':
            return REPEATS.EVERY_THURSDAY;
        case 'FRIDAY':
            return REPEATS.EVERY_FRIDAY;
        case 'SATURDAY':
            return REPEATS.EVERY_SATURDAY;
        case 'SUNDAY':
            return REPEATS.EVERY_SUNDAY;
        default:
            return REPEATS.DO_NOT_REPEAT;
    }
};

export const resolveApplicableOnDaysValue = (value: REPEATS): keyof typeof WEEKDAYS => {
    switch (value) {
        case REPEATS.EVERY_MONDAY:
            return 'MONDAY';
        case REPEATS.EVERY_TUESDAY:
            return 'TUESDAY';
        case REPEATS.EVERY_WEDNESDAY:
            return 'WEDNESDAY';
        case REPEATS.EVERY_THURSDAY:
            return 'THURSDAY';
        case REPEATS.EVERY_FRIDAY:
            return 'FRIDAY';
        case REPEATS.EVERY_SATURDAY:
            return 'SATURDAY';
        case REPEATS.EVERY_SUNDAY:
            return 'SUNDAY';
        default:
            return 'MONDAY';
    }
};

export const resolveApplicableOnDaysShortNames = (applicableOnDays: Array<keyof typeof WEEKDAYS>) =>
    (
        Object.keys(WEEKDAYS).filter(weekday => applicableOnDays.includes(weekday as keyof typeof WEEKDAYS)) as Array<
            keyof typeof WEEKDAYS
        >
    )
        .map(applicableDay => WEEKDAY_SHORT_NAMES[applicableDay])
        .join(', ');

export const getWhenMessage = (
    repeats: REPEATS,
    startDateTime: Date | null,
    endDateTime: Date | null,
    startTime: Date | null,
    endTime: Date | null,
    applicableOnDays: Array<keyof typeof WEEKDAYS>
): BlockedPeriodMessage => {
    if (repeats === REPEATS.DO_NOT_REPEAT) {
        return {
            part1: `${dayjs(startDateTime).format(DATE_FORMATS['ddd DD MMM YYYY at HH:mm A'])}`,
            part2: `${dayjs(endDateTime).format(DATE_FORMATS['ddd DD MMM YYYY at HH:mm A'])}`,
            part3: '',
        };
    }

    return {
        part1: `${dayjs(startDateTime).format(DATE_FORMATS['ddd DD MMM YYYY'])}`,
        part2: `${dayjs(endDateTime).format(DATE_FORMATS['ddd DD MMM YYYY'])}`,
        part3: `${resolveApplicableOnDaysShortNames(applicableOnDays)} from ${dayjs(startTime).format(
            TIME_FORMATS['HH:mm A']
        )} to ${dayjs(endTime).format(TIME_FORMATS['HH:mm A'])}`,
    };
};

export const getAdjustmentWhenMessage = (
    repeats: ADJUSTMENT_REPEATS,
    startDateTime: Date | null,
    endDateTime: Date | null,
    startTime: Date | null,
    endTime: Date | null,
    applicableOnDays: Array<keyof typeof WEEKDAYS>
) => {
    if (repeats === ADJUSTMENT_REPEATS.DOES_NOT_REPEAT) {
        return {
            part1: `${dayjs(startDateTime).format(DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A'])} - ${dayjs(endDateTime).format(
                DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A']
            )}`,
            part2: '',
        };
    } else if (repeats === ADJUSTMENT_REPEATS.REPEATS_DAILY) {
        return {
            part1: `${dayjs(startTime).format(TIME_FORMATS['HH:mm A'])} - ${dayjs(endTime).format(TIME_FORMATS['HH:mm A'])}`,
            part2: 'Daily',
        };
    } else if (repeats === ADJUSTMENT_REPEATS.REPEATS_WEEKLY) {
        return {
            part1: `${dayjs(startTime).format(TIME_FORMATS['HH:mm A'])} - ${dayjs(endTime).format(TIME_FORMATS['HH:mm A'])}`,
            part2: `${resolveApplicableOnDaysShortNames(applicableOnDays)}`,
        };
    } else if (repeats === ADJUSTMENT_REPEATS.REPEATS_YEARLY) {
        return {
            part1: `${dayjs(startDateTime).format(
                DATE_FORMATS['MMM Do HH:mm A']
            )} - ${dayjs(endDateTime).format(DATE_FORMATS['MMM Do HH:mm A'])}`,
            part2: 'Yearly',
        };
    }

    return {
        part1: '',
        part2: '',
    };
};

export const resolveRepeatsInitialValue = (value: Array<keyof typeof WEEKDAYS> | null): REPEATS => {
    if (value && value.length === 1) {
        return resolveRepeatsValue(value[0]);
    } else if (value && value.length > 1) {
        return REPEATS.CUSTOM;
    }

    return REPEATS.DO_NOT_REPEAT;
};

export const resolveAdjustmentRepeatsInitialValue = (value: ADJUSTMENT_REPEATS_VALUES): ADJUSTMENT_REPEATS =>
    Object.values(ADJUSTMENT_REPEATS)[Object.values(ADJUSTMENT_REPEATS_VALUES).indexOf(value)];
