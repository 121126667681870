import { Spinner, TableBody, TableContainer, TableHead } from '@get-e/react-components';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Table, TableCell, TableRow, Pagination, Container, Button, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../constants/colors';
import { DATE_FORMATS } from '../../constants/dateFormats';
import { MAX_CONTENT_WIDTH } from '../../constants/layout';
import { getSupplierInvoiceFile } from '../../services/routes';
import { FileType } from '../../services/types';
import theme from '../../styles/theme';
import { useSupplierInvoices } from './api';
import Heading from './components/Heading';
import InvoicesLoadingSkeleton from './components/InvoicesLoadingSkeleton';

const useStyles = makeStyles({
    table: { minWidth: '600px' },
    tableCell: { padding: '0.5rem', fontFamily: 'Open sans' },
    tableRow: { '&:last-child td, &:last-child th': { border: 0 } },
    loader: {
        height: '400px',
        textAlign: 'center',
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '1.5em',
    },
    downloadLink: {
        fontWeight: 400,
        border: `1px solid ${COLORS.BLUE_DARK}`,
        padding: '0 0.5rem',
    },
});

const getPaymentDate = (invoiceDate, paymentDay) => {
    let finalPaymentDay = 30;
    const dateObject = dayjs(invoiceDate);

    if (paymentDay === 15) {
        finalPaymentDay = 15;
    } else if (dateObject.get('month') === 1) {
        // For february we set last day of the month if paymentDay !== 15
        finalPaymentDay = dateObject.endOf('month').get('date');
    }

    const paymentDate = dateObject.set('date', finalPaymentDay).format(DATE_FORMATS['DD MMM YYYY']);

    return paymentDate;
};

const Invoices = () => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const [currentPage, setCurrentPage] = useState(1);

    const {
        data: supplierInvoices,
        meta: { lastPage },
        isFetching: isFetchingSupplierInvoices,
    } = useSupplierInvoices(currentPage);

    const handleChange = (event: ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };

    if (isFetchingSupplierInvoices) {
        return <InvoicesLoadingSkeleton />;
    }

    return (
        <Container
            sx={{
                padding: isMobile ? '2rem 1rem' : '0 2rem 2rem 2rem',
                maxWidth: `${MAX_CONTENT_WIDTH} !important`,
            }}
        >
            <Heading>{t('invoices')}</Heading>
            <TableContainer>
                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableCell className={classes.tableCell}>{t('pages.invoices.invoiceNumber')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('pages.invoices.invoiceDate')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('pages.invoices.totalRides')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('pages.invoices.period')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('pages.invoices.amountDue')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('pages.invoices.paymentDate')}</TableCell>
                        <TableCell className={classes.tableCell} />
                        <TableCell className={classes.tableCell} />
                    </TableHead>
                    <TableBody>
                        {isFetchingSupplierInvoices ? (
                            <TableRow className={classes.tableRow}>
                                <TableCell colSpan={8} className={classes.loader}>
                                    <Spinner size={64} />
                                </TableCell>
                            </TableRow>
                        ) : (
                            supplierInvoices.map(invoice => (
                                <TableRow className={classes.tableRow} key={invoice.id}>
                                    <TableCell className={classes.tableCell}>{invoice.invoiceNumber}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {dayjs(invoice.invoiceDate).format(DATE_FORMATS['DD MMM YYYY'])}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>{invoice.totalTripCount}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {dayjs(invoice.startDate).format(DATE_FORMATS['DD MMM YYYY'])} -{' '}
                                        {dayjs(invoice.endDate).format(DATE_FORMATS['DD MMM YYYY'])}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {invoice.currency} {invoice.amountDue?.toLocaleString('en-US')}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {getPaymentDate(invoice.invoiceDate, invoice.paymentDayOfMonth)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Button
                                            href={getSupplierInvoiceFile(invoice.invoiceNumber, FileType.PDF)}
                                            target="_blank"
                                            download
                                            variant="outlined"
                                            startIcon={<PictureAsPdfOutlinedIcon />}
                                            className={classes.downloadLink}
                                        >
                                            PDF
                                        </Button>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Button
                                            href={getSupplierInvoiceFile(invoice.invoiceNumber, FileType.EXCEL)}
                                            download
                                            variant="outlined"
                                            startIcon={<ListAltOutlinedIcon />}
                                            className={classes.downloadLink}
                                        >
                                            Excel
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {lastPage > 1 && (
                <Pagination
                    count={lastPage}
                    page={currentPage}
                    onChange={handleChange}
                    showFirstButton
                    showLastButton
                    className={classes.pagination}
                />
            )}
        </Container>
    );
};

export default Invoices;
