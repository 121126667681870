import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const usePageLeave = (
    isBackEnabled: boolean,
    initialPathName: string,
    setIsUpdatePendingModalOpen: (isOpen: boolean) => void,
    isRefreshingDisabled: boolean,
    isDisabled: boolean
) => {
    const history = useHistory();
    const [routeChanged, setRouteChanged] = useState('');

    useEffect(() => {
        if (isDisabled) {
            return;
        }

        const unlisten = history.listen(location => {
            if (location.pathname === initialPathName) {
                return;
            }

            if (routeChanged === '') {
                history.replace(initialPathName);
            } else {
                history.push(routeChanged);
                return;
            }

            setRouteChanged(location.pathname);
            setIsUpdatePendingModalOpen(true);
        });

        return function cleanup() {
            unlisten();
        };
    }, [history, isBackEnabled, initialPathName, routeChanged, setIsUpdatePendingModalOpen, isDisabled]);

    const handleBlockedNavigation = (event: PopStateEvent) => {
        event.preventDefault();
        setIsUpdatePendingModalOpen(true);
    };

    const onUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = '';
    };

    useEffect(() => {
        if (isDisabled) {
            return;
        }

        if (!isBackEnabled && !isRefreshingDisabled) {
            window.addEventListener('beforeunload', onUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', onUnload);
        };
    }, [isBackEnabled, isDisabled, isRefreshingDisabled]);

    useEffect(() => {
        if (isDisabled) {
            return;
        }

        if (!isBackEnabled) {
            window.history.pushState(null, document.title, window.location.href);
            window.addEventListener('popstate', handleBlockedNavigation);
        } else {
            if (routeChanged !== '') {
                history.replace(routeChanged);
                setIsUpdatePendingModalOpen(false);
                return;
            }

            history.goBack();
            return;
        }

        return () => {
            window.removeEventListener('popstate', handleBlockedNavigation);
        }; // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBackEnabled, isDisabled]);
};

export default usePageLeave;
