import { Validated } from '../Validator';

export default function isFilledString<TError>(value: string, error: TError): Validated<string, TError> {
    return value === null || value.trim() === ''
        ? {
              isValid: false,
              error,
          }
        : {
              isValid: true,
              value,
          };
}
