import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { COLORS } from '../../../constants/colors';
import { IMAGE_SIZE } from '../../../constants/layout';

const useStyles = makeStyles({
    vehicleBox: (props: { boxSize?: string }) => ({
        height: props.boxSize ?? 'auto',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '36px',
        borderRadius: '4px',
    }),
    photoBox: (props: { boxSize?: string }) => ({
        maxHeight: props.boxSize ?? IMAGE_SIZE,
        width: IMAGE_SIZE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }),
    cameraBox: (props: { boxSize?: string }) => ({
        height: props.boxSize || 'auto',
        minWidth: props.boxSize || 'auto',
        borderRadius: '4px',
        backgroundColor: COLORS.LIGHT_GRAY,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    }),
});

const SmallVehicleImage = ({ photo, boxSize }: { photo?: string; boxSize?: string }) => {
    const classes = useStyles({ boxSize });

    if (!photo) {
        return (
            <Box className={classes.cameraBox}>
                <CameraAltIcon style={{ color: COLORS.SLATE_GREY }} />
            </Box>
        );
    }

    return (
        <Box className={classes.vehicleBox}>
            <img src={photo} alt="Vehicle" className={classes.photoBox} style={{ objectFit: 'cover' }} />
        </Box>
    );
};

export default SmallVehicleImage;
