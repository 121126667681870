import { Grid, Skeleton, useMediaQuery } from '@mui/material';
import React from 'react';

import theme from '../../../../../styles/theme';

const RideLoadingSkeleton = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container marginTop="2rem">
            <Grid
                container
                columnSpacing={isMobile ? 0 : 4}
                display="flex"
                justifyContent={'space-between'}
                flexDirection="row"
                width="100%"
            >
                <Grid item xs={4}>
                    <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
            </Grid>
            <Grid item xs={12} marginTop={'1.5rem'} marginRight={'2rem'}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid container style={{ marginTop: '2rem' }} direction="row" columnSpacing={isMobile ? 0 : 4}>
                <Grid item xs={12} md={12} lg={7.5} order={isMobile ? 2 : 1} id="RIDE_INFORMATION">
                    <Grid item xs={4}>
                        <Skeleton
                            variant="text"
                            sx={{
                                fontSize: '1.5rem',
                                marginBottom: '2rem',
                            }}
                        />
                    </Grid>
                    <Grid item rowGap=".75rem" display="flex" flexDirection="column">
                        <Grid gap={4} display="flex" flexDirection={isMobile ? 'column' : 'row'} marginBottom="1.2rem">
                            <Grid item xs={12} md={6} lg={6}>
                                <Skeleton variant="rectangular" height={50} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Skeleton variant="rectangular" height={50} />
                            </Grid>
                        </Grid>
                        <Grid gap={isMobile ? 2 : 4} display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                            <Grid item xs={12} md={3} lg={3}>
                                <Skeleton variant="rectangular" height={50} />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Skeleton variant="rectangular" height={50} />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Skeleton variant="rectangular" height={50} />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Skeleton variant="rectangular" height={50} />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={isMobile ? 2 : 4} marginTop={'2rem'}>
                            <Grid item xs={12} md={6} lg={6}>
                                <Skeleton variant="rectangular" height={50} />
                            </Grid>
                        </Grid>
                        <Grid item marginTop={'1.5rem'}>
                            <Skeleton variant="rectangular" height={50} />
                        </Grid>
                        <Grid item marginTop={'1.5rem'} xs={3}>
                            <Skeleton variant="rectangular" height={50} />
                        </Grid>
                    </Grid>
                    <Grid marginBottom="2.5rem" marginTop="2rem" id="CAR_INFORMATION">
                        <Grid item xs={4}>
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1.5rem',
                                    marginBottom: '2rem',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton variant="rectangular" height={150} />
                        </Grid>
                        <Grid item marginTop={'1.5rem'} xs={3}>
                            <Skeleton variant="rectangular" height={50} />
                        </Grid>
                    </Grid>
                    <Grid marginBottom="2.5rem" marginTop="2rem" id="PASSENGER_INFORMATION">
                        <Grid item xs={4}>
                            <Skeleton
                                variant="text"
                                sx={{
                                    fontSize: '1.5rem',
                                    marginBottom: '2rem',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton variant="rectangular" height={150} />
                        </Grid>
                        <Grid item marginTop={'1.5rem'} xs={3}>
                            <Skeleton variant="rectangular" height={50} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={4.5}
                    order={isMobile ? 1 : 2}
                    marginBottom={isMobile ? '2rem' : 0}
                    display="flex"
                    flexDirection="column"
                    gap={4}
                >
                    <Skeleton variant="rectangular" height={'30vh'} />
                    <Skeleton variant="rectangular" height={'10vh'} />
                    <Skeleton variant="rectangular" height={'10vh'} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RideLoadingSkeleton;
