import { makeStyles, createStyles } from '@mui/styles';
import { TOOLBAR_HEIGHT } from './theme';

const iframeStyles = makeStyles(() =>
    createStyles({
        iframe: {
            width: '100%',
            height: `calc(100vh - ${TOOLBAR_HEIGHT}px)`,
            border: 'none',
        },
    })
);

export default iframeStyles;
