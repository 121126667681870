import { isPast, isToday } from 'date-fns';

import { Validated } from '../Validator';

const isPastDate = <TError>(value: Date | null, error: TError): Validated<Date, TError> =>
    value === null || (!isToday(value) && isPast(value))
        ? {
              isValid: false,
              error,
          }
        : {
              isValid: true,
              value,
          };

export default isPastDate;
