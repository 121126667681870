import { makeStyles } from '@mui/styles';

import { COLORS } from '../constants/colors';

const useDataGridStyles = makeStyles({
    dataGrid: {
        marginTop: '2rem',
        '& .MuiDataGrid-main': { fontSize: '1rem' },
        '& .permissionsCell': {
            display: 'flex',
            textAlign: 'center',
            paddingRight: '0.7rem',
            color: COLORS.SLATE_GREY,

            '& > *': { paddingRight: '0.7rem' },
        },
        '& .MuiDataGrid-cellContent': {
            whiteSpace: 'normal !important',
            wordWrap: 'break-word !important',
        },
        '& .MuiDataGrid-cell:focus': { outline: 'none' },
        '& .MuiDataGrid-cell:hover': { cursor: 'pointer' },
        '& .MuiDataGrid-cell': { outline: 'none !important' },
        '& .MuiDataGrid-row--detailPanelExpanded': { background: `${COLORS.EXTRA_LIGHT_GRAY} !important` },
        '& .MuiDataGrid-columnSeparator': { display: 'none' },
        '& .MuiDataGrid-row.Mui-selected': { background: COLORS.EXTRA_LIGHT_GRAY },
        '& .MuiDataGrid-detailPanel': { background: COLORS.EXTRA_LIGHT_GRAY },
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100%',
            height: '100%',
        },
        '& .MuiDataGrid-row--detailPanelExpanded:last-child': {
            '& .MuiDataGrid-cell': { borderBottom: `1px solid ${COLORS.BLACK_12}` },
        },
    },
    dataGridMobile: {
        border: 'none',
        '& .MuiDataGrid-columnHeaders': { borderBottom: 0 },
        '& .MuiDataGrid-cell': {
            padding: '0 !important',
            border: 'none !important',
        },
    },
    hideHeader: {
        '& .MuiDataGrid-columnHeaders': { display: 'none' },
        '& .MuiDataGrid-virtualScroller': { marginTop: '0 !important' },
    },
    rowMinHeight52: { '& .MuiDataGrid-main': { minHeight: '52px !important' } },
    hideSeparator: { '& > .MuiDataGrid-columnSeparator': { visibility: 'hidden' } },
    menuItem: {
        padding: '.75rem 1rem',
        '&:hover': { backgroundColor: COLORS.LIGHT_GRAY },
        cursor: 'pointer',
        display: 'flex',
    },
    hideItem: { display: 'none' },
    dataGridNoRows: {
        '& .MuiDataGrid-overlayWrapper': {
            width: '100%',
            height: '100%',
        },
        '& .MuiDataGrid-overlayWrapperInner': {
            height: '100% !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& .MuiDataGrid-virtualScroller': { overflowY: 'hidden' },
    },
    iconNoRows: {
        height: 200,
        width: 470,
    },
    tableHeader: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '2rem',
        borderRight: `1px solid ${COLORS.BLACK_12}`,
    },
    noRightBorder: { borderRight: 'none' },
    noMarginTop: { marginTop: 0 },
    header: {
        '& .MuiDataGrid-columnHeaderTitle': {
            color: COLORS.BLACK,
            fontWeight: 700,
        },
    },
    truncateText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    hideColumnHeaderRow: {
        '& .MuiDataGrid-columnHeaders': { display: 'none' },
        marginTop: '2rem',
    },
    font14: {
        '& .MuiTypography-root': {
            fontSize: '0.875rem',
            lineHeight: '1.075rem',
        },
    },
});

export default useDataGridStyles;
