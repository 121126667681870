import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import SportsScoreOutlinedIcon from '@mui/icons-material/SportsScoreOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Box, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownButtonMenu from '../../../components/dropdownButtonMenu/DropdownButtonMenu';
import { REPEATS } from '../../../constants';
import { COLORS } from '../../../constants/colors';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { BlockedPeriodMessage } from '../../../helpers/repeats';
import { BlockedPeriod } from '../api/types';
import ConfirmDeleteModal from './ConfirmDeleteModal';

const useStyles = makeStyles({
    tableRow: { cursor: 'pointer' },
    tableCell: {
        color: COLORS.BLACK,
        background: COLORS.WHITE,
    },
    editButton: {
        width: '150px',
        marginRight: '1rem',
        marginLeft: '0.2rem',
        color: COLORS.BLUE,
        border: `2px solid ${COLORS.BLUE}`,
    },
    deleteButton: {
        width: '150px',
        color: COLORS.RED,
        border: `2px solid ${COLORS.RED}`,
    },
    accordion: {
        '& .MuiAccordionSummary-root': { padding: '0 0.5rem' },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 'unset',
            background: COLORS.EXTRA_LIGHT_GRAY,
            borderBottom: `1px solid ${COLORS.BLACK_12}`,
        },
        '& .MuiAccordionSummary-content': { margin: '1rem 0' },
        '& .MuiAccordionSummary-content.Mui-expanded': { margin: '1rem 0' },
        border: 'none',
        boxShadow: 'none',
    },
    mobileContent: {
        color: COLORS.BLACK,
        marginLeft: '0.3rem',
    },
});

interface TableRowMobileProps {
    repeats: REPEATS;
    blockedPeriod: BlockedPeriod;
    blockedPeriodMessage: BlockedPeriodMessage;
    handleEditBlockPeriod: (id: number) => void;
    handleDeleteBlockPeriod: (id: number) => Promise<void>;
}

const TableRowMobile = ({
    repeats,
    blockedPeriod,
    blockedPeriodMessage,
    handleEditBlockPeriod,
    handleDeleteBlockPeriod,
}: TableRowMobileProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const { showNotification } = useNotificationContext();

    const handleSubmitDelete = async (id: number) => {
        try {
            await handleDeleteBlockPeriod(id);
        } catch (error) {
            showNotification(t('errors.deleteBlockedPeriod'), Severity.Error);
        } finally {
            setIsConfirmDeleteModalOpen(false);
        }
    };

    return (
        <>
            <TableRow key={blockedPeriod.id} className={classes.tableRow}>
                <TableCell className={classes.tableCell} sx={{ padding: '0' }} colSpan={3}>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlinedIcon />}
                            aria-controls={`panel${blockedPeriod.id}-content`}
                        >
                            <Typography>{blockedPeriod.description}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{
                                display: 'flex',
                                padding: '1rem 0.3rem',
                            }}
                            onClick={() => handleEditBlockPeriod(blockedPeriod.id)}
                        >
                            <Box display="flex" flexDirection="column" alignItems="flex-start">
                                <Box display="flex" alignItems="center" margin="0 0.5rem 0.75rem 0" color={COLORS.SLATE_GREY}>
                                    <Tooltip title={t('pages.availability.startsOn')} arrow>
                                        <FlagOutlinedIcon />
                                    </Tooltip>
                                    <Typography className={classes.mobileContent}>{blockedPeriodMessage.part1}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" margin="0 0.5rem 0.75rem 0" color={COLORS.SLATE_GREY}>
                                    <Tooltip title={t('pages.availability.endsOn')} arrow>
                                        <SportsScoreOutlinedIcon />
                                    </Tooltip>
                                    <Typography className={classes.mobileContent}>{blockedPeriodMessage.part2}</Typography>
                                </Box>
                                {repeats !== REPEATS.DO_NOT_REPEAT && (
                                    <Box display="flex" alignItems="center" margin="0 0.5rem 0 0" color={COLORS.SLATE_GREY}>
                                        <Tooltip title={t('pages.availability.repeatingRules')} arrow>
                                            <RepeatOutlinedIcon />
                                        </Tooltip>
                                        <Typography className={classes.mobileContent}>{blockedPeriodMessage.part3}</Typography>
                                    </Box>
                                )}
                                <Box display="flex" justifyContent="flex-start" margin="1rem 0 0 0" color={COLORS.SLATE_GREY}>
                                    <DropdownButtonMenu
                                        buttonLabel="buttonName.edit"
                                        buttonIcon={<EditOutlinedIcon />}
                                        onClick={() => handleEditBlockPeriod(blockedPeriod.id)}
                                        options={[
                                            {
                                                id: 1,
                                                label: t('buttonName.delete'),
                                                icon: DeleteOutlineOutlinedIcon,
                                                onClick: () => setIsConfirmDeleteModalOpen(true),
                                            },
                                        ]}
                                    />
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </TableCell>
            </TableRow>
            {isConfirmDeleteModalOpen && (
                <ConfirmDeleteModal
                    isModalOpen={isConfirmDeleteModalOpen}
                    onClose={() => setIsConfirmDeleteModalOpen(false)}
                    repeats={repeats}
                    blockedPeriodMessage={blockedPeriodMessage}
                    submitDelete={() => handleSubmitDelete(blockedPeriod.id)}
                />
            )}
        </>
    );
};

export default TableRowMobile;
