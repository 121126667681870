/* eslint-disable complexity */
import { IconedData } from '@get-e/react-components';
import { Room, Person, Luggage, Help, CalendarToday } from '@mui/icons-material';
import { Box, FormControlLabel, FormGroup, Grid, Switch, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CopyToClipboardButton from '../../../../components/buttons/CopyToClipboardButton';
import { COLORS } from '../../../../constants/colors';
import { DATE_FORMATS } from '../../../../constants/dateFormats';
import { getDateTimeWithoutOffsetFormatted } from '../../../../helpers/getDateTimeWithoutOffset';
import metersToKmAndMiFormatted from '../../../../helpers/metersToKmAndMiFormatted';
import secondsToTimeFormatted from '../../../../helpers/secondsToTimeFormatted';
import theme from '../../../../styles/theme';
import { Passenger, RideResponse } from '../../api/types';
import withExpandable from '../../components/withExpandable';

const useStyles = makeStyles(styleTheme => ({
    rideInfoContainer: {
        width: '100%',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '0.5rem',
        marginTop: '1rem',
        padding: '1rem',
        position: 'relative',
    },
    utcSwitch: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'baseline',
        '& label': {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    headingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    heading: {
        color: COLORS.BLUE,
        fontSize: '1rem',
        fontWeight: 700,
        marginBottom: 0,
    },
    contentContainer: {
        color: COLORS.SLATE_GREY,
        '& .MuiSvgIcon-root': {
            marginTop: '-0.1rem',
            marginRight: '0.5rem',
        },
    },
    pickupContent: {
        paddingLeft: '1.2rem',
        borderLeft: `2px solid ${COLORS.SLATE_GREY}`,
        marginLeft: '0.7rem',
    },
    dropoffContent: {
        marginTop: '0rem',
        paddingLeft: '2.1rem',
    },
    timeDateContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '1.5rem',
        [styleTheme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    modal: {
        border: 'none',
        padding: '0',
        marginBottom: '1.5rem',
    },
}));

interface RideInformationCardProps {
    data?: RideResponse;
    showUtcSwitch: boolean;
    isInModal?: boolean;
    renderChevrons: JSX.Element;
    isExpanded: boolean;
    isSupplierPage?: boolean;
    showUtcTime: boolean;
    setShowUtcTime: (show: boolean) => void;
}

const getPassengersNamesList = (passengers: Passenger[]): string =>
    passengers.map(passenger => `${passenger.firstName} ${passenger.lastName}`).join(', ');

const RideInformationCard = ({
    data,
    showUtcSwitch,
    isInModal,
    renderChevrons,
    isExpanded,
    isSupplierPage,
    showUtcTime,
    setShowUtcTime,
}: RideInformationCardProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const distance = useMemo(() => metersToKmAndMiFormatted(data?.distanceInMeters), [data?.distanceInMeters]);
    const time = useMemo(() => secondsToTimeFormatted(data?.durationInSeconds), [data?.durationInSeconds]);

    const passengerNames = getPassengersNamesList(data?.passengers || []);

    const pickUpDateAndTime = useMemo(() => {
        if (!data?.pickUp?.departAtLocal) {
            return '';
        }

        return getDateTimeWithoutOffsetFormatted(data?.pickUp?.departAtLocal || '', DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A']);
    }, [data?.pickUp?.departAtLocal]);

    const getCopyToClipboardText = () => {
        return [
            `${t('ride')}: ${data?.prettifiedUnid}`,
            `${t('pages.singleRide.fields.pickUpDate')}: ${pickUpDateAndTime}`,
            `${t('pickUpLocation')}: ${data?.pickUp?.location.address}`,
            `${t('dropOffLocation')}: ${data?.dropOff?.location.address}`,
            `${t('specialRequest')}: ${data?.specialRequest}`,
            `${t('vehicleType')}: ${data?.vehicle?.type}`,
            `${t('numberOfPassengers')}: ${data?.numberOfPassengers}`,
            `${t('piecesOfLuggage')}: ${data?.numberOfBags}`,
            `${t('passengers')}: ${passengerNames}`,
        ].join('\n');
    };

    return (
        <Grid
            container
            sx={{ borderRadius: isMobile && showUtcSwitch ? '0 0 0.5rem 0.5rem !important' : '' }}
            className={`${classes.rideInfoContainer} ${isInModal ? classes.modal : ''}`}
        >
            <Grid className={classes.headingContainer}>
                <Typography variant="h3" component="h4" className={classes.heading}>
                    {t('pages.singleRide.rideInformation.title')}
                    {isSupplierPage && <CopyToClipboardButton text={getCopyToClipboardText()} />}
                </Typography>
                {showUtcSwitch && isExpanded && (
                    <FormGroup className={classes.utcSwitch}>
                        <FormControlLabel
                            control={<Switch checked={showUtcTime} onClick={() => setShowUtcTime(!showUtcTime)} />}
                            label={t('pages.singleRide.local')}
                            labelPlacement="start"
                        />
                        {t('pages.singleRide.utc')}
                    </FormGroup>
                )}
                {!isInModal && renderChevrons}
            </Grid>
            {isExpanded && (
                <Grid className={classes.contentContainer} marginTop={isExpanded ? '1rem' : '0'}>
                    <IconedData
                        icon={Room}
                        dataLabel={t('pages.singleRide.rideInformation.pickup')}
                        data=""
                        dataLabelColor={COLORS.BLACK}
                        isColumn
                        fontSize=".75rem"
                        dataLabelBold={false}
                        iconSize="1.25rem"
                    />

                    <Grid className={classes.pickupContent}>
                        <Typography color={COLORS.BLACK} fontWeight={700}>
                            {data?.pickUp?.location?.name}
                            {isSupplierPage && (
                                <CopyToClipboardButton iconMarginRight="0" text={data?.pickUp?.location?.name || ''} />
                            )}
                        </Typography>
                        <Typography color={COLORS.BLACK}>{data?.pickUp?.location?.address}</Typography>
                        <Typography color={COLORS.BLACK} marginTop="1rem" marginBottom="0.5rem" fontSize=".75rem">
                            {distance} ({time})
                        </Typography>
                    </Grid>

                    <IconedData
                        icon={Room}
                        dataLabel={t('pages.singleRide.rideInformation.dropOff')}
                        data=""
                        dataLabelColor={COLORS.BLACK}
                        isColumn
                        fontSize=".75rem"
                        dataLabelBold={false}
                        iconSize="1.25rem"
                    />

                    <Grid className={classes.dropoffContent}>
                        <Typography color={COLORS.BLACK} fontWeight={700}>
                            {data?.dropOff?.location?.name}
                            {isSupplierPage && (
                                <CopyToClipboardButton iconMarginRight="0" text={data?.dropOff?.location?.name || ''} />
                            )}
                        </Typography>
                        <Typography color={COLORS.BLACK}>{data?.dropOff?.location?.address}</Typography>
                    </Grid>

                    <Box className={classes.timeDateContainer}>
                        <IconedData
                            dataTextBold
                            icon={CalendarToday}
                            dataLabel={t('pages.singleRide.rideInformation.pickupTimeAndDate')}
                            data={
                                showUtcTime
                                    ? dayjs.utc(data?.pickUp.departAt).format(DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A'])
                                    : getDateTimeWithoutOffsetFormatted(
                                          data?.pickUp?.departAtLocal || '',
                                          DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A']
                                      )
                            }
                            dataLabelColor={COLORS.BLACK}
                            isColumn
                            topMargin={isMobile ? '0.75rem' : '0'}
                            fontSize=".75rem"
                            fontTextSize="1rem"
                            dataLabelBold={false}
                            iconSize="1.25rem"
                        />
                        <IconedData
                            dataTextBold
                            icon={Person}
                            dataLabel={t('pages.singleRide.rideInformation.passengers')}
                            data={data?.numberOfPassengers || '-'}
                            dataLabelColor={COLORS.BLACK}
                            isColumn
                            topMargin={isMobile ? '0.75rem' : '0'}
                            fontSize=".75rem"
                            fontTextSize="1rem"
                            dataLabelBold={false}
                            iconSize="1.25rem"
                        />

                        <IconedData
                            dataTextBold
                            icon={Luggage}
                            dataLabel={t('pages.singleRide.rideInformation.luggage')}
                            data={data?.numberOfBags || '-'}
                            dataLabelColor={COLORS.BLACK}
                            isColumn
                            topMargin={isMobile ? '0.75rem' : '0'}
                            fontSize=".75rem"
                            fontTextSize="1rem"
                            dataLabelBold={false}
                            iconSize="1.25rem"
                        />
                    </Box>

                    <IconedData
                        dataTextBold
                        icon={Help}
                        dataLabel={t('pages.singleRide.rideInformation.specialRequest')}
                        data={data?.specialRequest || '-'}
                        dataLabelColor={COLORS.BLACK}
                        isColumn
                        topMargin={isMobile ? '0.75rem' : '1.5rem'}
                        fontSize=".75rem"
                        fontTextSize="1rem"
                        dataLabelBold={false}
                        iconSize="1.25rem"
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default withExpandable(RideInformationCard);
