import { parse, isValid, format } from 'date-fns';

import { Validated } from '../Validator';

const isValidTimeFormat = <TError>(value: Date | null, timeFormat: string, error: TError): Validated<Date, TError> => {
    const isValidTime = isValid(value);

    const formattedTime = isValidTime && value ? format(value, timeFormat) : null;

    const parsedTime = formattedTime ? parse(formattedTime, timeFormat, new Date()) : null;

    const isValidFormattedTime = parsedTime ? isValid(parsedTime) : false;

    return value === null || !isValidFormattedTime
        ? {
              isValid: false,
              error,
          }
        : {
              isValid: true,
              value,
          };
};

export default isValidTimeFormat;
