import { ContactMail, Nightlight, PersonAdd, AirplanemodeInactiveOutlined } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import { getRideStatusOptionKey, RideStatusOption } from '../../../helpers/rideStatusUtil';

const useStyles = makeStyles({
    optionIcon: {
        marginRight: '0.5rem',
        fontSize: '1rem !important',
        color: COLORS.SLATE_GREY,
    },
});

interface CellTextProps {
    statusOptions?: Array<keyof typeof RideStatusOption>;
}

const StatusOptionBadge = ({ statusOptions }: CellTextProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const isRideStatusOptionVisible = (option: RideStatusOption) =>
        Boolean(statusOptions?.includes(getRideStatusOptionKey(option)));

    return (
        <Box
            sx={{
                width: 'auto',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {isRideStatusOptionVisible(RideStatusOption.DRIVER_KNOWN) && (
                <Tooltip title={t('driverKnown')} placement="bottom" arrow>
                    <ContactMail className={classes.optionIcon} />
                </Tooltip>
            )}
            {isRideStatusOptionVisible(RideStatusOption.NO_FLIGHT_INFORMATION) && (
                <Tooltip title={t('noFlightInformation')} placement="bottom" arrow>
                    <AirplanemodeInactiveOutlined className={classes.optionIcon} />
                </Tooltip>
            )}
            {isRideStatusOptionVisible(RideStatusOption.OVERNIGHT_FLIGHT) && (
                <Tooltip title={t('overnightFlight')} placement="bottom" arrow>
                    <Nightlight className={classes.optionIcon} />
                </Tooltip>
            )}
            {isRideStatusOptionVisible(RideStatusOption.CONTACT_GREETER_INFO_KNOWN) && (
                <Tooltip title={t('contactInfo')} placement="bottom" arrow>
                    <PersonAdd className={classes.optionIcon} />
                </Tooltip>
            )}
        </Box>
    );
};

export default StatusOptionBadge;
