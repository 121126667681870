import FlightLandOutlinedIcon from '@mui/icons-material/FlightLandOutlined';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridRowModel } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';

import { COLORS } from '../../../../../constants/colors';
import { getAirlineIataImage } from '../../../../../services/routes';
import FlightStatusBadge from '../../../components/FlightStatusBadge';

interface FlightInfoProps {
    row: GridRowModel;
}

export const FlightInfo = ({ row }: FlightInfoProps) => {
    const [imageError, setImageError] = useState(false);

    const airlineAbrevation = row.flightDetails.number ? row.flightDetails.number.replace(/[^a-zA-Z]/g, '') : '';

    const handleImageError = () => {
        setImageError(true);
    };

    return row.flightDetails.number ? (
        <Box display="flex" alignItems="center" justifyContent="flex-start" marginTop=".25rem">
            {!imageError ? (
                <img
                    src={getAirlineIataImage(airlineAbrevation, 75, 20)}
                    alt="Airline"
                    onError={handleImageError}
                    style={{ marginRight: '.5rem' }}
                />
            ) : (
                <FlightLandOutlinedIcon
                    style={{
                        color: COLORS.SLATE_GREY,
                        marginRight: '.5rem',
                        fontSize: '1rem',
                    }}
                />
            )}
            <Typography
                sx={{
                    fontSize: '0.75rem',
                    lineHeight: '0.875rem',
                    color: COLORS.SLATE_GREY,
                    marginRight: '0.5rem',
                    overflow: 'hidden',
                    width: '100%',
                }}
            >
                {row.flightDetails.number}
            </Typography>
            <FlightStatusBadge status={row.flightStatus} showTooltip />
        </Box>
    ) : null;
};
