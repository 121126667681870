import { ModalAction as Modal } from '@get-e/react-components';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Traveller } from './travellerInformation/TravellerInformation';

const RemoveTravellerConfirmModal = ({
    onClose,
    onConfirm,
    traveller,
}: {
    onClose: () => void;
    onConfirm: (id: string) => void;
    traveller: Traveller;
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={() => onConfirm(traveller.id)}
            title={t('modals.removePassenger.title')}
            confirmButtonLabel={t('buttonName.confirm')}
            cancelButtonLabel={t('buttonName.cancel')}
            maxWidth="sm"
        >
            <Typography>
                {t('modals.removePassenger.descriptionPart1')}
                <strong> {`${traveller.firstName} ${traveller.lastName}`} </strong>
                {t('modals.removePassenger.descriptionPart2')}
            </Typography>
            <Typography style={{ fontWeight: 700 }}>{t('doYouWantToProceed')}</Typography>
        </Modal>
    );
};

export default RemoveTravellerConfirmModal;
