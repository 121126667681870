import { Coordinates } from '../../../services/types';
import { FlightStatus } from '../../ride/customer/helpers/flightStatusUtil';

export enum LocationType {
    AIRPORT = 'AIRPORT',
    PLACE = 'PLACE',
}
export interface Location {
    id: string;
    name: string;
    address: string | null;
    type: string;
    coordinates: Coordinates;
}

export interface Passenger {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isLead: boolean;
    name?: string;
}

export interface Arrival {
    arriveAt: string;
    arriveAtLocal: string;
    arrivalTimeZone?: string;
    location: Location;
}

export interface Departure {
    departAt: string;
    departAtLocal: string;
    departureTimeZone?: string;
    location: Location;
}

export enum FlightType {
    ARRIVAL = 'Arrival',
    DEPARTURE = 'Departure',
}

export interface FlightDetails {
    number: string;
    type: FlightType;
    airlineName?: string;
    status?: string;
    arrivalTime?: string;
    departure: {
        datetimeLocal: string;
        datetimeZulu: string;
        iata: string;
        name?: string;
    };
    arrival: {
        datetimeLocal: string;
        datetimeZulu: string;
        iata: string;
        name?: string;
    };
}

interface FlightTimes {
    scheduled: string | null;
    scheduledUTC: string | null;
    delayed: string | null;
    delayedUTC: string | null;
}

interface AirportDetails {
    airportIata: string | null;
    airportName: string | null;
    airportCity: string;
    terminal: string | null;
    gate: string | null;
    times: FlightTimes;
}

export interface FlightDetailsNew {
    flightNumber: string;
    flightStatus: FlightStatus;
    updatedAtUTC: string | null;
    isSubscribed: boolean | null;
    airlineIata: string | null;
    departure: AirportDetails;
    arrival: AirportDetails;
}

export interface FlightSearchResponse {
    id: number;
    airlineName?: string;
    departure: {
        dateTimeLocal: string;
        dateTimeZulu: string;
        iata: string;
        name?: string;
    };
    arrival: {
        dateTimeLocal: string;
        dateTimeZulu: string;
        iata: string;
        name?: string;
    };
}
export interface RidesVariables {
    query: string;
    statusFilters: string[];
    startDate: string;
    endDate: string;
}

export interface DownloadPdfParams {
    unid: string;
    prettifiedUnid: string;
}

export interface DownloadExport {
    startDate: string;
    endDate: string;
    tripUnids?: string[];
}
