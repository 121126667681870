import { Spinner } from '@get-e/react-components';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import { Button, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import { RIDE_INFORMATION_VEHICLE_CARD } from '../../../constants/amplitude/customerKeys';
import { COLORS } from '../../../constants/colors';
import { RideContext } from '../customer/context/RideContext';
import NoVehicleAvailable from './NoVehicleAvailable';
import VehicleInformationCard from './VehicleInformationCard';

const useStyles = makeStyles({
    button: {
        color: COLORS.BLUE,
        marginTop: '1rem',
    },
});

export interface Vehicle {
    id: string;
    vehicleClass: string;
    vehicleType: string;
    vehicleImage: string;
    travellers: string;
    luggages: string;
    price: number;
    priceCurrency: string;
    carId?: string;
}

interface VehicleInformationCardsProp {
    selectedVehicle: Vehicle | null;
    setSelectedVehicle: Dispatch<SetStateAction<Vehicle | null>>;
    setIsUpdateNotificationOpen: Dispatch<SetStateAction<boolean>>;
    vehicleList: Vehicle[];
    isError?: boolean;
    setIsError?: () => void;
    showAvailabilityOptions: () => void;
    isLoading: boolean;
}

const VehicleInformationCards = ({
    selectedVehicle,
    setSelectedVehicle,
    vehicleList = [],
    isError = false,
    setIsError,
    setIsUpdateNotificationOpen,
    showAvailabilityOptions,
    isLoading,
}: VehicleInformationCardsProp) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { isDisabled, amplitudeEventProperties } = useContext(RideContext);

    const onCardClick = (vehicle: Vehicle | null) => {
        if (isDisabled || isLoading) {
            return;
        }

        setSelectedVehicle(vehicle);
        setIsError?.();
        setIsUpdateNotificationOpen(false);

        logAmplitudeEvent(RIDE_INFORMATION_VEHICLE_CARD, amplitudeEventProperties);
    };

    return (
        <>
            {selectedVehicle && (
                <VehicleInformationCard
                    key={selectedVehicle.id}
                    isVehicleSelected={true}
                    onCardClick={() => onCardClick(selectedVehicle)}
                    vehicleClass={selectedVehicle.vehicleClass}
                    vehicleType={selectedVehicle.vehicleType}
                    vehicleImage={selectedVehicle.vehicleImage}
                    luggages={selectedVehicle.luggages}
                    travellers={selectedVehicle.travellers}
                    price={(Math.round((selectedVehicle?.price ?? 0) * 100) / 100).toFixed(2)}
                    priceCurrency={selectedVehicle.priceCurrency}
                />
            )}
            {vehicleList.length > 0 &&
                !selectedVehicle &&
                vehicleList.map((vehicle: Vehicle) => (
                    <VehicleInformationCard
                        key={vehicle.id}
                        isVehicleSelected={false}
                        onCardClick={() => onCardClick(vehicle)}
                        vehicleClass={vehicle.vehicleClass}
                        vehicleType={vehicle.vehicleType}
                        vehicleImage={vehicle.vehicleImage}
                        luggages={vehicle.luggages}
                        travellers={vehicle.travellers}
                        price={(Math.round((vehicle.price ?? 0) * 100) / 100).toFixed(2)}
                        priceCurrency={vehicle.priceCurrency}
                    />
                ))}
            {vehicleList.length === 0 && !selectedVehicle && !isLoading && <NoVehicleAvailable />}
            {isError && <FormHelperText error>{t('errors.selectVehicle')}</FormHelperText>}
            <Button
                variant="outlined"
                disabled={isLoading || !selectedVehicle || isDisabled}
                startIcon={isLoading ? <Spinner size={20} /> : <DirectionsCarFilledOutlinedIcon />}
                className={classes.button}
                onClick={showAvailabilityOptions}
            >
                {t('pages.rides.showOptions')}
            </Button>
        </>
    );
};

export default VehicleInformationCards;
