import React from 'react';
import { useTranslation } from 'react-i18next';
import largeLogo from '../../public/assets/images/logoDarkBackground.svg';
import smallLogo from '../../public/assets/images/logoIcon.svg';
import navigationLogoStyles from './NavigationLogo.styles';

interface LogoProps {
    closed: boolean;
}

const NavigationLogo = ({ closed }: LogoProps): JSX.Element => {
    const currentYear = new Date().getFullYear();

    const classes = navigationLogoStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.companyInformationContainer}>
            {closed ? (
                <img src={smallLogo} className={classes.smallLogo} alt={t('getE.logo.alt')} />
            ) : (
                <>
                    <img src={largeLogo} className={classes.largeLogo} alt={t('getE.logo.alt')} />
                    <p className={classes.companyInformation}>{t('getE.companyName')}</p>
                    <p className={classes.companyInformation}>2015-{currentYear}</p>
                </>
            )}
        </div>
    );
};

export default NavigationLogo;
