/* eslint-disable func-names */
import ExploreOffOutlinedIcon from '@mui/icons-material/ExploreOffOutlined';
import { Box, Typography } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import Pusher from 'pusher-js';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../config';
import { COLORS } from '../../constants/colors';
import { TRIP_POSITION_UPDATE } from '../../constants/events';
import currentEnvironment from '../../environment/currentEnvironment';
import Environment from '../../environment/Environment';
import fetchDirections from '../../helpers/googleMaps/fetchDirections';
import { bootstrapURLKeys } from '../../helpers/googleMaps/googleApi';
import useEffectAsync from '../../hooks/useEffectAsync';
import driverIcon from '../../public/assets/images/map-marker-icon.png';
import { PUSHER_AUTH } from '../../services/routes';
import { Coordinates } from '../../services/types';

export type LatLng = google.maps.LatLng;
export type Place = google.maps.Place;
export type Map = google.maps.Map;
export type Marker = google.maps.Marker;

export interface Point {
    longitude: number;
    latitude: number;
}

interface TripPosition {
    position: Point;
}

const DEFAULT_MAP_ZOOM = 11;

let cachedDirectionsRenderer: google.maps.DirectionsRenderer | null = null;

let cachedMarker: Marker | null = null;

const getDirectionsRenderer = () => {
    if (cachedDirectionsRenderer === null) {
        cachedDirectionsRenderer = new google.maps.DirectionsRenderer();
    }

    return cachedDirectionsRenderer;
};

interface DirectonPlacesProps {
    pickupCoordinates?: Coordinates;
    dropoffCoordinates?: Coordinates;
    pickupPlaceId?: string;
    dropoffPlaceId?: string;
}

const getDirectionPlaces = (directionPlacesProps: DirectonPlacesProps): Place[] => {
    return [
        {
            location: directionPlacesProps.pickupCoordinates
                ? new google.maps.LatLng(directionPlacesProps.pickupCoordinates.lat, directionPlacesProps.pickupCoordinates.lon)
                : undefined,
            placeId: !directionPlacesProps.pickupCoordinates ? directionPlacesProps.pickupPlaceId : undefined,
        },
        {
            location: directionPlacesProps.dropoffCoordinates
                ? new google.maps.LatLng(directionPlacesProps.dropoffCoordinates.lat, directionPlacesProps.dropoffCoordinates.lon)
                : undefined,
            placeId: !directionPlacesProps.dropoffCoordinates ? directionPlacesProps.dropoffPlaceId : undefined,
        },
    ];
};

Pusher.Runtime.createXHR = function () {
    const xhr = new XMLHttpRequest();

    xhr.withCredentials = true;
    return xhr;
};

const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_KEY ?? '', {
    cluster: 'eu',
    encrypted: true,
    authEndpoint: `${config.apiUrl}${PUSHER_AUTH}`,
});

const subscribeToPusher = (channelId: string, map: Map) => {
    const channel = pusherClient.subscribe(channelId ?? '');

    channel.bind(TRIP_POSITION_UPDATE, (data: TripPosition) => {
        setMarker(map, data);
    });
};

const setMarker = (map: Map, data: TripPosition) => {
    if (cachedMarker === null) {
        cachedMarker = new google.maps.Marker({
            position: {
                lat: data.position.latitude,
                lng: data.position.longitude,
            },
            map,
            title: '',
            icon: driverIcon,
        });
    } else {
        cachedMarker.setPosition({
            lat: data.position.latitude,
            lng: data.position.longitude,
        });
    }
};

const GoogleMap: FunctionComponent<{
    points?: Array<Coordinates | undefined>;
    placeIds?: string[];
    driverPosition?: Point;
    onLoaded?: () => void;
    zoom?: number;
    subscribeChannelId?: string;
}> = ({ points, placeIds, driverPosition, zoom = DEFAULT_MAP_ZOOM, subscribeChannelId }) => {
    const { t } = useTranslation();
    const googleMap = useRef<Map | null>(null);
    const [isError, setIsError] = useState(false);
    const pickupCoordinates = points?.[0];
    const dropoffCoordinates = points?.[1];
    const pickupPlaceId = placeIds?.[0] ?? '';
    const dropoffPlaceId = placeIds?.[1] ?? '';

    const handleRenderRoute = async () => {
        const directionsRenderer = getDirectionsRenderer();

        const directionPlaces = getDirectionPlaces({
            pickupCoordinates,
            dropoffCoordinates,
            pickupPlaceId,
            dropoffPlaceId,
        });

        try {
            const directions = await fetchDirections(directionPlaces);

            directionsRenderer.setDirections(directions);
        } catch {
            setIsError(true);
        }
    };

    const handleApiLoaded = async (map: Map) => {
        googleMap.current = map;
        const directionsRenderer = getDirectionsRenderer();

        directionsRenderer.setMap(map);

        await handleRenderRoute();

        if (currentEnvironment !== Environment.Development && subscribeChannelId) {
            subscribeToPusher(subscribeChannelId, map);
        }

        if (driverPosition && driverPosition.latitude && driverPosition.longitude) {
            setMarker(map, { position: driverPosition } as TripPosition);
        }
    };

    useEffectAsync(async () => {
        if (googleMap.current) {
            setIsError(false);
            await handleRenderRoute();
        }
    }, [pickupCoordinates, dropoffCoordinates, pickupPlaceId, dropoffPlaceId]);

    return (
        <>
            {isError ? (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                    <ExploreOffOutlinedIcon
                        style={{
                            color: COLORS.SLATE_GREY,
                            fontSize: '3rem',
                        }}
                    />
                    <Typography marginTop="0.5rem">{t('noRouteAvailable')}</Typography>
                </Box>
            ) : (
                <GoogleMapReact
                    bootstrapURLKeys={bootstrapURLKeys}
                    center={{
                        lat: pickupCoordinates?.lat ?? 0,
                        lng: pickupCoordinates?.lon ?? 0,
                    }}
                    zoom={zoom}
                    yesIWantToUseGoogleMapApiInternals
                    options={{ mapTypeControl: true }}
                    onGoogleApiLoaded={({ map }) => handleApiLoaded(map)}
                />
            )}
        </>
    );
};

export default GoogleMap;
