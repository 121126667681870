import React, { FunctionComponent, useRef } from 'react';
import { useHistory } from 'react-router';

import { logAmplitudeEvent } from '../../amplitude/amplitude';
import ErrorPage from '../../components/errorPage/ErrorPage';
import { UNEXPECTED_ERROR_PAGE } from '../../constants/amplitude/commonKeys';
import { carIcon } from '../../public/assets/icons';

const UnexpectedError: FunctionComponent<{
    onRetry?: () => void;
}> = ({ onRetry }) => {
    const history = useHistory();
    const isPageOpened = useRef(false);

    const onRefresh = () => history.go(0);

    if (!isPageOpened.current) {
        isPageOpened.current = true;
        logAmplitudeEvent(UNEXPECTED_ERROR_PAGE);
    }

    return (
        <ErrorPage
            icon={carIcon}
            header="An error occurred."
            description="Please retry or contact our support team."
            buttonTitle={onRetry ? 'Retry' : 'Refresh'}
            onButtonClick={onRetry ?? onRefresh}
        />
    );
};

export default UnexpectedError;
