import { Grid, Skeleton, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    mainWrapper: {
        alignItems: 'center',
        padding: 0,
    },
    headerTitleWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
    },
    headerTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 0,
        [theme.breakpoints.down('md')]: { marginBottom: '0.5rem' },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0.5rem',
            marginBottom: '0.5rem',
        },
    },
    content: {
        width: '100%',
        height: 'calc(100vh - 263px)',
        marginTop: '2rem',
    },
}));

const InvoicesLoadingSkeleton = () => {
    const classes = useStyles();

    return (
        <Grid container className={classes.mainWrapper}>
            <Grid item xs={12} className={classes.headerTitleWrapper}>
                <Grid item className={classes.headerTitle}>
                    <Skeleton variant="rectangular" width="200px" height={40} />
                </Grid>
            </Grid>

            <Box className={classes.content}>
                <Skeleton variant="rectangular" width="100%" height="100%" />
            </Box>
        </Grid>
    );
};

export default InvoicesLoadingSkeleton;
