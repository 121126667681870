/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable complexity */
import { SecondaryButton, IconedData } from '@get-e/react-components';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { zonedTimeToUtc } from 'date-fns-tz';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import PageActionButton from '../../../components/buttons/PageActionButton';
import { EDIT_SINGLE_RIDE, SINGLE_RIDE_PAGE } from '../../../constants/amplitude/commonKeys';
import {
    CONFIRM_UPDATE,
    DONWLOAD_DOCUMENTATION,
    RIDE_INFORMATION_FLIGHT_TRACKING,
    SEND_CONFIRMATION,
    UPDATE_RIDE,
} from '../../../constants/amplitude/customerKeys';
import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { getSingleRideRoute } from '../../../constants/urlPaths';
import { useCurrentProfileContext } from '../../../context/CurrentProfileContext';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { getDateTimeWithoutOffset } from '../../../helpers/getDateTimeWithoutOffset';
import { mapRideToContext } from '../../../helpers/maps/mapRideToContext';
import { RideStatus, getRideStatusKey } from '../../../helpers/rideStatusUtil';
import { PrimaryTimeZone } from '../../../services/types';
import theme from '../../../styles/theme';
import { FlightDetails, LocationType, Passenger } from '../../rides/api/types';
import StatusBadge from '../../rides/components/StatusBadge';
import downloadBookingConfirmation from '../../rides/customer/api/downloadBookingConfirmation';
import downloadReceipt from '../../rides/customer/api/downloadReceipt';
import { CustomFieldItem, PAYMENT_METHOD, RideAmplitudeEventProperties } from '../api/types';
import { useRide } from '../api/useRide';
import LeavePageModal from '../components/LeavePageModal';
import { PassengerList } from '../components/passengerInformation/PassengerList';
import { Ride as RideType } from '../components/RideInformation';
import SendBookingConfirmationModal, { BookingConfirmationEmail } from '../components/SendBookingConfirmationModal';
import { Traveller } from '../components/travellerInformation/TravellerInformation';
import ChangeReferences, { ReferenceField } from '../components/updateRide/ChangeReferences';
import ChangeRideInformation from '../components/updateRide/ChangeRideInformation';
import VehicleOptions from '../components/updateRide/VehicleOptions';
import UpdateRideConfirmModal from '../components/UpdateRideConfirmModal';
import { Vehicle } from '../components/VehicleInformationCards';
import { updateFlightNumber, updateRideInformation, updateRideVehicle, updateTravellerInformation } from './api';
import { useAvailabilites } from './api/useAvailabilites';
import { useFlightSearch } from './api/useFlightSearch';
import RideLoadingSkeleton from './components/rideLoadingSkeleton/RideLoadingSkeleton';
import RideMap from './components/RideMap';
import { NewRideContext, RideMapType } from './context/RideContext';
import useNewRideInformation from './hooks/useNewRideInformation';
import usePassengerInformation from './hooks/usePassengerInformation';
import useReferences from './hooks/useReferences';

const useStyles = makeStyles(styleTheme => ({
    mainWrapper: {
        alignItems: 'center',
        padding: '0 2rem',
        [styleTheme.breakpoints.down('md')]: { padding: '0' },
    },
    header: {
        backgroundColor: COLORS.WHITE,
        display: 'flex',
        flexDirection: 'column',
    },
    headerTitleWrapper: {
        marginBottom: '2rem',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        [styleTheme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            marginTop: '2rem',
        },
    },
    headerTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '.5rem !important',
        fontSize: '1.2rem',
        [styleTheme.breakpoints.up('md')]: { marginBottom: 0 },
    },
    headerText: {
        fontSize: '1.5rem',
        fontWeight: 700,
        paddingRight: '1rem',
        color: COLORS.BLUE,
        [styleTheme.breakpoints.down('md')]: { fontSize: '1.2rem' },
    },
    updateButton: { marginRight: '1rem' },
    buttons: {
        width: '150px',
        [styleTheme.breakpoints.down('md')]: {
            width: 'auto',
            fontSize: '13px',
            height: '40px',
            marginTop: '1rem',
        },
    },
    downloadOptions: {
        display: 'flex',
        flexDirection: 'row',
        [styleTheme.breakpoints.down('md')]: { flexDirection: 'column' },
    },
}));

const REFETCH_VEHICLE_LIST_TIMEOUT_MS = 400;
let refetchVehicleListTimeoutId: NodeJS.Timeout | undefined;

const getLeadPassenger = (passengers: Passenger[]) => passengers.filter(passenger => passenger.isLead)[0];

export const CUSTOM_FIELD_KEY = 'customField';

const UpdateRide = () => {
    const [isTypingFlightNumberFinished, setIsTypingFlightNumberFinished] = useState(false);
    const [isVehicleNotSelected, setIsVehicleNotSelected] = useState(false);
    const [isCheckingAvailabileVehicleList, setIsCheckingAvailabileVehicleList] = useState(false);
    const [isUpdateRideModalOpen, setIsUpdateRideModalOpen] = useState(false);
    const [isSendBookingConfirmationModalOpen, setIsSendBookingConfirmationModalOpen] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const { rideId }: { rideId: string } = useParams();
    const { showNotification } = useNotificationContext();
    const { data, isLoading } = useRide(rideId);
    const initialRideValues = useRef<RideType | null>(null);
    const initialVehicleValues = useRef<Vehicle | null>(null);
    const initialTravellerValues = useRef<Traveller[] | null>(null);
    const initialReferenceValues = useRef<ReferenceField | null>(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { currentProfile: currentUser } = useCurrentProfileContext();
    const [isUtc, setIsUtc] = useState(currentUser.primaryTimeZone === PrimaryTimeZone.UTC);
    const whatIsChanged = useRef<Map<string, boolean | typeof CUSTOM_FIELD_KEY>>();
    const isPageOpened = useRef(false);
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);
    const [isLeavingBack, setIsLeavingBack] = useState(false);
    const history = useHistory();

    const { rideValues, setRideValues, rideErrors, setRideErrors, flightDetails, setFlightDetails, validateRideFields } =
        useNewRideInformation(isUtc);

    const { referencesValues, setReferencesValues, referencesErrors, setReferencesErrors, validateReferencesFields } =
        useReferences();

    const { passengerValues, setPassengerValues, passengerErrors, setPassengerErrors, validatePassengerFields } =
        usePassengerInformation();

    const { mutate: downloadBookingConfirmationMutation, isLoading: isDownloadingBookingConfirmation } =
        useMutation(downloadBookingConfirmation);

    const { mutate: downloadReceiptMutation, isLoading: isDownloadingReceipt } = useMutation(downloadReceipt);

    const { mutate: updateRideInformationMutation, isLoading: isUpdatingRideInformation } = useMutation(updateRideInformation, {
        onSuccess: () => {
            showNotification(t('alert.edit.success'), Severity.Info);
            goToRides();
        },
        onError: (error: Error) => {
            showNotification(t('alert.edit.error'), Severity.Error);
        },
    });

    const { mutate: updateTravellerInformationMutation, isLoading: isUpdatingTravellers } = useMutation(
        updateTravellerInformation,
        {
            onSuccess: () => {
                showNotification(t('alert.edit.success'), Severity.Info);
                goToRides();
            },
            onError: (error: Error) => {
                showNotification(t('alert.edit.error'), Severity.Error);
            },
        }
    );

    const { mutate: updateFlightNumberMutation, isLoading: isUpdatingFlightNumber } = useMutation(updateFlightNumber, {
        onSuccess: () => {
            showNotification(t('alert.edit.success'), Severity.Info);
            goToRides();
        },
        onError: (error: Error) => {
            showNotification(t('alert.edit.error'), Severity.Error);
        },
    });

    const goToRides = () => history.push(getSingleRideRoute(rideId));

    const checkVehicleAfterRefetchAvailabilites = () => {
        if (selectedVehicle && isCheckingAvailabileVehicleList) {
            const { id, carId, ...restSelectedVehicle } = selectedVehicle;

            const vehicleExists = vehicleList.some(obj => {
                const { id: vehicleItemId, ...restObj } = obj;

                if (JSON.stringify(restSelectedVehicle) === JSON.stringify(restObj)) {
                    selectedVehicle.carId = vehicleItemId;
                    return true;
                }

                return false;
            });

            if (vehicleExists) {
                setSelectedVehicle(selectedVehicle);

                setIsCheckingAvailabileVehicleList(false);
            } else if (initialVehicleValues.current === selectedVehicle) {
                setSelectedVehicle(selectedVehicle);
                setIsVehicleNotSelected(false);
                setIsCheckingAvailabileVehicleList(false);
            } else {
                setSelectedVehicle(null);
                setIsVehicleNotSelected(!isVehicleNotSelected);

                setIsCheckingAvailabileVehicleList(false);
            }
        }
    };

    const {
        vehicleList,
        newEstimateDistanceAndDuration,
        refetch: refetchAvailabileVehicleList,
        isLoading: isLoadingAvailabilites,
        isRefetching: isRefetchingAvailabilites,
        isFetched: areAvailabilitesFetched,
    } = useAvailabilites({
        pickup: {
            transferPoint: {
                id: rideValues.pickUpLocation?.id ?? '',
                type: rideValues.pickUpLocation?.type.toUpperCase() ?? '',
            },
            pickupTimeZulu: isUtc ? dayjs(rideValues.pickUpTime).format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']) : '',
            pickupTime: isUtc ? '' : dayjs(rideValues.pickUpTimeLocal).format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']),
        },
        dropOff: {
            transferPoint: {
                id: rideValues.dropOffLocation?.id ?? '',
                type: rideValues.dropOffLocation?.type.toUpperCase() ?? '',
            },
        },
        numberOfPassengers: parseInt(rideValues.travellers),
        numberOfBags: parseInt(rideValues.luggages),
    });

    useEffect(() => {
        if (areAvailabilitesFetched && isCheckingAvailabileVehicleList && !isRefetchingAvailabilites) {
            checkVehicleAfterRefetchAvailabilites();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheckingAvailabileVehicleList, areAvailabilitesFetched, isRefetchingAvailabilites]);

    const isPickupDateDirty = () => {
        if (!initialRideValues.current) {
            return false;
        }

        if (isUtc) {
            return (
                initialRideValues.current.pickUpDate !== rideValues.pickUpDate ||
                initialRideValues.current.pickUpTime !== rideValues.pickUpTime
            );
        }

        return (
            initialRideValues.current.pickUpDateLocal !== rideValues.pickUpDateLocal ||
            initialRideValues.current.pickUpTimeLocal !== rideValues.pickUpTimeLocal
        );
    };

    const isLocationDirty = () => {
        if (!initialRideValues.current) {
            return false;
        }

        return (
            initialRideValues.current.pickUpLocation?.name !== rideValues.pickUpLocation?.name ||
            initialRideValues.current.dropOffLocation?.name !== rideValues.dropOffLocation?.name
        );
    };

    // The logic for this calculation is described in the ticket TP-1336
    const searchFlightDate = useMemo(
        () => {
            let timeZone = '';
            let flightDate: Date | null = null;

            const rideDuration = newEstimateDistanceAndDuration?.durationInSeconds ?? 0;

            if (rideValues.pickUpLocation?.type === LocationType.AIRPORT) {
                timeZone = rideValues.pickUpLocation?.timeZone ?? '';
                flightDate = isUtc ? rideValues.pickUpDate : rideValues.pickUpDateLocal;
            } else if (rideValues.dropOffLocation?.type === LocationType.AIRPORT) {
                timeZone = rideValues.dropOffLocation?.timeZone ?? '';

                if (isPickupDateDirty()) {
                    flightDate = isUtc ? rideValues.pickUpDate : rideValues.pickUpDateLocal;
                    flightDate = dayjs(flightDate).add(rideDuration, 'seconds').toDate();
                } else {
                    flightDate = isUtc ? rideValues.dropOffDate : rideValues.dropOffDateLocal;
                }
            }

            if (isUtc && timeZone && flightDate) {
                return dayjs(zonedTimeToUtc(flightDate, timeZone)).format(DATE_FORMATS['YYYY-MM-DD']);
            }

            return dayjs(flightDate).format(DATE_FORMATS['YYYY-MM-DD']);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            isUtc,
            rideValues.pickUpDate,
            rideValues.pickUpDateLocal,
            rideValues.dropOffDate,
            rideValues.dropOffDateLocal,
            rideValues.pickUpLocation?.timeZone,
            rideValues.pickUpLocation?.type,
            rideValues.dropOffLocation?.timeZone,
            rideValues.dropOffLocation?.type,
            newEstimateDistanceAndDuration?.durationInSeconds,
        ]
    );

    const {
        flightDetails: flightSearchDetails,
        refetch: refetchFlightDetails,
        isLoading: isLoadingFlight,
        isError: isErrorFlight,
    } = useFlightSearch(rideValues.flightNumber, searchFlightDate);

    const { mutate: updateVehicleMutation, isLoading: isUpdatingVehicle } = useMutation(updateRideVehicle, {
        onSuccess: () => {
            showNotification(t('alert.edit.successVehicleChange'), Severity.Info);
            goToRides();
        },
        onError: (error: Error) => {
            showNotification(t('alert.edit.error'), Severity.Error);
        },
    });

    const rideAmplitudeEventProperties = useMemo(() => {
        return {
            'Ride number': data?.prettifiedUnid,
            'Ride created time': dayjs.utc(data?.createdAt).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
            'Ride pickup time': dayjs.utc(data?.pickUp.departAt).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
            'Ride customer': data?.customer,
            'Ride supplier': data?.supplier,
            'Ride status': data?.status,
            'Ride situation tags': data?.situationTag,
            'Ride qc status': data?.qcStatus,
            'Ride has driver': Boolean(data?.driver),
        } as RideAmplitudeEventProperties;
    }, [
        data?.createdAt,
        data?.customer,
        data?.driver,
        data?.pickUp.departAt,
        data?.prettifiedUnid,
        data?.qcStatus,
        data?.situationTag,
        data?.status,
        data?.supplier,
    ]);

    useEffect(() => {
        if (!data || isLoading) {
            return;
        }

        const leadPassenger = getLeadPassenger(data.passengers);
        const passengerFullName = leadPassenger ? `${leadPassenger.firstName} ${leadPassenger.lastName}` : '';

        const tagManagerArgs = {
            dataLayer: {
                name: currentUser?.fullName ?? null,
                email: currentUser?.email ?? null,
                accountType: currentUser?.accountType,
                rideNumber: data.unid,
                passengerName: passengerFullName,
            },
        };

        TagManager.dataLayer(tagManagerArgs);

        const newRideValues = {
            ...rideValues,
            flightNumber: data?.flightDetails?.number ?? '',
            flightDetails: data?.flightDetails ?? ({} as FlightDetails),
            id: data?.unid ?? '',
            pickUpDate: getDateTimeWithoutOffset(data?.pickUp?.departAt),
            pickUpTime: getDateTimeWithoutOffset(data?.pickUp?.departAt),
            pickUpDateLocal: getDateTimeWithoutOffset(data?.pickUp?.departAtLocal),
            pickUpTimeLocal: getDateTimeWithoutOffset(data?.pickUp?.departAtLocal),
            dropOffDate: getDateTimeWithoutOffset(data?.dropOff?.arriveAt),
            dropOffDateLocal: getDateTimeWithoutOffset(data?.dropOff?.arriveAtLocal),
            travellers: data?.numberOfPassengers?.toString() ?? '',
            luggages: data?.numberOfBags?.toString() ?? '',
            specialRequest: data?.specialRequest ?? '',
            pickUpLocation: {
                id: data?.pickUp?.location?.id ?? '',
                name: data?.pickUp?.location?.name ?? '',
                address: data?.pickUp?.location?.address ?? '',
                type: data?.pickUp?.location?.type ?? '',
                latitude: data?.pickUp?.location?.coordinates?.lat ?? 0,
                longitude: data?.pickUp?.location?.coordinates?.lon ?? 0,
                timeZone: data?.pickUp?.departureTimeZone ?? '',
            },
            dropOffLocation: {
                id: data?.dropOff?.location?.id ?? '',
                name: data?.dropOff?.location?.name ?? '',
                address: data?.dropOff?.location?.address ?? '',
                type: data?.dropOff?.location?.type ?? '',
                latitude: data?.dropOff?.location?.coordinates?.lat ?? 0,
                longitude: data?.dropOff?.location?.coordinates?.lon ?? 0,
                timeZone: data?.dropOff?.arrivalTimeZone ?? '',
            },
            primaryTimeZone: currentUser?.primaryTimeZone ?? null,
            status: data?.status,
        } as unknown as RideType;

        setRideValues(newRideValues);
        setFlightDetails(data?.flightDetails ?? ({} as FlightDetails));

        const newReferencesValues = {
            customReference: data?.customerReference ?? '',
            customFields: data?.customFields ?? [],
        };

        setReferencesValues(newReferencesValues);

        const defaultTravellers: Traveller[] = data
            ? data.passengers.map(({ id, ...rest }) => ({
                  id: id.toString(),
                  ...rest,
              }))
            : [];

        setPassengerValues(defaultTravellers);

        const newVehicleValues = {
            id: data?.unid ?? '',
            vehicleClass: data?.vehicle.class,
            vehicleType: data?.vehicle?.type ?? '',
            vehicleImage: data?.vehicle?.imageUrl ?? '',
            travellers: data?.vehicle?.seats?.toString() ?? '',
            luggages: data?.vehicle?.luggage?.toString() ?? '',
            price: data?.price?.amount ?? 0,
            priceCurrency: data?.price?.isoCurrency ?? '',
        };

        setSelectedVehicle(newVehicleValues);

        const updatedErrorsValues = defaultTravellers.map(traveller => ({
            ...traveller,
            id: traveller.id,
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
        }));

        setPassengerErrors(updatedErrorsValues);

        initialRideValues.current = newRideValues;
        initialVehicleValues.current = newVehicleValues;
        initialTravellerValues.current = defaultTravellers;
        initialReferenceValues.current = newReferencesValues;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading]);

    useEffect(() => {
        // Do not call flight search if pickup of dropoff location fields are dirty
        if (!isTypingFlightNumberFinished || isLocationDirty()) {
            return;
        }

        refetchFlightDetails();
        setIsTypingFlightNumberFinished(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTypingFlightNumberFinished]);

    useEffect(() => {
        setFlightDetails(isErrorFlight ? ({} as FlightDetails) : (flightSearchDetails ?? ({} as FlightDetails)));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flightSearchDetails, isErrorFlight]);

    useEffect(() => {
        const isRideSetted = Boolean(rideValues.pickUpLocation?.id) && Boolean(rideValues.dropOffLocation?.id);

        if (isRideSetted) {
            clearTimeout(refetchVehicleListTimeoutId);

            refetchVehicleListTimeoutId = setTimeout(() => {
                refetchAvailabileVehicleList();
                setIsCheckingAvailabileVehicleList(true);
            }, REFETCH_VEHICLE_LIST_TIMEOUT_MS);
        }

        return () => clearTimeout(refetchVehicleListTimeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rideValues]);

    useEffect(() => {
        if (
            rideValues.travellers !== data?.numberOfPassengers?.toString() ||
            rideValues.luggages !== data?.numberOfBags?.toString()
        ) {
            if (
                parseInt(rideValues.travellers) > parseInt(selectedVehicle?.travellers ?? '-1') ||
                parseInt(rideValues.luggages) > parseInt(selectedVehicle?.luggages ?? '-1')
            ) {
                clearTimeout(refetchVehicleListTimeoutId);
                refetchVehicleListTimeoutId = setTimeout(() => {
                    refetchAvailabileVehicleList();
                    setIsCheckingAvailabileVehicleList(true);
                }, REFETCH_VEHICLE_LIST_TIMEOUT_MS);
            }
        }

        return () => clearTimeout(refetchVehicleListTimeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rideValues.travellers, rideValues.luggages]);

    const areRideInformationValuesChanged = () => {
        const defaultValues = {
            travellers: data?.numberOfPassengers?.toString() ?? '',
            luggages: data?.numberOfBags?.toString() ?? '',
            specialRequest: data?.specialRequest ?? '',
            customReference: data?.customerReference ?? '',
            customFields: data?.customFields ?? '',
        };

        const currentValues = {
            travellers: rideValues.travellers,
            luggages: rideValues.luggages,
            specialRequest: rideValues.specialRequest,
            customReference: referencesValues.customReference,
            customFields: referencesValues.customFields,
        };

        return JSON.stringify(defaultValues) !== JSON.stringify(currentValues);
    };

    const areTravellerInformationValuesChanged = () => {
        const defaultPassengers = data?.passengers.map(({ id, ...keepAttrs }) => keepAttrs);
        const changedTravellerValues = passengerValues.map(({ id, ...keepAttrs }) => keepAttrs);

        return JSON.stringify(defaultPassengers) !== JSON.stringify(changedTravellerValues);
    };

    function handleUpdateRideInformation() {
        if (areRideInformationValuesChanged()) {
            const customFieldsPairs: CustomFieldItem[] = [];

            referencesValues.customFields.forEach(customField => customFieldsPairs.push([customField.key, customField.value]));
            const customFields = customFieldsPairs.length > 0 ? Object.fromEntries(new Map(customFieldsPairs)) : null;

            updateRideInformationMutation({
                id: rideId,
                payload: {
                    numberOfPassengers: parseInt(rideValues.travellers),
                    numberOfBags: parseInt(rideValues.luggages),
                    customerReference: referencesValues.customReference,
                    specialRequest: rideValues.specialRequest,
                    customFields,
                },
            });
        }
    }

    function handleUpdateTravellerInformation() {
        if (areTravellerInformationValuesChanged()) {
            updateTravellerInformationMutation({
                id: rideId,
                payload: passengerValues.map(({ id, ...keepAttrs }) => keepAttrs),
            });
        }
    }

    function handleUpdateFlightNumber() {
        const defaultFlightNumber = data?.flightDetails?.number ?? '';
        const defaultPickUpDate = getDateTimeWithoutOffset(data?.pickUp?.departAtLocal ?? '');

        if (defaultFlightNumber !== rideValues.flightNumber && defaultPickUpDate !== rideValues.pickUpDate) {
            updateFlightNumberMutation({
                id: rideId,
                payload: {
                    flightNumber: rideValues.flightNumber,
                    departureDate: dayjs(isUtc ? rideValues.pickUpDate : rideValues.pickUpDateLocal).format(
                        DATE_FORMATS['YYYY-MM-DD']
                    ),
                },
            });
        }
    }

    const onUpdateHandler = () => {
        // If user click on other vehicle (change default selected vehicle), then send carId, in opposite send id from vehicleListe
        const carId = selectedVehicle?.id === rideValues?.id ? (selectedVehicle?.carId ?? '') : (selectedVehicle?.id ?? '');

        // Update vehicle - swap estimate options
        if (selectedVehicle?.id) {
            updateVehicleMutation({
                rideId: rideValues.id,
                carId,
            });
        } else if (selectedVehicle === null) {
            setIsVehicleNotSelected(!isVehicleNotSelected);
            setIsUpdateRideModalOpen(false);
            return;
        }

        handleUpdateTravellerInformation();

        handleUpdateFlightNumber();
        handleUpdateRideInformation();

        setIsUpdateRideModalOpen(false);
        setIsCheckingAvailabileVehicleList(false);

        logAmplitudeEvent(CONFIRM_UPDATE, rideAmplitudeEventProperties);
    };

    const validateFields = () => validatePassengerFields() && validateRideFields() && validateReferencesFields();

    const handleRideUpdate = () => {
        if (validateFields()) {
            setIsUpdateRideModalOpen(true);

            logAmplitudeEvent(UPDATE_RIDE, rideAmplitudeEventProperties);
        }
    };

    const handleFlightNumberFinishing = () => {
        setIsTypingFlightNumberFinished(true);

        if (initialRideValues?.current?.flightNumber !== rideValues.flightNumber) {
            logAmplitudeEvent(RIDE_INFORMATION_FLIGHT_TRACKING, rideAmplitudeEventProperties);
        }
    };

    const initialBookingConfirmationEmails: BookingConfirmationEmail[] = passengerValues.map(traveller => ({
        id: traveller.email,
        email: traveller.email,
        isIncluded: traveller.isLead,
    }));

    const isTravellerUpdated = () => {
        const initialTravellerKeys = initialTravellerValues.current?.map(
            traveller => traveller.firstName + traveller.lastName + traveller.email + traveller.phone + traveller.isLead
        );

        const currentTravellerKeys = passengerValues.map(
            traveller => traveller.firstName + traveller.lastName + traveller.email + traveller.phone + traveller.isLead
        );

        return !_.isEqual(initialTravellerKeys, currentTravellerKeys);
    };

    const checkWhatIsChanging = () => {
        if (!initialRideValues.current) {
            return;
        }

        const changedCustomFields: string[] = [];

        initialReferenceValues?.current?.customFields.forEach(customField => {
            const currentCustomField = referencesValues.customFields.filter(field => field.id === customField.id)[0];

            if (JSON.stringify(customField) !== JSON.stringify(currentCustomField)) {
                changedCustomFields.push(customField.label);
            }
        });

        const changes = new Map<string, boolean | typeof CUSTOM_FIELD_KEY>();

        changes.set('pickUpLocation', initialRideValues.current.pickUpLocation?.name !== rideValues.pickUpLocation?.name);
        changes.set('dropOffLocation', initialRideValues.current.dropOffLocation?.name !== rideValues.dropOffLocation?.name);
        changes.set('pickUpDate', initialRideValues.current.pickUpDate !== rideValues.pickUpDate);
        changes.set('pickUpTime', initialRideValues.current.pickUpTime !== rideValues.pickUpTime);
        changes.set('pickUpDateLocal', initialRideValues.current.pickUpDateLocal !== rideValues.pickUpDateLocal);
        changes.set('pickUpTimeLocal', initialRideValues.current.pickUpTimeLocal !== rideValues.pickUpTimeLocal);
        changes.set('travellers', initialRideValues.current.travellers !== rideValues.travellers);
        changes.set('luggages', initialRideValues.current.luggages !== rideValues.luggages);
        changes.set('flightNumber', initialRideValues.current.flightNumber !== rideValues.flightNumber);
        changes.set('specialRequest', initialRideValues.current.specialRequest !== rideValues.specialRequest);
        changes.set('customReference', initialReferenceValues?.current?.customReference !== referencesValues.customReference);
        changes.set('vehicle', initialVehicleValues.current?.id !== selectedVehicle?.id);
        changes.set('passengerInformation', isTravellerUpdated());
        changedCustomFields.forEach(changedCustomField => {
            changes.set(changedCustomField, CUSTOM_FIELD_KEY);
        });
        whatIsChanged.current = changes;
    };

    const isUpdatedDisabled = useMemo(() => {
        if (!initialRideValues.current || !vehicleList || !selectedVehicle || !initialReferenceValues.current) {
            return true;
        }

        if (
            initialRideValues.current.pickUpLocation?.name !== rideValues.pickUpLocation?.name ||
            initialRideValues.current.dropOffLocation?.name !== rideValues.dropOffLocation?.name ||
            initialRideValues.current.pickUpDate !== rideValues.pickUpDate ||
            initialRideValues.current.pickUpTime !== rideValues.pickUpTime ||
            initialRideValues.current.pickUpDateLocal !== rideValues.pickUpDateLocal ||
            initialRideValues.current.pickUpTimeLocal !== rideValues.pickUpTimeLocal ||
            initialRideValues.current.travellers !== rideValues.travellers ||
            initialRideValues.current.luggages !== rideValues.luggages ||
            initialRideValues.current.flightNumber !== rideValues.flightNumber ||
            initialRideValues.current.specialRequest !== rideValues.specialRequest ||
            initialReferenceValues.current.customReference !== referencesValues.customReference ||
            JSON.stringify(initialReferenceValues.current.customFields) !== JSON.stringify(referencesValues.customFields) ||
            initialVehicleValues.current?.id !== selectedVehicle?.id ||
            isTravellerUpdated()
        ) {
            checkWhatIsChanging();
            return false;
        }

        return true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rideValues, selectedVehicle, passengerValues, referencesValues]);

    if (!data || isLoading) {
        return <RideLoadingSkeleton />;
    }

    if (!isPageOpened.current) {
        isPageOpened.current = true;
        logAmplitudeEvent(EDIT_SINGLE_RIDE, rideAmplitudeEventProperties);
    }

    return (
        <>
            <NewRideContext.Provider
                value={{
                    summary: data ? mapRideToContext(data, rideValues) : ({} as RideMapType),
                    amplitudeEventProperties: rideAmplitudeEventProperties,
                }}
            >
                <Grid container className={classes.mainWrapper}>
                    <Grid item xs className={classes.header}>
                        <Grid item xs className={classes.headerTitleWrapper}>
                            <Grid item display="flex" flexDirection={'column'}>
                                <Grid item className={classes.headerTitle}>
                                    <Typography className={classes.headerText}>
                                        {t('ride')} {data?.prettifiedUnid}
                                    </Typography>
                                    <StatusBadge
                                        status={data?.status}
                                        trackingStatus={data?.trackingStatus}
                                        pickupDateTimeUtc={data?.pickUp.departAt}
                                    />
                                </Grid>
                                <Grid gap={isMobile ? 0 : 2} className={classes.downloadOptions}>
                                    <IconedData
                                        icon={EmailOutlinedIcon}
                                        data={t('pages.rides.sendBookingConfirmation')}
                                        bottomMargin="0.5rem"
                                        iconColor={COLORS.BLUE}
                                        dataColor={COLORS.BLUE}
                                        onClick={() => {
                                            setIsSendBookingConfirmationModalOpen(true);

                                            logAmplitudeEvent(SEND_CONFIRMATION, rideAmplitudeEventProperties);
                                        }}
                                        fontSize=".75rem"
                                        iconSize="1.25rem"
                                        iconRightMargin=".2rem"
                                    />
                                    <IconedData
                                        icon={DownloadOutlinedIcon}
                                        data={t('pages.rides.downloadBookingConfirmation')}
                                        iconColor={COLORS.BLUE}
                                        dataColor={COLORS.BLUE}
                                        onClick={() => {
                                            downloadBookingConfirmationMutation({
                                                unid: data.unid,
                                                prettifiedUnid: data.prettifiedUnid,
                                            });

                                            logAmplitudeEvent(DONWLOAD_DOCUMENTATION, rideAmplitudeEventProperties);
                                        }}
                                        fontSize=".75rem"
                                        iconSize="1.25rem"
                                        iconRightMargin=".2rem"
                                    />
                                    {data?.paymentMethod === PAYMENT_METHOD.CREDIT_CARD &&
                                        (data?.status === getRideStatusKey(RideStatus.CONFIRMED) ||
                                            data?.status === getRideStatusKey(RideStatus.COMPLETED)) && (
                                            <IconedData
                                                icon={ReceiptLongOutlinedIcon}
                                                data={t('pages.rides.downloadReceipt')}
                                                iconColor={COLORS.BLUE}
                                                dataColor={COLORS.BLUE}
                                                onClick={() => {
                                                    downloadReceiptMutation({
                                                        unid: data.unid,
                                                        prettifiedUnid: data.prettifiedUnid,
                                                    });
                                                }}
                                                fontSize=".75rem"
                                                iconSize="1.25rem"
                                                iconRightMargin=".2rem"
                                            />
                                        )}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <PageActionButton
                                    onClick={handleRideUpdate}
                                    className={clsx(classes.updateButton)}
                                    isLoading={
                                        isLoading ||
                                        isUpdatingRideInformation ||
                                        isUpdatingVehicle ||
                                        isUpdatingTravellers ||
                                        isUpdatingFlightNumber ||
                                        isLoadingAvailabilites ||
                                        isRefetchingAvailabilites
                                    }
                                    isDisabled={false}
                                >
                                    {t('pages.singleRide.update')}
                                </PageActionButton>
                                <SecondaryButton
                                    onClick={() => {
                                        isUpdatedDisabled ? goToRides() : setIsLeavingBack(true);
                                    }}
                                    className={classes.buttons}
                                >
                                    Back
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" columnSpacing={4}>
                        <Grid item xs={12} md={12} lg={7.5} order={isMobile ? 2 : 1}>
                            <Grid marginBottom="2.5rem">
                                <ChangeRideInformation
                                    rideValues={rideValues}
                                    setRideValues={setRideValues}
                                    flightDetails={flightDetails}
                                    setFlightDetails={setFlightDetails}
                                    errors={rideErrors}
                                    setErrors={setRideErrors}
                                    handleFlightNumberFinishing={handleFlightNumberFinishing}
                                    isLoadingFlight={isLoadingFlight}
                                    isErrorFlight={isErrorFlight}
                                    isUtc={isUtc}
                                    setIsUtc={setIsUtc}
                                    initialRideValues={initialRideValues.current}
                                />
                                {isMobile && (
                                    <VehicleOptions
                                        selectedVehicle={selectedVehicle}
                                        setSelectedVehicle={setSelectedVehicle}
                                        vehicleList={vehicleList}
                                        isError={isVehicleNotSelected}
                                        setIsError={() => setIsVehicleNotSelected(false)}
                                        isLoading={isLoadingAvailabilites || isRefetchingAvailabilites}
                                        maxPassengers={parseInt(rideValues.travellers)}
                                    />
                                )}
                                <PassengerList
                                    passengers={passengerValues}
                                    setValues={setPassengerValues}
                                    errors={passengerErrors}
                                    setErrors={setPassengerErrors}
                                />
                                <ChangeReferences
                                    referenceValues={referencesValues}
                                    setReferenceValues={setReferencesValues}
                                    errors={referencesErrors}
                                    setErrors={setReferencesErrors}
                                    initialReferenceValues={initialReferenceValues.current}
                                />
                            </Grid>
                        </Grid>
                        {data && (
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={4.5}
                                order={isMobile ? 1 : 2}
                                marginBottom={isMobile ? '2rem' : 0}
                                display="flex"
                                flexDirection="column"
                            >
                                <RideMap />
                                {!isMobile && (
                                    <VehicleOptions
                                        selectedVehicle={selectedVehicle}
                                        setSelectedVehicle={setSelectedVehicle}
                                        vehicleList={vehicleList}
                                        isError={isVehicleNotSelected}
                                        setIsError={() => setIsVehicleNotSelected(false)}
                                        isLoading={isLoadingAvailabilites || isRefetchingAvailabilites}
                                        maxPassengers={parseInt(rideValues.travellers)}
                                    />
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </NewRideContext.Provider>
            {isUpdateRideModalOpen && (
                <UpdateRideConfirmModal
                    onClose={() => setIsUpdateRideModalOpen(false)}
                    onConfirm={onUpdateHandler}
                    changes={whatIsChanged.current}
                    isLoading={isUpdatingRideInformation || isUpdatingVehicle || isUpdatingTravellers || isUpdatingFlightNumber}
                />
            )}
            {isSendBookingConfirmationModalOpen && (
                <SendBookingConfirmationModal
                    initialBookingConfirmationEmails={initialBookingConfirmationEmails}
                    isOpen={isSendBookingConfirmationModalOpen}
                    onClose={() => setIsSendBookingConfirmationModalOpen(false)}
                    rideId={rideId}
                />
            )}
            {isLeavingBack && <LeavePageModal onClose={() => setIsLeavingBack(false)} onConfirm={goToRides} />}
        </>
    );
};

export default UpdateRide;
