import { LatLng, Place } from '../../components/navigation/GoogleMap';

type DirectionsResult = google.maps.DirectionsResult;

const cache = new Map<string, Promise<DirectionsResult>>();

function createCacheKey(route: Place[]): string {
    return route.reduce(
        (result, place) =>
            place.location ? `${result};${(place.location as LatLng).toUrlValue(6)}` : `${result};${place.placeId as string}`,
        ''
    );
}

let cachedDirectionsService: google.maps.DirectionsService | null = null;

export default function fetchDirections(route: Place[]): Promise<DirectionsResult> {
    const cacheKey = createCacheKey(route);
    const cachedPromise = cache.get(cacheKey);

    if (cachedPromise) {
        return cachedPromise;
    }

    const directionsService = (() => {
        if (cachedDirectionsService === null) {
            cachedDirectionsService = new google.maps.DirectionsService();
        }

        return cachedDirectionsService;
    })();

    const origin = route[0];
    const destination = route[route.length - 1];
    const stopovers = route.slice(1, route.length - 1);

    const promise = new Promise<DirectionsResult>((resolve, reject) => {
        directionsService.route(
            {
                origin,
                destination,
                waypoints: stopovers.map(stopover => ({
                    location: stopover,
                    stopover: true,
                })),
                travelMode: google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
                if (status !== google.maps.DirectionsStatus.OK) {
                    const originError = origin.location
                        ? ` ${(origin.location as LatLng).toUrlValue(6)}`
                        : `${origin.placeId as string}`;

                    const destinationError = destination.location
                        ? ` ${(destination.location as LatLng).toUrlValue(6)}`
                        : `${destination.placeId as string}`;

                    reject(new Error(`Cannot find directions from ${originError} to ${destinationError} (${status})`));

                    return;
                }

                resolve(response);
            }
        );
    });

    cache.set(cacheKey, promise);

    return promise;
}
