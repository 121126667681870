/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Select, SelectOption, TextField, ModalAction as Modal } from '@get-e/react-components';
import { Box, Checkbox, FormControlLabel, Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import { BaseError } from 'make-error-cause';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DUMMY_DATE, REPEATS, WEEKDAYS } from '../../../constants';
import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { datePickerPlaceholder } from '../../../constants/datePickerPlaceholder';
import { TIME_FORMATS } from '../../../constants/timeFormats';
import { useCurrentProfileContext } from '../../../context/CurrentProfileContext';
import FormError from '../../../helpers/inputValidation/FormError';
import getFormErrorMessage from '../../../helpers/inputValidation/getFormErrorMessage';
import { InputError } from '../../../helpers/inputValidation/InputError';
import { Validated } from '../../../helpers/inputValidation/Validator';
import allValid from '../../../helpers/inputValidation/validators/allValid';
import and from '../../../helpers/inputValidation/validators/and';
import isAfterDate from '../../../helpers/inputValidation/validators/isAfterDate';
import isAfterTime from '../../../helpers/inputValidation/validators/isAfterTime';
import isFilledString from '../../../helpers/inputValidation/validators/isFilledString';
import isNotNull from '../../../helpers/inputValidation/validators/isNotNull';
import isPastDate from '../../../helpers/inputValidation/validators/isPastDate';
import isValidDateFormat from '../../../helpers/inputValidation/validators/isValidDateFormat';
import isValidTimeFormat from '../../../helpers/inputValidation/validators/isValidTimeFormat';
import { resolveApplicableOnDaysValue, resolveRepeatsInitialValue } from '../../../helpers/repeats';
import theme from '../../../styles/theme';
import { createStopSalesRule, editStopSalesRule, useStopSalesRule } from '../api';
import { BlockedPeriodRequest } from '../api/types';

const useStyles = makeStyles(styleTheme => ({
    formField: {
        marginBottom: '1.25rem',
        width: '100%',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    formFieldMobile: { marginBottom: '1.25rem' },
    dateFieldStart: {
        width: '50%',
        marginRight: '1rem',
    },
    dateFieldEnd: {
        width: '50%',
        marginLeft: '1rem',
    },
    checkbox: { height: '2rem' },
    cancelButton: {
        width: '150px',
        marginLeft: '1rem',
        color: COLORS.BLUE,
        border: `2px solid ${COLORS.BLUE}`,
        outline: '2px',
        '&:hover': { border: `2px solid ${COLORS.BLUE}` },
    },
    timeSepartor: { '& .MuiDateTimePickerToolbar-separator': { marginTop: '.7rem' } },
}));

interface CreateAvailabilityModalProps {
    blockedPeriodId: string;
    isModalOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

interface ValidatedBlockedPeriod {
    startDateTime: Validated<Date, InputError>;
    endDateTime: Validated<Date, InputError>;
    description: Validated<string, InputError>;
    startTime?: Validated<Date, InputError>;
    endTime?: Validated<Date, InputError>;
}

const CreateAvailabilityModal = ({ blockedPeriodId, isModalOpen, onClose, onSubmit }: CreateAvailabilityModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [isSaving, setIsSaving] = useState(false);
    const { currentProfile: currentUser } = useCurrentProfileContext();
    const autoFocusRef = useRef<HTMLInputElement>();

    const [startDateTime, setStartDateTime] = useState<Date | null>(null);

    const [startDateTimeError, setStartDateTimeError] = useState<InputError | null>(null);

    const [endDateTime, setEndDateTime] = useState<Date | null>(null);

    const [endDateTimeError, setEndDateTimeError] = useState<InputError | null>(null);

    const [startTime, setStartTime] = useState<Date | null>(null);
    const [startTimeError, setStartTimeError] = useState<InputError | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);
    const [endTimeError, setEndTimeError] = useState<InputError | null>(null);
    const [description, setDescription] = useState('');

    const [descriptionError, setDescriptionError] = useState<InputError | null>(null);

    const [repeats, setRepeats] = useState(REPEATS.DO_NOT_REPEAT);

    const [applicableOnDays, setApplicableOnDays] = useState<Array<keyof typeof WEEKDAYS>>([]);

    const [formError, setFormError] = useState<BaseError | FormError | null>(null);

    const [openStartDate, setOpenStartDate] = useState(false);
    const [openEndDate, setOpenEndDate] = useState(false);
    const [openStartTime, setOpenStartTime] = useState(false);
    const [openEndTime, setOpenEndTime] = useState(false);
    const [openStartDateTime, setOpenStartDateTime] = useState(false);
    const [openEndDateTime, setOpenEndDateTime] = useState(false);

    const { data: blockedPeriod, isFetching: isfetchingBlockedPeriod } = useStopSalesRule(
        currentUser.accountId?.toString() ?? '',
        blockedPeriodId,
        Boolean(blockedPeriodId)
    );

    useEffect(() => {
        if (blockedPeriodId && blockedPeriod) {
            const startDateTimeInitial = blockedPeriod.start ? dayjs(blockedPeriod.start).toDate() : null;

            const endDateTimeInitial = blockedPeriod.end ? dayjs(blockedPeriod.end).toDate() : null;

            const startTimeInitial = blockedPeriod.applicableStartTime
                ? dayjs(`${DUMMY_DATE} ${blockedPeriod.applicableStartTime}`).toDate()
                : null;

            const endTimeInitial = blockedPeriod.applicableEndTime
                ? dayjs(`${DUMMY_DATE} ${blockedPeriod.applicableEndTime}`).toDate()
                : null;

            setStartDateTime(startDateTimeInitial);
            setEndDateTime(endDateTimeInitial);
            setStartTime(startTimeInitial);
            setEndTime(endTimeInitial);
            setRepeats(resolveRepeatsInitialValue(blockedPeriod.applicableOnDays));
            setApplicableOnDays(blockedPeriod.applicableOnDays ?? []);
            setDescription(blockedPeriod.description);
        }
    }, [blockedPeriodId, blockedPeriod]);

    useEffect(() => {
        autoFocusRef.current?.focus();
    }, [autoFocusRef]);

    const isFieldDisabled = useMemo(
        () => Boolean(blockedPeriodId) && isfetchingBlockedPeriod,
        [blockedPeriodId, isfetchingBlockedPeriod]
    );

    const isBlockedTimeVisible = useMemo(() => {
        return repeats !== REPEATS.DO_NOT_REPEAT;
    }, [repeats]);

    const isRepeatsOnVisible = useMemo(() => {
        return repeats === REPEATS.CUSTOM;
    }, [repeats]);

    const validateFormFields = (): boolean => {
        const validated = {
            startDateTime: and(isNotNull(startDateTime, InputError.Empty), value =>
                and(isPastDate(startDateTime, InputError.InvalidPastDate), () =>
                    isValidDateFormat(startDateTime, DATE_FORMATS['dd MMM yyyy hh:mm a'], InputError.InvalidDateFormat)
                )
            ),
            endDateTime: and(isNotNull(endDateTime, InputError.Empty), () =>
                and(isAfterDate(endDateTime, startDateTime, InputError.InvalidAfterDate), () =>
                    isValidDateFormat(endDateTime, DATE_FORMATS['dd MMM yyyy hh:mm a'], InputError.InvalidDateFormat)
                )
            ),
            description: isFilledString(description, InputError.Empty),
        } as ValidatedBlockedPeriod;

        if (isBlockedTimeVisible) {
            const startTimeParsed = dayjs(`${DUMMY_DATE} ${dayjs(startTime).format(TIME_FORMATS['HH:mm:ss'])}`).toDate();

            const endTimeParsed = dayjs(`${DUMMY_DATE} ${dayjs(endTime).format(TIME_FORMATS['HH:mm:ss'])}`).toDate();

            validated.startTime = and(isNotNull(startTime, InputError.Empty), () =>
                isValidTimeFormat(startTime, TIME_FORMATS['HH:mm'], InputError.InvalidTimeFormat)
            );

            validated.endTime = and(isNotNull(endTime, InputError.Empty), () =>
                and(isAfterTime(endTimeParsed, startTimeParsed, InputError.InvalidAfterTime), () =>
                    isValidTimeFormat(endTime, TIME_FORMATS['HH:mm'], InputError.InvalidTimeFormat)
                )
            );
        }

        if (!allValid(validated)) {
            setStartDateTimeError(validated.startDateTime.isValid ? null : validated.startDateTime.error);

            setEndDateTimeError(validated.endDateTime.isValid ? null : validated.endDateTime.error);

            setDescriptionError(validated.description.isValid ? null : validated.description.error);

            if (isBlockedTimeVisible && validated.startTime && validated.endTime) {
                setStartTimeError(validated.startTime.isValid ? null : validated.startTime.error);
                setEndTimeError(validated.endTime.isValid ? null : validated.endTime.error);
            }

            return false;
        }

        return true;
    };

    const submitForm = async () => {
        if (!validateFormFields()) {
            return;
        }

        const postData = {
            start: startDateTime ? dayjs(startDateTime).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']) : null,
            end: endDateTime ? dayjs(endDateTime).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']) : null,
            description,
            applicableOnDays: applicableOnDays.length ? applicableOnDays : null,
            applicableStartTime: startTime && isBlockedTimeVisible ? dayjs(startTime).format(TIME_FORMATS['HH:mm:ss']) : null,
            applicableEndTime: endTime && isBlockedTimeVisible ? dayjs(endTime).format(TIME_FORMATS['HH:mm:ss']) : null,
        } as BlockedPeriodRequest;

        try {
            setFormError(null);
            setIsSaving(true);

            if (blockedPeriodId) {
                await editStopSalesRule(currentUser.accountId?.toString() ?? '', blockedPeriodId, postData);
            } else {
                await createStopSalesRule(currentUser.accountId?.toString() ?? '', postData);
            }

            setIsSaving(false);
            onSubmit();
        } catch (error) {
            setIsSaving(false);

            if (error instanceof BaseError) {
                setFormError(new BaseError(error.message));
                return;
            }
        }
    };

    const handleApplicableOnDays = (key: keyof typeof WEEKDAYS, checked: boolean) => {
        if (checked) {
            !applicableOnDays.includes(key) && setApplicableOnDays(prevValue => [...prevValue, key]);
        } else {
            setApplicableOnDays(applicableOnDays.filter(element => element !== key));
        }
    };

    const handleStartTime = (newTime: Date | null) => {
        setStartTime(newTime);
        setStartTimeError(null);
    };

    const handleEndTime = (newTime: Date | null) => {
        setEndTime(newTime);
        setEndTimeError(null);
    };

    const handleRepeatsOn = (newValue: REPEATS) => {
        setRepeats(newValue);
        setFormError(null);

        if (newValue !== REPEATS.CUSTOM && newValue !== REPEATS.DO_NOT_REPEAT) {
            setApplicableOnDays([resolveApplicableOnDaysValue(newValue)]);
        } else {
            setApplicableOnDays([]);
        }

        if (newValue !== REPEATS.DO_NOT_REPEAT) {
            if (startDateTime !== null) {
                setStartTime(startDateTime);
            }

            if (endDateTime !== null) {
                setEndTime(endDateTime);
            }
        }
    };

    const resolveFormErrorMessage = (error: FormError | BaseError | null) => {
        if (error instanceof BaseError) {
            return error.message;
        }

        return t(getFormErrorMessage(FormError.UnexpectedError));
    };

    return (
        <Modal
            isOpen={isModalOpen}
            isDisabled={isSaving || isFieldDisabled}
            onClose={onClose}
            onSubmit={submitForm}
            title={blockedPeriodId ? t('pages.availability.editBlockPeriod') : t('pages.availability.createBlockPeriod')}
            confirmButtonLabel={blockedPeriodId ? t('buttonName.save') : t('buttonName.create')}
            cancelButtonLabel={t('buttonName.back')}
        >
            <Grid
                container
                sx={{
                    backgroundColor: COLORS.WHITE,
                    margin: 0,
                }}
            >
                <Grid item display="flex" flexDirection="column">
                    <Grid container columnSpacing={4}>
                        <Grid
                            item
                            xs={12}
                            lg={12}
                            sx={{
                                paddingTop: '0',
                                paddingBottom: '1rem',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: COLORS.BLUE_DARK,
                                    fontWeight: '700',
                                }}
                            >
                                {t('rideInformation')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            {isBlockedTimeVisible && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: isMobile ? 'column' : 'row',
                                    }}
                                >
                                    <DatePicker
                                        className={isMobile ? classes.formFieldMobile : classes.dateFieldStart}
                                        label={t('form.fields.startDate')}
                                        format={DATE_FORMATS['EEE, dd MMM yyyy']}
                                        value={startDateTime}
                                        onChange={newValue => {
                                            setStartDateTime(newValue);
                                            setStartDateTimeError(null);
                                            setFormError(null);
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: startDateTimeError !== null,
                                                helperText: startDateTimeError ? t(startDateTimeError) : undefined,
                                                variant: 'filled',
                                                required: true,
                                                inputProps: { readOnly: true },
                                                onClick: () => setOpenStartDate(true),
                                            },
                                        }}
                                        localeText={datePickerPlaceholder}
                                        onClose={() => setOpenStartDate(false)}
                                        open={openStartDate}
                                    />
                                    <DatePicker
                                        className={!isMobile ? classes.dateFieldEnd : ''}
                                        label={t('form.fields.endDate')}
                                        format={DATE_FORMATS['EEE, dd MMM yyyy']}
                                        value={endDateTime}
                                        onChange={newValue => {
                                            setEndDateTime(newValue);
                                            setEndDateTimeError(null);
                                            setFormError(null);
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: endDateTimeError !== null,
                                                helperText: endDateTimeError ? t(endDateTimeError) : undefined,
                                                variant: 'filled',
                                                required: true,
                                                inputProps: { readOnly: true },
                                                onClick: () => setOpenEndDate(true),
                                            },
                                        }}
                                        localeText={datePickerPlaceholder}
                                        onClose={() => setOpenEndDate(false)}
                                        open={openEndDate}
                                    />
                                </Box>
                            )}
                            {!isBlockedTimeVisible && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: isMobile ? 'column' : 'row',
                                    }}
                                >
                                    <DateTimePicker
                                        className={isMobile ? classes.formFieldMobile : classes.dateFieldStart}
                                        label={t('form.fields.startDate')}
                                        format={DATE_FORMATS['EEE, dd MMM yyyy at HH:mm a']}
                                        value={startDateTime}
                                        onChange={newValue => {
                                            setStartDateTime(newValue);
                                            setStartDateTimeError(null);
                                            setFormError(null);
                                        }}
                                        disabled={isSaving || isFieldDisabled}
                                        slotProps={{
                                            textField: {
                                                error: startDateTimeError !== null,
                                                helperText: startDateTimeError ? t(startDateTimeError) : undefined,
                                                variant: 'filled',
                                                required: true,
                                                onClick: () => setOpenStartDateTime(true),
                                            },
                                            mobilePaper: { className: classes.timeSepartor },
                                        }}
                                        onClose={() => setOpenStartDateTime(false)}
                                        open={openStartDateTime}
                                    />
                                    <DateTimePicker
                                        className={!isMobile ? classes.dateFieldEnd : ''}
                                        label={t('form.fields.endDate')}
                                        format={DATE_FORMATS['EEE, dd MMM yyyy at HH:mm a']}
                                        value={endDateTime}
                                        onChange={newValue => {
                                            setEndDateTime(newValue);
                                            setEndDateTimeError(null);
                                            setFormError(null);
                                        }}
                                        disabled={isSaving || isFieldDisabled}
                                        slotProps={{
                                            textField: {
                                                error: endDateTimeError !== null,
                                                helperText: endDateTimeError ? t(endDateTimeError) : undefined,
                                                variant: 'filled',
                                                required: true,
                                                onClick: () => setOpenEndDateTime(true),
                                            },
                                            mobilePaper: { className: classes.timeSepartor },
                                        }}
                                        onClose={() => setOpenEndDateTime(false)}
                                        open={openEndDateTime}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        rowSpacing={2}
                        sx={{
                            backgroundColor: COLORS.WHITE,
                            margin: 0,
                        }}
                    >
                        <Grid item xs={12} lg={12}>
                            <Typography
                                sx={{
                                    color: COLORS.BLUE_DARK,
                                    fontWeight: '700',
                                }}
                            >
                                {t('pages.availability.repetitionRules')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ paddingLeft: '0 !important' }}>
                            <Select
                                label={t('pages.availability.repeats')}
                                value={repeats}
                                required
                                disabled={isSaving || isFieldDisabled}
                                onChangeValue={newValue => handleRepeatsOn(newValue)}
                            >
                                {Object.keys(REPEATS).map(repeat => (
                                    <SelectOption key={repeat} value={REPEATS[repeat as keyof typeof REPEATS]}>
                                        {REPEATS[repeat as keyof typeof REPEATS]}
                                    </SelectOption>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    {isRepeatsOnVisible && (
                        <>
                            <Grid item xs sx={{ padding: '1rem 0' }}>
                                <Typography
                                    sx={{
                                        color: COLORS.BLUE_DARK,
                                        fontWeight: '700',
                                    }}
                                >
                                    {t('pages.availability.repeatsOn')}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                sx={{
                                    paddingLeft: '0',
                                    flexDirection: 'column',
                                    paddingBottom: '1rem',
                                }}
                                direction="row"
                            >
                                {Object.keys(WEEKDAYS).map(weekday => {
                                    return (
                                        <FormControlLabel
                                            key={weekday}
                                            control={
                                                <Checkbox
                                                    checked={applicableOnDays.includes(weekday as keyof typeof WEEKDAYS)}
                                                    disabled={isSaving || isFieldDisabled}
                                                    onChange={event =>
                                                        handleApplicableOnDays(
                                                            weekday as keyof typeof WEEKDAYS,
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            }
                                            label={WEEKDAYS[weekday as keyof typeof WEEKDAYS]}
                                            className={classes.checkbox}
                                        />
                                    );
                                })}
                            </Grid>
                        </>
                    )}
                    {isBlockedTimeVisible && (
                        <>
                            <Grid
                                item
                                xs={12}
                                lg={12}
                                sx={{
                                    paddingLeft: '0',
                                    paddingTop: '1.25rem',
                                    paddingBottom: '1rem',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: COLORS.BLUE_DARK,
                                        fontWeight: '700',
                                    }}
                                >
                                    {t('pages.availability.blockedTimeOnSelectedWeekdays')}
                                </Typography>
                            </Grid>
                            <Grid container columnSpacing={4}>
                                <Grid item xs={12} lg={12} md={12}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: isMobile ? 'column' : 'row',
                                        }}
                                    >
                                        <TimePicker
                                            className={isMobile ? classes.formFieldMobile : classes.dateFieldStart}
                                            label={t('form.fields.startTime')}
                                            value={startTime}
                                            onChange={newValue => {
                                                handleStartTime(newValue);
                                                setFormError(null);
                                            }}
                                            format={TIME_FORMATS['HH:mm a']}
                                            slotProps={{
                                                textField: {
                                                    error: startTimeError !== null,
                                                    helperText: startTimeError ? t(startTimeError) : undefined,
                                                    variant: 'filled',
                                                    required: true,
                                                    inputProps: { readOnly: true },
                                                    onClick: () => setOpenStartTime(true),
                                                },
                                            }}
                                            ampm={false}
                                            onClose={() => setOpenStartTime(false)}
                                            open={openStartTime}
                                        />
                                        <TimePicker
                                            className={!isMobile ? classes.dateFieldEnd : ''}
                                            label={t('form.fields.endTime')}
                                            value={endTime}
                                            onChange={newValue => {
                                                handleEndTime(newValue);
                                                setFormError(null);
                                            }}
                                            format={TIME_FORMATS['HH:mm a']}
                                            slotProps={{
                                                textField: {
                                                    error: endTimeError !== null,
                                                    helperText: endTimeError ? t(endTimeError) : undefined,
                                                    variant: 'filled',
                                                    required: true,
                                                    inputProps: { readOnly: true },
                                                    onClick: () => setOpenEndTime(true),
                                                },
                                            }}
                                            ampm={false}
                                            onClose={() => setOpenEndTime(false)}
                                            open={openEndTime}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Grid
                        item
                        xs={12}
                        lg={12}
                        sx={{
                            paddingLeft: '0',
                            paddingTop: '1.25rem',
                            paddingBottom: '1rem',
                        }}
                    >
                        <Typography
                            sx={{
                                color: COLORS.BLUE_DARK,
                                fontWeight: '700',
                            }}
                        >
                            {t('pages.availability.description')}
                        </Typography>
                    </Grid>
                    <Grid item xs sx={{ margin: 0 }}>
                        <TextField
                            value={description}
                            onChange={event => {
                                setDescription(event.target.value);
                                setDescriptionError(null);
                                setFormError(null);
                            }}
                            label={t('pages.availability.yourDescription')}
                            type="text"
                            autoFocus
                            name="description"
                            inputRef={autoFocusRef}
                            error={descriptionError !== null}
                            helperText={descriptionError ? t(descriptionError) : undefined}
                            required
                            disabled={isSaving || isFieldDisabled}
                        />
                    </Grid>
                    {formError && (
                        <Grid
                            item
                            xs={12}
                            lg={12}
                            sx={{
                                paddingLeft: '0 !important',
                                paddingBottom: '1rem',
                                paddingTop: '0.5rem',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: COLORS.RED,
                                    fontSize: '1rem',
                                    marginBottom: '0 !important',
                                }}
                            >
                                {formError ? resolveFormErrorMessage(formError) : null}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CreateAvailabilityModal;
