import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import { Box } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconedLabel } from '../../../../components/iconedLabel/IconedLabel';
import { COLORS } from '../../../../constants/colors';
import useDataGridStyles from '../../../../styles/DataGrid';
import CellText from '../../components/CellText';
import CellWrapper from '../../components/CellWrapper';
import StatusBadge from '../../components/StatusBadge';
import { RideRow } from '../api/types';
import { ChangeStatusButton } from './components/ChangeStatusButton';
import { FlightInfo } from './components/FlightInfo';
import { RideInformationCell } from './components/RideInformationCell';
import { RideChangeStatusOptionsMap } from './ridesChangeStatusUtil';

export const useRidesColumns = (
    handleChangeStatusButtonClick: (rideId: string, optionsMap: RideChangeStatusOptionsMap) => void,
    handleReferenceAndRemarks: (data: RideRow) => void,
    handleDownloadRideOrder: (id: string) => void,
    handleRemoveDriver: (rideId: string, driverId: string) => void,
    handleDriverAndVehicle: (rideId: string) => void,
    statusBadgeKey?: number
) => {
    const dataGridClases = useDataGridStyles();
    const { t } = useTranslation();

    const desktopColumns = useMemo(() => {
        const columns = [
            {
                field: 'status',
                headerName: t('status'),
                width: 170,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <StatusBadge
                            key={statusBadgeKey}
                            status={params.row.status}
                            trackingStatus={params.row.trackingStatus}
                            pickupDateTimeUtc={params.row.originalPickupUtc}
                        />
                    </CellWrapper>
                ),
            },
            {
                field: 'pickup',
                headerName: t('pickUp'),
                minWidth: 230,
                flex: 1,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <CellText>{params.row.pickupDateTime}</CellText>
                    </CellWrapper>
                ),
            },
            {
                field: 'route',
                headerName: t('pages.rides.route'),
                minWidth: 200,
                flex: 1,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <CellText marginBottom="0.5rem">
                            <strong style={{ color: COLORS.SLATE_GREY }}>{t('from')}: </strong>
                            {params.row.pickUp.location.name}
                        </CellText>
                        <CellText>
                            <strong style={{ color: COLORS.SLATE_GREY }}>{t('To')}: </strong>
                            {params.row.dropOff.location.name}
                        </CellText>
                        <FlightInfo row={params.row} />
                    </CellWrapper>
                ),
            },
            {
                field: 'rideInformation',
                headerName: t('rideInformation'),
                width: 190,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <RideInformationCell row={params.row} />
                    </CellWrapper>
                ),
            },
            {
                field: 'driver',
                headerName: t('driver'),
                width: 200,
                sortable: false,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <CellText marginBottom=".5rem">
                            <IconedLabel
                                icon={BadgeOutlinedIcon}
                                dataLabel={params.row.driver?.name ?? t('notAssigned')}
                                suffix=""
                            />
                        </CellText>
                        <CellText>
                            <IconedLabel
                                icon={DirectionsCarOutlinedIcon}
                                label={t('model')}
                                dataLabel={params.row.vehicleType}
                                alignToTop
                            />
                        </CellText>
                    </CellWrapper>
                ),
            },
            {
                field: 'references',
                headerName: t('pages.rides.references'),
                minWidth: 180,
                flex: 1,
                sortable: false,
                headerClassName: dataGridClases.hideSeparator,
                renderCell: (params: GridRowParams) => (
                    <CellWrapper>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
                            <Box width="160px">
                                <CellText marginBottom="0.5rem">
                                    <strong style={{ color: COLORS.SLATE_GREY }}>{t('rideNumber')}</strong>:{' '}
                                    {params.row.rideNumber}
                                </CellText>
                                {params.row.supplierReference && (
                                    <CellText marginBottom="0.5rem">
                                        <strong style={{ color: COLORS.SLATE_GREY }}>{t('reference')}</strong>:{' '}
                                        {params.row.supplierReference}
                                    </CellText>
                                )}
                                <CellText marginBottom="0.5rem">
                                    <strong style={{ color: COLORS.SLATE_GREY }}>{t('price')}</strong>:{' '}
                                    <span style={{ color: COLORS.BLUE_DARK }}>{params.row.price}</span>
                                </CellText>
                            </Box>
                        </Box>
                    </CellWrapper>
                ),
            },
            {
                field: 'changeStatus',
                headerName: '',
                minWidth: 210,
                sortable: false,
                flex: 1,
                headerClassName: dataGridClases.hideSeparator,
                renderCell: (params: GridRowParams) => (
                    <ChangeStatusButton
                        row={params.row}
                        handleChangeStatusButtonClick={handleChangeStatusButtonClick}
                        handleReferenceAndRemarks={handleReferenceAndRemarks}
                        handleDownloadRideOrder={handleDownloadRideOrder}
                        handleRemoveDriver={handleRemoveDriver}
                        handleDriverAndVehicle={handleDriverAndVehicle}
                        isMobile={false}
                    />
                ),
            },
        ];

        return columns;
    }, [
        dataGridClases.hideSeparator,
        handleChangeStatusButtonClick,
        handleDownloadRideOrder,
        handleReferenceAndRemarks,
        handleRemoveDriver,
        handleDriverAndVehicle,
        statusBadgeKey,
        t,
    ]);

    return [desktopColumns];
};
