import { centerCrop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import Resizer from 'react-image-file-resizer';

import { Photo } from '../../api/types';

const TO_RADIANS = Math.PI / 180;

export function canvasPreview(
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: PixelCrop,
    scale = 1,
    rotate = 0,
    isMobile = false
) {
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        throw new Error('No 2d context');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    /*
     * DevicePixelRatio slightly increases sharpness on retina devices
     * at the expense of slightly slower render times and needing to
     * size the image back down if you want to download/upload and be
     * true to the images natural size.
     */
    const pixelRatio = isMobile ? 1 : Math.min(window.devicePixelRatio, 2);

    // Const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);

    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);

    // 3) Rotate around the origin
    ctx.rotate(rotateRads);

    // 2) Scale the image
    ctx.scale(scale, scale);

    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

    ctx.restore();
}

export function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

export function getUnionPicturesToUploadByUrl(urlArr: string[], objArr: Photo[]) {
    const urls = new Set(urlArr);

    const unionArray = objArr.filter(obj => urls.has(obj.url));

    return unionArray;
}

export const resizeImageFile = file =>
    new Promise(resolve => {
        Resizer.imageFileResizer(
            file,
            400,
            400,
            'JPEG',
            100,
            0,
            uri => {
                resolve(uri);
            },
            'blob'
        );
    });
