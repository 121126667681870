import { IconedData } from '@get-e/react-components';
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../../../constants/colors';
import theme from '../../../../../../styles/theme';
import { Passenger } from '../../../../api/types';
import { RideSummaryAccordions } from '../RideSummaryCardContext';
import informationSummaryStyles from './InformationSummary.styles';
import SummaryAccordion from './SummaryAccordion';

interface PassengerInformationSummaryProps {
    unid: keyof typeof RideSummaryAccordions;
    passengers?: Passenger[];
    numberOfPassengers?: string;
    luggage?: string;
    clientInstructions?: string;
    displayAsAcccordion?: boolean;
}

const getLeadPassenger = (passengers: Passenger[]) => passengers.filter(passenger => passenger.isLead)[0];

const getOtherPassengers = (passengers: Passenger[]) => passengers.filter(passenger => !passenger.isLead);

const PassengerInformationSummary = ({
    unid,
    passengers,
    numberOfPassengers,
    luggage,
    clientInstructions,
    displayAsAcccordion = true,
}: PassengerInformationSummaryProps) => {
    const classes = informationSummaryStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const leadPassenger = useMemo(() => {
        return passengers ? getLeadPassenger(passengers) : undefined;
    }, [passengers]);

    const content = useMemo(
        () => (
            <>
                <IconedData
                    icon={PersonOutlineOutlinedIcon}
                    data={numberOfPassengers}
                    tooltip={t('numberOfPassengers')}
                    dataLabel={`${t('numberOfPassengers')}:`}
                    dataLabelColor={COLORS.SLATE_GREY}
                />
                <IconedData
                    icon={LuggageOutlinedIcon}
                    data={luggage}
                    tooltip={t('piecesOfLuggage')}
                    dataLabel={`${t('piecesOfLuggage')}:`}
                    dataLabelColor={COLORS.SLATE_GREY}
                />
                <Box display="flex">
                    <IconedData
                        icon={EmojiPeopleOutlinedIcon}
                        tooltip={t('pages.summary.mainPassenger')}
                        rightMargin="0.5rem"
                        dataLabel={`${leadPassenger?.firstName} ${leadPassenger?.lastName}`}
                        dataLabelColor={COLORS.SLATE_GREY}
                    />
                    <IconedData
                        icon={PhoneIcon}
                        tooltip={t('form.fields.phoneNumber')}
                        dataLabel={leadPassenger?.phone ?? ''}
                        dataLabelColor={COLORS.SLATE_GREY}
                    />
                </Box>
                {getOtherPassengers(passengers ?? []).map(passenger => (
                    <Box key={passenger.id} display="flex" paddingLeft="2.125rem">
                        <IconedData
                            rightMargin="0.5rem"
                            dataLabel={`${passenger.firstName} ${passenger.lastName}`}
                            dataLabelColor={COLORS.SLATE_GREY}
                        />
                        <IconedData
                            icon={PhoneIcon}
                            tooltip={t('form.fields.phoneNumber')}
                            dataLabel={passenger.phone ? passenger.phone : t('notProvided')}
                            dataLabelColor={COLORS.SLATE_GREY}
                        />
                    </Box>
                ))}
                <IconedData
                    icon={TextSnippetOutlinedIcon}
                    data={clientInstructions ? clientInstructions : t('notAvailable')}
                    tooltip={t('clientInstructions')}
                    dataLabel={`${t('clientInstructions')}:`}
                    dataLabelColor={COLORS.SLATE_GREY}
                />
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [leadPassenger?.firstName, leadPassenger?.lastName, leadPassenger?.phone, t]
    );

    return isMobile && displayAsAcccordion ? (
        <SummaryAccordion unid={unid} summaryTitle={t('pages.summary.passengerInfo')} detailsContent={content} />
    ) : (
        <>
            <Typography variant="h3" component="h4" className={classes.subtitle}>
                {t('passengers')}
            </Typography>
            {content}
        </>
    );
};

export default PassengerInformationSummary;
