import LocationOffIcon from '@mui/icons-material/LocationOff';
import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import getDueInTime from '../../../helpers/getDueInTime';
import { getRideStatusColor, getRideStatusTextColor, RideStatus, RideTrackingStatus } from '../../../helpers/rideStatusUtil';

interface StatusBadgeProps {
    status: keyof typeof RideStatus;
    trackingStatus?: keyof typeof RideTrackingStatus;
    pickupDateTimeUtc?: string;
}

const StatusBadge = ({ status, trackingStatus, pickupDateTimeUtc }: StatusBadgeProps) => {
    const { t } = useTranslation();

    const resolvedStatus = useMemo(() => {
        switch (status) {
            case 'ACTIVE':
                switch (trackingStatus) {
                    case 'DRIVER_ON_THE_WAY':
                        return t(RideStatus.DRIVER_ON_THE_WAY);
                    case 'DRIVER_AT_PICKUP':
                        return t(RideStatus.DRIVER_AT_PICKUP);
                    case 'PASSENGER_ON_BOARD':
                        return t(RideStatus.PASSENGER_ON_BOARD);
                    default:
                        return t(RideStatus[status]);
                }

            default:
                return t(RideStatus[status]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, trackingStatus]);

    const dueIn = useMemo(
        () => (status === 'BOOKED' && pickupDateTimeUtc ? getDueInTime(pickupDateTimeUtc, t('dueIn')) : ''),
        [pickupDateTimeUtc, status, t]
    );

    const untrackingIcon = useMemo(() => {
        const isNotTrackedRideStatus = trackingStatus === 'NOT_TRACKING' && (status === 'ACTIVE' || status === 'COMPLETED');

        return isNotTrackedRideStatus ? (
            <LocationOffIcon
                style={{
                    color: getRideStatusTextColor(status),
                    fontSize: '1rem',
                    marginLeft: '0.25rem',
                }}
            />
        ) : null;
    }, [status, trackingStatus]);

    return (
        <Box
            sx={{
                backgroundColor: getRideStatusColor(status),
                width: 'auto',
                height: '22px',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0.25rem 0.625rem',
            }}
        >
            <Typography
                sx={{
                    textAlign: 'center',
                    color: getRideStatusTextColor(status),
                    fontSize: '0.75rem',
                    lineHeight: '0.875rem',
                    fontWeight: 400,
                }}
            >
                {dueIn || resolvedStatus}
            </Typography>
            {untrackingIcon}
        </Box>
    );
};

export default StatusBadge;
