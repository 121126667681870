/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryObserverResult } from 'react-query';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import { SINGLE_RIDE_DRIVER_SAVE, SINGLE_RIDE_DRIVER_UNASSIGN } from '../../../../constants/amplitude/supplierKeys';
import { Severity, useNotificationContext } from '../../../../context/NotificationContext';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import isFilledString from '../../../../helpers/inputValidation/validators/isFilledString';
import { addDriver, useDrivers } from '../../../drivers/api';
import { AddDriverRequest, Driver } from '../../../drivers/api/types';
import { assignDriverToRide, unassignDriverFromRide } from '../../../rides/supplier/api';
import { AssignDriverToRide } from '../../../rides/supplier/api/types';
import { RideResponse } from '../../api/types';
import { RideContext } from '../context/RideContext';

export const ADD_DRIVER_ID = -1;
export const NO_RESULTS_DRIVER_ID = -2;

export const DEAFULT_ADD_DRIVER_BUTTON_OPTION = {
    id: ADD_DRIVER_ID,
    name: '',
    phoneNumber: '',
    phoneOs: null,
    tripsCount: 0,
} as Driver;

export const NO_RESULTS_DRIVER_OPTION = {
    id: NO_RESULTS_DRIVER_ID,
    name: '',
    phoneNumber: '',
    phoneOs: null,
    tripsCount: 0,
};

export const initialDriverInformationError = {
    nameError: null,
    phoneNumberError: null,
} as DriverInformationError;

export interface DriverInformationError {
    nameError: InputError | null;
    phoneNumberError?: string | null;
}

const useSupplierDriverInformation = (
    initialDriverInformation?: Driver
): {
    driverInformations: Driver[];
    driverInformation?: Driver;
    setDriverInformation: Dispatch<SetStateAction<Driver | undefined>>;
    driverInformationError: DriverInformationError;
    setDriverInformationError: Dispatch<SetStateAction<DriverInformationError>>;
    addDriverInformation: (driverInformation: AddDriverRequest) => Promise<void>;
    validateDriverInformationFields: () => boolean;
    refetchDrivers: () => Promise<QueryObserverResult<Driver[]>>;
    handleChangeDriver: (request: AssignDriverToRide) => Promise<RideResponse | undefined>;
    handleUnassignDriver: (rideId: string) => Promise<RideResponse | undefined>;
    isLoading: boolean;
} => {
    const [values, setValues] = useState<Driver[]>([]);
    const [value, setValue] = useState<Driver | undefined>(initialDriverInformation);
    const [error, setError] = useState<DriverInformationError>(initialDriverInformationError);
    const { showNotification } = useNotificationContext();
    const { t } = useTranslation();
    const { amplitudeEventProperties } = useContext(RideContext);

    const { data: drivers, refetch, isLoading, isFetching } = useDrivers();

    useEffect(() => {
        if (!isLoading && !isFetching && drivers && values.length === 0) {
            setValues(drivers);
        }
    }, [drivers, isLoading, isFetching, values.length]);

    const validateFields = (): boolean => {
        const validatedName = isFilledString(value?.name ?? '', InputError.Empty);
        const fieldErros = { nameError: !value || validatedName.isValid ? null : validatedName.error };

        setError(fieldErros);

        return fieldErros.nameError === null;
    };

    const handleAddDriverInformation = async (newDriver: AddDriverRequest) => {
        try {
            const response = await addDriver(newDriver);

            setValue(response.data);
            setError(initialDriverInformationError);
            showNotification(t('pages.drivers.driverSuccessfullyCreated'), Severity.Info);
            logAmplitudeEvent(SINGLE_RIDE_DRIVER_SAVE, amplitudeEventProperties);
        } catch (addDriverError: any) {
            let errorMessage = null;

            if (addDriverError.response && addDriverError.response.data) {
                errorMessage = addDriverError.response.data.errors?.phoneNumber?.[0];
            }

            setError({
                phoneNumberError: errorMessage,
                nameError: null,
            });
            showNotification(t('errors.defaultError'), Severity.Error);
        } finally {
            await refetch();
        }
    };

    const handleChangeDriver = async (request: AssignDriverToRide) => {
        try {
            const { data } = await assignDriverToRide(request);

            logAmplitudeEvent(SINGLE_RIDE_DRIVER_SAVE, amplitudeEventProperties);
            return data;
        } catch (changeDriverError) {
            showNotification(t('alert.driverAssignError'), Severity.Error);
        }
    };

    const handleUnassignDriver = async (rideId: string) => {
        try {
            const { data } = await unassignDriverFromRide(rideId);

            logAmplitudeEvent(SINGLE_RIDE_DRIVER_UNASSIGN, amplitudeEventProperties);
            return data;
        } catch (unassignDriverError) {
            showNotification(t('alert.driverUnassignError'), Severity.Error);
        }
    };

    return {
        driverInformations: values,
        driverInformation: value,
        setDriverInformation: setValue,
        driverInformationError: error,
        setDriverInformationError: setError,
        addDriverInformation: handleAddDriverInformation,
        validateDriverInformationFields: validateFields,
        refetchDrivers: refetch,
        handleChangeDriver,
        handleUnassignDriver,
        isLoading,
    };
};

export default useSupplierDriverInformation;
