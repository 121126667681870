import { FullScreenLoader } from '@get-e/react-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FunctionComponent } from 'react';

import { useCurrentProfileContext } from '../../../context/CurrentProfileContext';
import { AccountType } from '../../../services/types';
import { default as CustomerRide } from '../customer/Ride';
import { default as CustomerRideNew } from '../customer/RideView';
import { default as SupplierRide } from '../supplier/Ride';
import { default as SupplierRideView } from '../supplier/RideView';

const RidePageSwitch: FunctionComponent = () => {
    const {
        currentProfile: { accountType },
    } = useCurrentProfileContext();

    const { useNewCustomerRidePage, useNewSupplierPage } = useFlags();

    switch (accountType) {
        case AccountType.CUSTOMER:
            return useNewCustomerRidePage ? <CustomerRideNew /> : <CustomerRide />;
        case AccountType.SUPPLIER:
            return useNewSupplierPage ? <SupplierRideView /> : <SupplierRide />;
        default:
            return <FullScreenLoader />;
    }
};

export default RidePageSwitch;
