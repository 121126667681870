/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, PrimaryButton, SecondaryButton, SignedOutLayout, TertiaryButtonLink, TextField } from '@get-e/react-components';
import { Typography, Paper, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import ShowPasswordButton from '../../components/buttons/showPasswordButton/ShowPasswordButton';
import { COLORS } from '../../constants/colors';
import { GET_CONTACT, GET_E_TERMS_AND_CONDITIONS } from '../../constants/getEWebsites';
import { STATUS_401 } from '../../constants/httpStatusCodes';
import { FORGOT_PASSWORD, RIDES } from '../../constants/urlPaths';
import { IS_LOGIN_WITH_NEW_PORTAL } from '../../constants/windowStorageKeys';
import { useAuth } from '../../context/AuthenticatedUserContext';
import FormError from '../../helpers/inputValidation/FormError';
import getFormErrorMessage from '../../helpers/inputValidation/getFormErrorMessage';
import { InputError } from '../../helpers/inputValidation/InputError';
import allValid from '../../helpers/inputValidation/validators/allValid';
import and from '../../helpers/inputValidation/validators/and';
import isEmail from '../../helpers/inputValidation/validators/isEmail';
import isFilledString from '../../helpers/inputValidation/validators/isFilledString';
import { logo } from '../../public/assets/images';

const useStyles = makeStyles(styleTheme => ({
    container: {
        padding: '2rem',
        maxWidth: '320px',
        margin: '0 auto',
    },
    forgotPasswordContainer: {
        marginTop: '1rem',
        textAlign: 'center',
    },
    dontHaveAccount: {
        marginTop: '2rem',
        textAlign: 'center',
        color: COLORS.SLATE_GREY,
        fontSize: '.8rem',
    },
    signInButtonContainer: { marginTop: '1rem' },
    secondaryButton: { width: '100%' },
    logoImage: {
        height: '47px',
        width: '200px',
        marginBottom: '1rem',
    },
    textField: {
        marginBottom: 0,
        paddingBottom: '0.75rem',
    },
    signedOutMobile: { [styleTheme.breakpoints.down('sm')]: { '& .MuiToolbar-root': { transform: 'translate(-15%, 0)' } } },
    termsLink: {
        color: COLORS.BLUE,
        '&:hover': {
            color: COLORS.BLUE,
        },
    },
}));

const Login = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { onLogin } = useAuth();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<InputError | null>(null);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<InputError | null>(null);
    const [showingPassword, setShowingPassword] = useState(false);
    const [signingIn, setSigningIn] = useState(false);
    const [formError, setFormError] = useState<FormError | null>(null);
    const autoFocusRef = useRef<HTMLInputElement>();

    useEffect(() => {
        autoFocusRef.current?.focus();
    }, [autoFocusRef]);

    const onSignIn = async () => {
        const validated = {
            email: and(isFilledString(email, InputError.Empty), () => isEmail(email, InputError.InvalidEmail)),
            password: isFilledString(password, InputError.Empty),
        };

        if (!allValid(validated)) {
            setSigningIn(false);
            setEmailError(validated.email.isValid ? null : validated.email.error);
            setPasswordError(validated.password.isValid ? null : validated.password.error);
            return;
        }

        setFormError(null);
        setSigningIn(true);

        try {
            await onLogin(email, password);
            localStorage.setItem(IS_LOGIN_WITH_NEW_PORTAL, 'true');
            history.push(RIDES);
        } catch (error: any) {
            setSigningIn(false);

            switch (error?.response?.status) {
                case STATUS_401:
                    setFormError(FormError.WrongCredentials);
                    break;
                default:
                    setFormError(error.response?.data?.message);
            }
        }
    };

    return (
        <Box className={classes.signedOutMobile}>
            <SignedOutLayout linkPrefix={t('visit').toLowerCase()}>
                <Paper elevation={0} className={classes.container}>
                    <Box textAlign="center" marginBottom="2rem">
                        <img src={logo} alt={t('getE.logo.alt')} className={classes.logoImage} />
                        <Typography fontWeight="700" marginBottom="1rem">
                            {t('pages.login.welcomeToGetE')}
                        </Typography>
                    </Box>
                    <Typography variant="body1" mb={'2rem'}>
                        {t('pages.login.signInBelow')}
                    </Typography>
                    <TextField
                        className={classes.textField}
                        value={email}
                        onChange={event => {
                            setEmail(event.target.value);
                            setEmailError(null);
                        }}
                        label={t('email')}
                        type="email"
                        autoFocus
                        autoComplete="username"
                        inputRef={autoFocusRef}
                        error={emailError !== null}
                        helperText={emailError && t(emailError)}
                        required
                    />
                    <TextField
                        className={classes.textField}
                        label={t('password')}
                        type={showingPassword ? 'text' : 'password'}
                        value={password}
                        onChange={event => {
                            setPassword(event.target.value);
                            setPasswordError(null);
                        }}
                        autoComplete="current-password"
                        InputProps={{
                            endAdornment: (
                                <ShowPasswordButton
                                    onShowPassword={isPasswordVisible => {
                                        setShowingPassword(isPasswordVisible);
                                    }}
                                />
                            ),
                        }}
                        error={passwordError !== null}
                        helperText={passwordError && t(passwordError)}
                        required
                    />
                    {formError && (
                        <Alert severity="error">
                            {formError === FormError.WrongCredentials ? t(getFormErrorMessage(formError)) : formError}
                        </Alert>
                    )}
                    <div className={classes.signInButtonContainer}>
                        <PrimaryButton onClick={onSignIn} loading={signingIn} fullWidth>
                            {t('pages.login.signIn')}
                        </PrimaryButton>
                    </div>
                    <div className={classes.forgotPasswordContainer}>
                        <TertiaryButtonLink to={FORGOT_PASSWORD}>{t('pages.login.forgotPassword')}</TertiaryButtonLink>
                    </div>

                    <div className={classes.dontHaveAccount}>{t('pages.login.dontHaveAccount')}</div>
                    <div className={classes.signInButtonContainer}>
                        <SecondaryButton
                            onClick={() => {
                                window.location.href = GET_CONTACT;
                            }}
                            loading={signingIn}
                            className={classes.secondaryButton}
                        >
                            {t('pages.login.register')}
                        </SecondaryButton>
                    </div>
                    <div>
                        <Typography
                            marginTop="1rem"
                            style={{
                                color: COLORS.SLATE_GREY,
                                fontSize: '.8rem',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            {t('pages.login.byProceeding')}
                            <a href={GET_E_TERMS_AND_CONDITIONS} className={classes.termsLink}>
                                {t('pages.login.termsAndConditions')}
                            </a>
                        </Typography>
                    </div>
                </Paper>
            </SignedOutLayout>
        </Box>
    );
};

export default Login;
