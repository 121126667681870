import { ModalAction as Modal } from '@get-e/react-components';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BookingSystemAutomatedModal = ({ onClose, onConfirm }: { onClose: () => void; onConfirm: () => void }) => {
    const { t } = useTranslation();

    const handleConfirm = () => {
        onConfirm();
    };

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={handleConfirm}
            title={t('modals.systemAutomated.title')}
            confirmButtonLabel={t('buttonName.continue')}
            cancelButtonLabel={t('buttonName.cancel')}
            maxWidth="sm"
        >
            <Typography style={{ marginBottom: '2rem' }}>{t('modals.systemAutomated.descriptionDetailed')}</Typography>
            <Typography style={{ marginBottom: '2rem' }}>
                {t('modals.systemAutomated.descriptionDetailed2')}
                <span>{t('modals.systemAutomated.descriptionDetailed2Part2')}</span>
            </Typography>
            <Typography style={{ fontWeight: 700 }}>{t('modals.systemAutomated.description')}</Typography>
        </Modal>
    );
};

export default BookingSystemAutomatedModal;
