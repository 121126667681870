import { Grid, Skeleton, Chip, useMediaQuery, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import theme from '../../../styles/theme';

const useStyles = makeStyles({
    mainContainer: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '0 2rem 2rem 2rem',
    },
    mainContainerMobile: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '2rem',
    },
    pageHeaderWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#000',
    },
    pageHeader: {
        color: '#3f51b5',
        fontSize: '1.5rem',
        fontWeight: 700,
    },
    card: {
        marginRight: '2rem',
        marginTop: '1rem',
    },
});

const ReportsLoadingSkeleton = () => {
    const classes = useStyles();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container className={isSmallDevice ? classes.mainContainerMobile : classes.mainContainer}>
            <Grid container className={classes.pageHeaderWrapper}>
                <Grid item xs display="flex" flexDirection="row" alignItems="center">
                    <Skeleton variant="text" sx={{ marginRight: '1rem' }} width={isSmallDevice ? '70%' : '20%'} height={40} />
                    <Chip
                        label={<Skeleton variant="text" width={100} height="100%" />}
                        sx={{ borderColor: '#EEF3F9' }}
                        variant="outlined"
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} marginTop="2rem">
                <Grid item xs={12} md={3} xl={3}>
                    <Skeleton variant="rectangular" width="100%" height={60} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={3}
                    xl={3}
                    marginTop={isSmallDevice ? '1.5rem' : '0'}
                    marginLeft={isSmallDevice ? 0 : '2rem'}
                >
                    <Skeleton variant="rectangular" width="100%" height={60} />
                </Grid>
            </Grid>

            <Grid item xs marginTop="2rem">
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Skeleton variant="text" width={isSmallDevice ? '25%' : '10%'} height={40} style={{ marginRight: '1rem' }} />
                    <Skeleton variant="text" width={isSmallDevice ? '25%' : '10%'} height={40} style={{ marginRight: '1rem' }} />
                    <Skeleton variant="text" width={isSmallDevice ? '25%' : '10%'} height={40} style={{ marginRight: '1rem' }} />
                    <Skeleton variant="text" width={isSmallDevice ? '25%' : '10%'} height={40} />
                </Box>
            </Grid>

            <Grid container marginTop="2rem">
                <Skeleton
                    variant="text"
                    width={250}
                    height={30}
                    style={{
                        marginRight: '1rem',
                        marginTop: '2rem',
                    }}
                />
                <Box display="flex" flexDirection={isSmallDevice ? 'column' : 'row'} alignItems="center">
                    <Skeleton variant="rectangular" width={400} height={300} className={classes.card} />
                    <Skeleton variant="rectangular" width={400} height={300} className={classes.card} />
                    <Skeleton variant="rectangular" width={400} height={300} className={classes.card} />
                </Box>

                <Skeleton
                    variant="text"
                    width={250}
                    height={30}
                    style={{
                        marginRight: '1rem',
                        marginTop: '2rem',
                    }}
                />
                <Box display="flex" flexDirection={isSmallDevice ? 'column' : 'row'} alignItems="center">
                    <Skeleton variant="rectangular" width={400} height={300} className={classes.card} />
                    <Skeleton variant="rectangular" width={400} height={300} className={classes.card} />
                    <Skeleton variant="rectangular" width={400} height={300} className={classes.card} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default ReportsLoadingSkeleton;
