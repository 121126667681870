import { useMemo } from 'react';

import { getPercentage } from '../../../../helpers/getPercentage';
import { QualityReport } from '../api/types';

interface TrackedDriversPercentage {
    trackedDriversCurrentPeriodPercentage: string;
    trackedDriversPreviousPeriodPercentage: string;
}

export function useTrackedDriversPercentage(
    currentPeriod: QualityReport | undefined,
    pastPeriod: QualityReport | undefined
): TrackedDriversPercentage {
    const trackedDriversCurrentPeriodPercentage = useMemo(
        () =>
            getPercentage(
                currentPeriod?.summary.totalAccuratelyTrackedRides ?? 0,
                currentPeriod?.summary.totalRides ?? 0,
                0
            ).toString(),
        [currentPeriod]
    );

    const trackedDriversPreviousPeriodPercentage = useMemo(
        () =>
            getPercentage(
                pastPeriod?.summary.totalAccuratelyTrackedRides ?? 0,
                pastPeriod?.summary.totalRides ?? 0,
                0
            ).toString(),
        [pastPeriod]
    );

    return {
        trackedDriversCurrentPeriodPercentage,
        trackedDriversPreviousPeriodPercentage,
    };
}
