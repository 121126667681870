import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

import { COLORS } from '../constants/colors';

const useFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
        },
        withoutLabel: { marginTop: theme.spacing(3) },
        textField: {
            width: '50ch',
            height: '70px',
            marginBottom: '2rem',

            '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
        },
        permissionsContainer: { margin: '1em 0 0 0' },
        passwordField: { height: '80px' },
        formGroup: {
            width: '100%',
            '& > *': { marginRight: theme.spacing(1) },
        },
        customLogin: {
            height: 'auto',
            width: '50ch',
        },
        formContainer: {
            padding: theme.spacing(4),
            maxWidth: '424px',
            margin: '1em auto',
            backgroundColor: theme.palette.common.white,
            [theme.breakpoints.down('xs')]: { margin: '0 auto 2em' },
        },
        margin: { margin: theme.spacing(3, 0, 0) },
        disabledField: {
            '& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled': {
                backgroundColor: COLORS.WHITE,
                border: `1px solid ${COLORS.BLACK_12}`,
                '&:before': { borderBottomStyle: 'none' },
            },
            '& .MuiInputBase-input.MuiFilledInput-input.Mui-disabled': { WebkitTextFillColor: COLORS.BLACK },
        },
    })
);

export default useFormStyles;
