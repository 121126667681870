import { makeStyles, createStyles } from '@mui/styles';

import { COLORS } from '../../../../../../constants/colors';

const informationSummaryStyles = makeStyles(() =>
    createStyles({
        subtitle: {
            marginBottom: '0.5rem',
            color: COLORS.BLUE_DARK,
            fontSize: '1rem',
            fontWeight: 700,
        },
        subtitleWithIcon: {
            marginBottom: 0,
            color: COLORS.BLUE_DARK,
            fontSize: '1rem',
            fontWeight: 700,
        },
        heading: {
            marginBottom: '1rem',
            color: COLORS.ORANGE,
            fontSize: '1rem',
            fontWeight: 700,
        },
        vehicleImage: {
            height: '77px',
            width: '240px',
        },
        vehicleImageMobile: {
            position: 'relative',
            height: '96px',
        },
        labeledDataLabel: { marginRight: '0 !important' },
        accordion: {
            '& .MuiAccordionSummary-root': {
                padding: '0 1rem',
                height: '56px',
                borderBottom: `1px solid ${COLORS.BLACK_12}`,
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'unset',
                background: COLORS.EXTRA_LIGHT_GRAY,
            },
            '& .MuiAccordionSummary-content': { margin: 0 },
            '& .MuiAccordionSummary-content.Mui-expanded': { margin: 0 },
            border: 'none',
            boxShadow: 'none',
        },
        accordionSummary: {},
        accordionDetails: {
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            borderBottom: `1px solid ${COLORS.BLACK_12}`,
        },
        pickupDropoffDateTime: {
            paddingLeft: '2.125rem',
            color: COLORS.SLATE_GREY,
            fontWeight: '700',
            fontSize: '0.875rem',
            lineHeight: '0.875rem',
            paddingTop: '0.5rem',
        },
    })
);

export default informationSummaryStyles;
