import { Grid, Skeleton, useMediaQuery } from '@mui/material';
import React from 'react';

import theme from '../../../../../styles/theme';

const RideLoadingSkeleton = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container item xs={12} marginBottom="6rem" marginTop="2rem" width="100%">
            <Grid
                container
                spacing={isMobile ? 0 : 4}
                display="flex"
                justifyContent={'space-between'}
                flexDirection="row"
                width="100%"
            >
                <Grid item xs={4}>
                    <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={50} />
                </Grid>
            </Grid>
            <Grid container spacing={isMobile ? 1 : 4} paddingTop="2rem" display="flex" flexDirection={'row'} width="100%">
                <Grid item xs={isMobile ? 12 : 8} height="60vh" order={isMobile ? 1 : 0}>
                    <Skeleton variant="rectangular" height="100%" animation="wave" />
                </Grid>
                <Grid item xs={isMobile ? 12 : 4} height="50vh">
                    <Skeleton variant="rectangular" height="50%" animation="wave" />
                    <Skeleton
                        variant="text"
                        sx={{
                            fontSize: '1.5rem',
                            marginTop: '1rem',
                        }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{
                            fontSize: '1rem',
                            width: '80%',
                        }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{
                            fontSize: '1rem',
                            width: '80%',
                        }}
                    />
                    <Skeleton variant="rectangular" width={'70%'} height={40} animation="wave" sx={{ marginTop: '1rem' }} />
                    <Skeleton
                        variant="text"
                        sx={{
                            fontSize: '1.5rem',
                            marginTop: '1rem',
                        }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{
                            fontSize: '1rem',
                            width: '80%',
                        }}
                    />
                    <Skeleton variant="rectangular" width={'70%'} height={40} animation="wave" sx={{ marginTop: '1rem' }} />
                    <Skeleton
                        variant="text"
                        sx={{
                            fontSize: '1.5rem',
                            marginTop: '1rem',
                        }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{
                            fontSize: '1rem',
                            width: '80%',
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RideLoadingSkeleton;
