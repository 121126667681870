import { TextField } from '@get-e/react-components';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import useFormStyles from '../../../../styles/Form';

interface AdditionalCostsCardProps {
    extraWaitingTime?: number;
    waitingTimeCosts?: number;
    parkingTollCosts?: number;
    otherCosts?: number;
    comments?: string;
    isVisible?: boolean;
    order?: number;
}

const AdditionalCostsCard = ({
    extraWaitingTime,
    waitingTimeCosts,
    parkingTollCosts,
    otherCosts,
    comments,
    isVisible,
    order,
}: AdditionalCostsCardProps) => {
    const { t } = useTranslation();
    const formClasses = useFormStyles();

    if (!isVisible) {
        return null;
    }

    return (
        <Box paddingBottom="1rem" order={order ?? 0}>
            <Typography
                style={{
                    fontWeight: 700,
                    color: COLORS.BLUE_DARK,
                    marginBottom: '1rem',
                }}
            >
                {t('modals.referencesAndRemarks.additionalCosts')}
            </Typography>
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{
                    xs: 1,
                    sm: 2,
                    md: 3,
                }}
            >
                <Grid item xs={12} md={6}>
                    <TextField
                        className={formClasses.disabledField}
                        type="number"
                        label={t('modals.referencesAndRemarks.extraWaitingTime')}
                        value={extraWaitingTime}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        className={formClasses.disabledField}
                        type="number"
                        label={t('modals.referencesAndRemarks.waitingTimeCosts')}
                        value={waitingTimeCosts}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        className={formClasses.disabledField}
                        type="number"
                        label={t('modals.referencesAndRemarks.parkingTollCosts')}
                        value={parkingTollCosts}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        className={formClasses.disabledField}
                        type="number"
                        label={t('modals.referencesAndRemarks.otherCosts')}
                        value={otherCosts}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={formClasses.disabledField}
                        label={t('comments')}
                        value={comments}
                        InputProps={{
                            minRows: '3',
                            multiline: true,
                        }}
                        disabled
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdditionalCostsCard;
