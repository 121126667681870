import { ModalAction as Modal } from '@get-e/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RideFeedback } from '../../api/types';
import ReviewRideCard from './ReviewRideCard';

const RideReviewModal = ({ onClose, isOpen, feedbacks }: { onClose: () => void; isOpen: boolean; feedbacks: RideFeedback[] }) => {
    const { t } = useTranslation();

    return (
        <Modal onSubmit={() => {}} isOpen={isOpen} onClose={onClose} title={t('pages.singleRide.review')} maxWidth="sm">
            <ReviewRideCard closeModal={onClose} isInModal feedbacks={feedbacks} />
        </Modal>
    );
};

export default RideReviewModal;
