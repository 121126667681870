import { Coordinates } from '../../services/types';

interface RideCordinates {
    pickupLatitude?: number;
    pickupLongitude?: number;
    dropOffLatitude?: number;
    dropOffLongitude?: number;
}

export const getRidePoints = ({ pickupLatitude, pickupLongitude, dropOffLatitude, dropOffLongitude }: RideCordinates) => {
    const pickupCoordinates =
        pickupLatitude && pickupLongitude
            ? ({
                  lat: pickupLatitude,
                  lon: pickupLongitude,
              } as Coordinates)
            : undefined;

    const dropOffCoordinates =
        dropOffLatitude && dropOffLongitude
            ? ({
                  lat: dropOffLatitude,
                  lon: dropOffLongitude,
              } as Coordinates)
            : undefined;

    return [pickupCoordinates, dropOffCoordinates];
};
