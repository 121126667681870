import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../constants/colors';
import { RIDES_FILTER, SUPPLIER_RIDES_FILTER } from '../constants/windowStorageKeys';

export enum RideStatus {
    TO_CONFIRM = 'toConfirm',
    CONFIRMED = 'confirmed',
    BOOKED = 'booked',
    TO_CONFIRM_CHANGE = 'toConfirmChange',
    DRIVER_ON_THE_WAY = 'driverOnTheWay',
    DRIVER_AT_PICKUP = 'driverAtPickup',
    PASSENGER_ON_BOARD = 'passengerOnBoard',
    ACTIVE = 'active',
    TO_CLOSE = 'toClose',
    COMPLETED = 'completed',
    TO_CONFIRM_CANCEL = 'toConfirmCancel',
    CANCELLED = 'cancelled',
}

export enum RideStatusFilter {
    BOOKED = 'booked',
    ACTIVE = 'active',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
}

export enum RideStatusSupplierFilter {
    TO_CONFIRM = 'toConfirm',
    CONFIRMED = 'confirmed',
    TO_CLOSE = 'toClose',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
}

export enum RideStatusOption {
    DRIVER_KNOWN = 'Driver known',
    NO_FLIGHT_INFORMATION = 'No flight information',
    OVERNIGHT_FLIGHT = 'Overnight flight',
    CONTACT_GREETER_INFO_KNOWN = 'Contact / Greeter info known',
    FLIGHT_TIMES_MISMATCH = 'Flight times mismatch',
}

export const RideStatusColor = {
    TO_CONFIRM: COLORS.MEDIUM_ORANGE,
    CONFIRMED: COLORS.LIGHT_BLUE,
    BOOKED: COLORS.LIGHT_BLUE,
    TO_CONFIRM_CHANGE: COLORS.MEDIUM_ORANGE,
    DRIVER_ON_THE_WAY: COLORS.LIGHT_GREEN,
    DRIVER_AT_PICKUP: COLORS.LIGHT_GREEN,
    PASSENGER_ON_BOARD: COLORS.LIGHT_GREEN,
    ACTIVE: COLORS.LIGHT_GREEN,
    TO_CLOSE: COLORS.MEDIUM_ORANGE,
    COMPLETED: COLORS.LIGHT_BLUE,
    TO_CONFIRM_CANCEL: COLORS.MEDIUM_ORANGE,
    CANCELLED: COLORS.BLACK_12,
} as {
    [key in keyof typeof RideStatus]: keyof typeof COLORS;
};

export const RideStatusTextColor = {
    TO_CONFIRM: COLORS.RED_WARNING,
    CONFIRMED: COLORS.BLACK,
    BOOKED: COLORS.BLACK,
    TO_CONFIRM_CHANGE: COLORS.RED_WARNING,
    DRIVER_ON_THE_WAY: COLORS.GREEN_TEXT,
    DRIVER_AT_PICKUP: COLORS.GREEN_TEXT,
    PASSENGER_ON_BOARD: COLORS.GREEN_TEXT,
    ACTIVE: COLORS.GREEN_TEXT,
    TO_CLOSE: COLORS.RED_WARNING,
    COMPLETED: COLORS.BLACK,
    TO_CONFIRM_CANCEL: COLORS.RED_WARNING,
    CANCELLED: COLORS.BLACK,
} as {
    [key in keyof typeof RideStatus]: keyof typeof COLORS;
};

export enum RideTrackingStatus {
    NOT_TRACKING = 'NOT_TRACKING',
    DRIVER_ON_THE_WAY = 'DRIVER_ON_THE_WAY',
    DRIVER_AT_PICKUP = 'DRIVER_AT_PICKUP',
    PASSENGER_ON_BOARD = 'PASSENGER_ON_BOARD',
    COMPLETE = 'COMPLETE',
}

export const useRideStatusMap = (): Map<string, string> => {
    const { t } = useTranslation();
    const rideStatusMap: Map<string, string> = new Map();

    for (const key in RideStatusFilter) {
        if (Object.prototype.hasOwnProperty.call(RideStatusFilter, key)) {
            rideStatusMap.set(key, t(RideStatusFilter[key as keyof typeof RideStatusFilter]));
        }
    }

    return rideStatusMap;
};

export const useRideStatusSupplierMap = () => {
    const { t } = useTranslation();
    const rideStatusMap: Map<string, string> = new Map([]);

    Object.keys(RideStatusSupplierFilter).map(value =>
        rideStatusMap.set(value, t(RideStatusSupplierFilter[value as keyof typeof RideStatusSupplierFilter]))
    );

    return rideStatusMap;
};

export const getRideStatusColor = (status: keyof typeof RideStatus) => RideStatusColor[status];

export const getRideStatusTextColor = (status: keyof typeof RideStatus) => RideStatusTextColor[status];

export const getRideStatusKey = (() => {
    const rideStatusKeyLookup: { [key: string]: keyof typeof RideStatus } = {};

    Object.entries(RideStatus).forEach(([key, value]) => {
        rideStatusKeyLookup[value] = key as keyof typeof RideStatus;
    });

    return (value: string): keyof typeof RideStatus => {
        return rideStatusKeyLookup[value];
    };
})();

export const getRideStatusOptionKey = (value: string): keyof typeof RideStatusOption => {
    return Object.entries(RideStatusOption).find(([_key, val]) => val === value)?.[0] as keyof typeof RideStatusOption;
};

interface RideStatusOptions {
    hasDriver: boolean;
    hasFlight: boolean;
    hasContact: boolean;
    isFlightMismatch: boolean;
    isOvernightFlight: boolean;
}

export const getRideStatusOptions = (statusOptions: RideStatusOptions): Array<keyof typeof RideStatusOption> => {
    const newOptions: Array<keyof typeof RideStatusOption> = [];

    if (statusOptions?.hasDriver) {
        newOptions.push(getRideStatusOptionKey(RideStatusOption.DRIVER_KNOWN));
    }

    if (!statusOptions?.hasFlight) {
        newOptions.push(getRideStatusOptionKey(RideStatusOption.NO_FLIGHT_INFORMATION));
    }

    if (statusOptions?.hasContact) {
        newOptions.push(getRideStatusOptionKey(RideStatusOption.CONTACT_GREETER_INFO_KNOWN));
    }

    if (statusOptions?.isFlightMismatch) {
        newOptions.push(getRideStatusOptionKey(RideStatusOption.FLIGHT_TIMES_MISMATCH));
    }

    if (statusOptions?.isOvernightFlight) {
        newOptions.push(getRideStatusOptionKey(RideStatusOption.OVERNIGHT_FLIGHT));
    }

    return newOptions;
};

export interface RidesFilterState {
    status: string[];
    dateRange: DateRange<Dayjs | null>;
}

const defaultFilters = {
    status: [RideStatusFilter.BOOKED, RideStatusFilter.ACTIVE],
    dateRange: [dayjs().subtract(1, 'days'), dayjs().add(6, 'M')],
} as RidesFilterState;

const defaultSupplierFilters = {
    status: [RideStatusSupplierFilter.TO_CONFIRM, RideStatusSupplierFilter.CONFIRMED, RideStatusSupplierFilter.TO_CLOSE],
    dateRange: [dayjs().subtract(1, 'days'), dayjs().add(6, 'M')],
} as RidesFilterState;

export const getCustomerRidesFilter = () => {
    const ridesFilter = localStorage.getItem(RIDES_FILTER);
    const ridesFilterParsed = ridesFilter ? (JSON.parse(ridesFilter) as RidesFilterState) : null;

    return {
        status: ridesFilterParsed?.status ?? defaultFilters.status,
        dateRange: ridesFilterParsed
            ? [dayjs(ridesFilterParsed?.dateRange[0]), dayjs(ridesFilterParsed?.dateRange[1])]
            : defaultFilters.dateRange,
    } as RidesFilterState;
};

export const resetCustomerRidesFilter = () => {
    localStorage.removeItem(RIDES_FILTER);
    const filters = getCustomerRidesFilter();
    const filtersParsed = JSON.stringify({ ...filters });

    localStorage.setItem(RIDES_FILTER, filtersParsed);

    return filters;
};

export const getSupplierRidesFilter = () => {
    const ridesFilter = localStorage.getItem(SUPPLIER_RIDES_FILTER);
    const ridesFilterParsed = ridesFilter ? (JSON.parse(ridesFilter) as RidesFilterState) : null;

    return {
        status: ridesFilterParsed?.status ?? defaultSupplierFilters.status,
        dateRange: ridesFilterParsed
            ? [dayjs(ridesFilterParsed?.dateRange[0]), dayjs(ridesFilterParsed?.dateRange[1])]
            : defaultSupplierFilters.dateRange,
    } as RidesFilterState;
};

export const resetSupplierRidesFilter = () => {
    localStorage.removeItem(SUPPLIER_RIDES_FILTER);
    const filters = getSupplierRidesFilter();
    const filtersParsed = JSON.stringify({ ...filters });

    localStorage.setItem(SUPPLIER_RIDES_FILTER, filtersParsed);

    return filters;
};
