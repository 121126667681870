import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { logAmplitudeEvent } from '../../amplitude/amplitude';
import ErrorPage from '../../components/errorPage/ErrorPage';
import { NOT_FOUND_PAGE } from '../../constants/amplitude/commonKeys';
import { notFoundIcon } from '../../public/assets/icons';

const NotFound = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const isPageOpened = useRef(false);

    if (!isPageOpened.current) {
        isPageOpened.current = true;
        logAmplitudeEvent(NOT_FOUND_PAGE);
    }

    return (
        <ErrorPage
            icon={notFoundIcon}
            header={t('errors.notFound.description')}
            description={t('errors.notFound.nextSteps')}
            buttonTitle={t('buttonName.backToHome')}
            onButtonClick={() => history.goBack()}
        />
    );
};

export default NotFound;
