/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spinner, ModalAction as Modal } from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { COLORS } from '../../../../constants/colors';
import { IMAGE_SIZE } from '../../../../constants/layout';
import { useDebounceEffect } from '../../../../hooks/useDebounceEffect';
import theme from '../../../../styles/theme';
import { canvasPreview, centerAspectCrop, resizeImageFile } from './helpers';

const useStyles = makeStyles(() => ({
    imageBox: (props: { width: string; height: string }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: props.width,
        height: props.height,
        borderRadius: '8px',
        border: `1px solid ${COLORS.DARK_GRAY}`,
        backgroundColor: COLORS.GREY_INPUT,
    }),
    loader: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        borderRadius: '8px',
    },
    imageCropBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 700,
        height: 500,
        borderRadius: '8px',
        border: `1px solid ${COLORS.DARK_GRAY}`,
        backgroundColor: COLORS.GREY_INPUT,
    },
    imageMobileCropBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 240,
        height: 300,
        borderRadius: '8px',
        border: `1px solid ${COLORS.DARK_GRAY}`,
        backgroundColor: COLORS.GREY_INPUT,
    },
}));

interface ImageUploaderProps {
    setFiles: Dispatch<SetStateAction<string[]>>;
    isLoading?: boolean;
    content?: JSX.Element;
    isDriverPage?: boolean;
    boxWidth?: string;
    boxHeight?: string;
    logPhotoInputEvent?: () => void;
    logPhotoDeleteEvent?: () => void;
    defaultImages?: string[];
    setImagesChange?: Dispatch<SetStateAction<string[]>>;
}

const aspect = 1 / 1;

const ImageUploader = ({
    setFiles,
    isLoading,
    content,
    isDriverPage,
    boxWidth,
    boxHeight,
    logPhotoInputEvent,
    logPhotoDeleteEvent,
    defaultImages,
    setImagesChange,
}: ImageUploaderProps) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const classes = useStyles({
        height: boxWidth || IMAGE_SIZE,
        width: boxHeight || IMAGE_SIZE,
    });

    const { t } = useTranslation();
    const [images, setImages] = useState<string[]>(defaultImages ?? []);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imgSrc, setImgSrc] = useState('');
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const imgRef = useRef<HTMLImageElement>(null);

    const handleFileSelect = async event => {
        setIsModalOpen(true);

        if (event.target.files && event.target.files.length > 0) {
            setCrop(undefined); // Makes crop preview update between images.
            const reader = new FileReader();

            reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));

            const image = isMobile ? await resizeImageFile(event.target.files[0]) : event.target.files[0];

            reader.readAsDataURL(image);
        }
    };

    function onImageLoad(event: React.SyntheticEvent<HTMLImageElement>) {
        const { width, height } = event.currentTarget;

        setCrop(centerAspectCrop(width, height, aspect));
        logPhotoInputEvent?.();
    }

    useDebounceEffect(
        () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                // New part for testing
                const canvasWidth = isMobile ? 240 : previewCanvasRef.current.width;
                const canvasHeight = isMobile ? 300 : previewCanvasRef.current.height;

                previewCanvasRef.current.width = canvasWidth;
                previewCanvasRef.current.height = canvasHeight;

                // eslint-disable-next-line no-void
                void canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
            }
        },
        100,
        [completedCrop]
    );

    const handleSubmitImageUpload = () => {
        const previewCanvas = previewCanvasRef.current;

        if (!previewCanvas) {
            console.error('Crop canvas does not exist');
            return;
        }

        // Check if the canvas has valid dimensions
        if (previewCanvas.width === 0 || previewCanvas.height === 0) {
            console.error('Canvas is empty or not rendered correctly');
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        previewCanvas.toBlob(async blob => {
            if (!blob) {
                console.error('Canvas is empty');
                return;
            }

            const image = await resizeImageFile(blob);
            const file = new File([image as any], new Date().toString(), { type: 'image/png' });

            const fileURL = URL.createObjectURL(file);

            console.log('bitno ', file);

            setImages(prevImages => [...prevImages, fileURL].slice(0, 3));
            setImagesChange?.(prevImages => [...prevImages, fileURL].slice(0, 3));
            setFiles(prevFiles => [...prevFiles, file as any].slice(0, 3) as string[]);

            setIsModalOpen(false);
        }, 'image/png');
    };

    const handleDelete = (index, indexForFiles) => {
        setImages(prevImages => prevImages.filter((_, idx) => idx !== index));
        setImagesChange?.(prevImages => prevImages.filter((_, idx) => idx !== index));

        if (isDriverPage) {
            setFiles(prevFiles => prevFiles.filter((_, idx) => idx !== index));
        } else {
            setFiles(prevFiles => prevFiles.filter((_, idx) => idx !== indexForFiles));
        }

        logPhotoDeleteEvent?.();
    };

    return (
        <Grid item xs={12}>
            {!isDriverPage && (
                <>
                    <Typography variant="body2" color={COLORS.BLUE} fontWeight={700}>
                        {t('photo')}
                    </Typography>
                    <Typography variant="body2" color={COLORS.SLATE_GREY}>
                        {t('photoGuidelines')}
                    </Typography>
                </>
            )}
            <Grid item xs={12} display="flex" maxWidth={isMobile ? '300px' : 'auto'} mt={2} overflow={'auto'}>
                {images.map((image, index) => (
                    <Box key={index} position="relative" mr={2}>
                        <img
                            src={image}
                            alt={`Upload ${index + 1}`}
                            className={classes.imageBox}
                            style={{ objectFit: 'cover' }}
                        />
                        {isLoading && (
                            <Box className={classes.loader}>
                                <Spinner size={24} />
                            </Box>
                        )}
                        <IconButton
                            size="small"
                            onClick={() => handleDelete(index, index + images.length)}
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                color: COLORS.WHITE,
                            }}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Box>
                ))}
                {(isDriverPage ? images.length < 1 : images.length < 3) && (
                    <Box component="label" className={classes.imageBox}>
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={evn => {
                                // eslint-disable-next-line no-void
                                void handleFileSelect(evn);
                            }}
                        />
                        {content ? content : <AddIcon sx={{ color: COLORS.SLATE_GREY }} />}
                    </Box>
                )}
            </Grid>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleSubmitImageUpload}
                title={t('pleaseCropImage')}
                confirmButtonLabel={t('crop')}
                maxWidth="md"
            >
                <div className={isMobile ? classes.imageMobileCropBox : classes.imageCropBox}>
                    {Boolean(imgSrc) && (
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={completeCrop => setCompletedCrop(completeCrop)}
                            aspect={aspect}
                            maxHeight={isMobile ? 300 : 500}
                            maxWidth={isMobile ? 300 : 500}
                            minHeight={300}
                            minWidth={300}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop"
                                src={imgSrc}
                                onLoad={onImageLoad}
                                style={{ maxHeight: isMobile ? '300px' : '500px' }}
                            />
                        </ReactCrop>
                    )}
                    {Boolean(completedCrop) && (
                        <div>
                            <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
                        </div>
                    )}
                </div>
            </Modal>
        </Grid>
    );
};

export default ImageUploader;
