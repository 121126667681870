import { TextField, ModalAction as Modal } from '@get-e/react-components';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Box, Checkbox, InputAdornment, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { COLORS } from '../../../constants/colors';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { InputError } from '../../../helpers/inputValidation/InputError';
import isEmail from '../../../helpers/inputValidation/validators/isEmail';
import { sendConfirmationEmail } from '../customer/api';

const useStyles = makeStyles({
    formField: {
        marginBottom: '1rem',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    multipleSelectItem: {
        width: '100% !important',
        padding: '0.5rem !important',
        '& .MuiListItemText-root': { paddingLeft: '0.5rem !important' },
    },
});

const EMAIL_LIST_SEPARATOR = ',';

export interface SendBookingEmailOptions {
    initialBookingConfirmationEmails: BookingConfirmationEmail[];
    extraEmails: string;
}

export interface BookingConfirmationEmail {
    email: string;
    isIncluded: boolean;
    id: string;
}

interface SendBookingErrors {
    [id: string]: null | string;
}

interface SendBookingConfirmationProps {
    onClose: () => void;
    isOpen: boolean;
    initialBookingConfirmationEmails: BookingConfirmationEmail[];
    rideId: string;
}

const SendBookingConfirmationModal = ({
    onClose,
    isOpen,
    initialBookingConfirmationEmails,
    rideId,
}: SendBookingConfirmationProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();

    const [bookingConfirmationEmails, setBookingConfirmationEmails] =
        useState<BookingConfirmationEmail[]>(initialBookingConfirmationEmails);

    const [extraEmails, setExtraEmails] = useState('');

    const [extraEmailsErrors, setExtraEmailsErrors] = useState<InputError | null>(null);

    const ids: string[] = initialBookingConfirmationEmails.map(traveller => traveller.id);

    const initialFormErrors: SendBookingErrors = ids.reduce<SendBookingErrors>((errors, id) => {
        errors[id] = null;
        return errors;
    }, {});

    const [formErrors, setFormErrors] = useState<SendBookingErrors>(initialFormErrors);

    const validateFields = (): boolean => {
        let hasError = false;

        if (extraEmails !== '') {
            const emailsList = extraEmails.split(EMAIL_LIST_SEPARATOR).map(element => element.trim());

            const validatesExtraEmails = emailsList.map(email => {
                return isEmail(email, InputError.InvalidEmail);
            });

            for (const validated of validatesExtraEmails) {
                if (!validated.isValid) {
                    setExtraEmailsErrors(InputError.InvalidEmail);
                    hasError = true;
                }
            }
        }

        setFormErrors(prevFormErrors => {
            const updatedFormErrors = { ...prevFormErrors };

            for (const traveller of bookingConfirmationEmails) {
                if (isEmail(traveller.email, InputError.InvalidEmail).isValid) {
                    updatedFormErrors[traveller.id] = null;
                } else {
                    updatedFormErrors[traveller.id] = InputError.InvalidEmail;
                    hasError = true;
                }
            }

            return updatedFormErrors;
        });

        return !hasError;
    };

    const { mutate: sendConfirmationEmailMutation, isLoading } = useMutation(sendConfirmationEmail, {
        onSuccess: () => {
            showNotification(t('alert.edit.successSendEmail'), Severity.Info);
            onClose();
        },
        onError: (error: Error) => {
            showNotification(t('alert.edit.error'), Severity.Error);
        },
    });

    const handleSubmit = (): void => {
        if (!validateFields()) {
            return;
        }

        const includedEmails: string[] = bookingConfirmationEmails
            .filter(traveller => traveller.isIncluded)
            .map(traveller => traveller.email);

        const emailTo = includedEmails.join(', ').concat(extraEmails ? ` , ${extraEmails}` : '');

        emailTo &&
            sendConfirmationEmailMutation({
                id: rideId ?? '',
                emailTo,
            });
    };

    const handleChange = (id: string, field: keyof BookingConfirmationEmail, value: string | boolean) => {
        const updatedValues = bookingConfirmationEmails.map(traveller => {
            if (traveller.id === id) {
                return {
                    ...traveller,
                    [field]: value,
                };
            }

            if (field === 'isIncluded' && traveller.id === id) {
                return {
                    ...traveller,
                    [field]: !traveller.isIncluded,
                };
            }

            return traveller;
        });

        setBookingConfirmationEmails(updatedValues);
        setFormErrors(prevFormErrors => {
            const updatedFormErrors = { ...prevFormErrors };

            for (const traveller of bookingConfirmationEmails) {
                if (traveller.id === id) {
                    updatedFormErrors[traveller.id] = null;
                }
            }

            return updatedFormErrors;
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title={t('pages.rides.sendBookingConfirmation')}
            confirmButtonLabel={t('buttonName.send')}
            cancelButtonLabel={t('buttonName.back')}
            isDisabled={isLoading}
            maxWidth="lg"
        >
            {bookingConfirmationEmails.length > 0 && (
                <Typography
                    style={{
                        color: COLORS.BLUE_DARK,
                        fontSize: '1rem',
                        fontWeight: 700,
                        marginBottom: '1rem',
                    }}
                >
                    {t('pages.singleRide.emailAddressesCheckToInclude')}
                </Typography>
            )}
            {bookingConfirmationEmails?.map(traveller => (
                <Box display="flex" flexDirection="row" alignItems="baseline" key={traveller.id}>
                    <TextField
                        key={traveller.id}
                        className={classes.formField}
                        error={formErrors[traveller.id] !== null}
                        helperText={formErrors[traveller.id] ? t(formErrors[traveller.id] ?? '') : null}
                        label={t('email')}
                        name="email"
                        onChange={event => handleChange(traveller.id, 'email', event.target.value)}
                        required
                        autoComplete="off"
                        value={traveller.email}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EmailOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Tooltip title={t(traveller.isIncluded ? 'included' : 'notIncluded')}>
                        <Checkbox
                            style={{ paddingRight: 0 }}
                            checked={traveller.isIncluded}
                            onChange={event => handleChange(traveller.id, 'isIncluded', event.target.checked)}
                        />
                    </Tooltip>
                </Box>
            ))}

            <Typography
                style={{
                    color: COLORS.BLUE_DARK,
                    fontSize: '1rem',
                    fontWeight: 700,
                    marginBottom: '1rem',
                }}
            >
                {t('pages.rides.extraEmailAddress')}
            </Typography>
            <TextField
                className={classes.formField}
                error={extraEmailsErrors !== null}
                helperText={extraEmailsErrors ? t(extraEmailsErrors) : null}
                label={t('pages.rides.commaSeparated')}
                name="email"
                onChange={event => setExtraEmails(event.target.value)}
                autoComplete="off"
                value={extraEmails}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <EmailOutlinedIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </Modal>
    );
};

export default SendBookingConfirmationModal;
