import { Grid, LinearProgress } from '@mui/material';
import React, { FunctionComponent } from 'react';

import largeIcon from '../../public/assets/images/get-e-logo-large.png';
import containerStyles from '../../styles/Container';

const Loading: FunctionComponent<{ framed: boolean }> = ({ framed }) => {
    const classes = containerStyles();

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="center"
            className={framed ? classes.framedFullHeight : classes.fullHeight}
            data-testid="loading-page"
        >
            <Grid item xs={10}>
                <img
                    src={largeIcon}
                    alt="Loading Get-E portal"
                    style={{
                        maxWidth: '200px',
                        maxHeight: '47px',
                        margin: '0 auto',
                        display: 'block',
                    }}
                />
            </Grid>
            <Grid item xs={10}>
                <LinearProgress
                    style={{
                        width: '200px',
                        margin: '1em auto',
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default Loading;
