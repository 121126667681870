import { FullScreenLoader, NoResultsDataGrid as NoResults } from '@get-e/react-components';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../constants/colors';
import theme from '../../styles/theme';
import { DocumentItem, FileItem } from './api/types';
import { useDocuments } from './api/useDocument';
import { useFiles } from './api/useFiles';
import DocumentRow from './components/DocumentRow';

const useStyles = makeStyles({
    row: {
        padding: '.75rem',
        cursor: 'pointer',
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '&:hover': { backgroundColor: COLORS.LIGHT_GRAY },
    },
    heading: {
        marginBottom: '2rem',
        width: '100%',
    },
    tableHeaderLabel: {
        display: 'flex',
        alignItems: 'center',
        color: COLORS.BLUE_DARK,
        fontWeight: '700',
        height: '56px',
        borderBottom: `1px solid ${COLORS.BLUE_DARK}`,
    },
});

const Documents = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const [data, setData] = useState<Array<DocumentItem | FileItem>>([]);

    const { data: documents, isLoading: isLoadingDocuments } = useDocuments();
    const { data: files, isLoading: isLoadingFiles } = useFiles();

    useEffect(() => {
        if (!isLoadingDocuments && !isLoadingFiles) {
            setData([...documents, ...files]);
        }
    }, [documents, files, isLoadingDocuments, isLoadingFiles]);

    if (isLoadingDocuments || isLoadingFiles) {
        return <FullScreenLoader />;
    }

    return (
        <>
            <Grid container alignItems="flex-start" padding={isMobile ? '2rem 1rem' : '0 2rem 2rem 2rem'}>
                <Typography
                    style={{
                        color: COLORS.BLUE,
                        fontSize: '1.5rem',
                        fontWeight: 700,
                        paddingRight: '1rem',
                        marginBottom: isMobile ? '1rem' : '2rem',
                    }}
                >
                    {t('documents')}
                </Typography>
                {data.length > 0 ? (
                    <Grid item width="100%">
                        <Typography className={classes.tableHeaderLabel}>{t('name')}</Typography>
                        {data.map(item => (
                            <DocumentRow key={`${item.label}-${item.id}`} item={item} />
                        ))}
                    </Grid>
                ) : (
                    <Box width="100%" height="calc(100vh - 105px)" display="flex" justifyContent="center">
                        <NoResults text={t('errors.noDocuments.title')} description={t('errors.noDocuments.description')} />
                    </Box>
                )}
            </Grid>
        </>
    );
};

export default Documents;
