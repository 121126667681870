import { BaseError } from 'make-error-cause';
import { useQuery } from 'react-query';

import { USE_PAST_STOP_SALES_RULES, USE_STOP_SALES_RULE, USE_STOP_SALES_RULES } from '../../../constants/queryKeys';
import tryGetAsync from '../../../helpers/tryGetAsync';
import apiClient from '../../../services/api';
import { getPastStopSalesRulesRoute, getStopSalesRuleRoute, getStopSalesRulesRoute } from '../../../services/routes';
import { BlockedPeriod, BlockedPeriodRequest } from './types';

export const useStopSalesRule = (accountId: string, ruleId: string, enabled: boolean) => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery(
        [USE_STOP_SALES_RULE],
        () => getStopSalesRule(accountId, ruleId),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            enabled,
        }
    );

    return {
        data,
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

export const useCurrentStopSalesRules = (accountId: string, enabled: boolean) => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery(
        [USE_STOP_SALES_RULES],
        () => getCurrentStopSalesRules(accountId),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            enabled,
        }
    );

    return {
        data: data ?? [],
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

export const usePastStopSalesRules = (accountId: string, enabled: boolean) => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery(
        [USE_PAST_STOP_SALES_RULES],
        () => getPastStopSalesRules(accountId),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            enabled,
        }
    );

    return {
        data: data ?? [],
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

const getStopSalesRule = async (accountId: string, ruleId: string): Promise<BlockedPeriod> => {
    const { data } = await apiClient.get<BlockedPeriod>(getStopSalesRuleRoute(accountId, ruleId));

    return data;
};

const getCurrentStopSalesRules = async (accountId: string): Promise<BlockedPeriod[]> => {
    const { data } = await apiClient.get<BlockedPeriod[]>(getStopSalesRulesRoute(accountId));

    return data;
};

const getPastStopSalesRules = async (accountId: string): Promise<BlockedPeriod[]> => {
    const { data } = await apiClient.get<BlockedPeriod[]>(getPastStopSalesRulesRoute(accountId));

    return data;
};

export const editStopSalesRule = async (accountId: string, ruleId: string, payload: BlockedPeriodRequest) => {
    const result = await tryGetAsync(() => apiClient.put(getStopSalesRuleRoute(accountId, ruleId), payload));

    if (!result.success) {
        throw new BaseError('Failed to edit blocked period');
    }
};

export const createStopSalesRule = async (accountId: string, payload: BlockedPeriodRequest) => {
    const result = await tryGetAsync(() => apiClient.post(getStopSalesRulesRoute(accountId), payload));

    if (!result.success) {
        throw new BaseError('Failed to create blocked period');
    }
};

export const deleteStopSalesRule = async (accountId: string, ruleId: string) => {
    const result = await tryGetAsync(() => apiClient.delete(getStopSalesRuleRoute(accountId, ruleId)));

    if (!result.success) {
        throw new BaseError('Failed to delete blocked period');
    }
};
