import { AccountType, PrimaryTimeZone } from '../../../services/types';

export enum UserRoles {
    MANAGE_USERS = 'manageUsers',
    MANAGE_RIDES_BOOKED_BY_OTHERS = 'manageRidesBookedByOthers',
    SEE_INVOICES = 'seeInvoices',
}

export type UserPermissions = { [key in UserRoles]: boolean };

export enum AgentVerticle {
    AIRLINE = 'AIRLINE',
    CORPORATE = 'CORPORATE',
    ASSISTANCE = 'ASSISTANCE',
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    joinedAt: string;
    lastSeen: string;
    isDeactivated: boolean;
    accountId: number;
    accountType: AccountType;
    accountManager?: string;
    primaryTimeZone: PrimaryTimeZone;
    permissions: UserPermissions;
    agentTier?: number;
    agentVerticle?: AgentVerticle;
    accountEmail?: string;
    accountName?: string;
    organizationId?: number;
}

export type EditUserPermissions = Omit<UserPermissions, UserRoles.SEE_INVOICES>;

export interface EditUserRequest {
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    password_confirmation?: string;
    isDeactivated: boolean;
    permissions: EditUserPermissions;
}

export interface InviteUserRequest {
    firstName: string;
    lastName: string;
    email: string;
    permissions: Pick<UserPermissions, UserRoles.MANAGE_USERS>;
}

export interface UserProfileRequest {
    email: string;
    firstName: string;
    lastName: string;
    password?: string;
    password_confirmation?: string;
}

export interface ErrorResponse {
    errors: Record<string, []>;
    message: string;
}
