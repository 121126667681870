import { Dispatch, SetStateAction, useState } from 'react';

import { InputError } from '../../../../helpers/inputValidation/InputError';
import allValid from '../../../../helpers/inputValidation/validators/allValid';
import isFilledString from '../../../../helpers/inputValidation/validators/isFilledString';
import {
    initialReference,
    initialReferenceErrors,
    ReferenceField,
    ReferenceError,
} from '../../components/updateRide/ChangeReferences';

const useReferences = (): {
    referencesValues: ReferenceField;
    setReferencesValues: Dispatch<SetStateAction<ReferenceField>>;
    referencesErrors: ReferenceError;
    setReferencesErrors: Dispatch<SetStateAction<ReferenceError>>;
    validateReferencesFields: () => boolean;
} => {
    const [errors, setErrors] = useState<ReferenceError>(initialReferenceErrors);
    const [referencesValues, setReferencesValues] = useState<ReferenceField>(initialReference);

    const validateFields = () => {
        const validatedCustomFields = {};
        const validatedCustomFieldsErrors = {};

        referencesValues.customFields.forEach(customField => {
            const validation = isFilledString(customField.value, InputError.Empty);

            if (customField.isRequired) {
                validatedCustomFields[customField.key] = validation;
                validatedCustomFieldsErrors[customField.key] = validation.isValid ? null : validation.error;
            }
        });

        if (!allValid(validatedCustomFields)) {
            const fieldErrors = { customFields: validatedCustomFieldsErrors };

            setErrors(fieldErrors);
            return false;
        }

        return true;
    };

    return {
        referencesValues,
        setReferencesValues,
        referencesErrors: errors,
        setReferencesErrors: setErrors,
        validateReferencesFields: validateFields,
    };
};

export default useReferences;
