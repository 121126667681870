import { IconedData } from '@get-e/react-components';
import { AirlineSeatReclineExtra, Work, Check } from '@mui/icons-material';
import { Grid, Typography, Box, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import { calculateCancelledPrice } from '../../../../helpers/calculateCancelledPrice';
import theme from '../../../../styles/theme';
import { RideResponse } from '../../api/types';
import { Vehicle } from '../../components/updateRide/VehicleOptions';
import withExpandable from '../../components/withExpandable';

const useStyles = makeStyles({
    image: {
        height: '62px',
        width: '182px',
    },
    headingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    heading: {
        color: COLORS.BLUE,
        fontSize: '1rem',
        fontWeight: 700,
        marginBottom: 0,
    },
    cardContainer: {
        width: '100%',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '0.5rem',
        marginTop: '1rem',
        padding: '1rem',
        position: 'relative',
    },
});

const SelectIcon = () => {
    return (
        <Box
            style={{
                borderRadius: '50%',
                maxWidth: '30px',
                maxHeight: '30px',
                background: COLORS.GREEN,
                padding: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '1rem',
                right: '1rem',
                boxShadow: ' 0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
            }}
        >
            <Check
                sx={{
                    color: COLORS.WHITE,
                    width: '20px',
                    height: '20px',
                }}
            />
        </Box>
    );
};

interface SelectedVehicleCardProps {
    data?: RideResponse;
    isSelectedHeaderVisible?: boolean;
    isSelected?: boolean;
    onCardClick?: () => void;
    vehicle?: Vehicle;
    isPassengerOverMax?: boolean;
    renderChevrons: JSX.Element;
    isExpanded: boolean;
}

const SelectedVehicleCard = ({
    data,
    isSelectedHeaderVisible,
    isSelected,
    onCardClick,
    vehicle,
    isPassengerOverMax,
    renderChevrons,
    isExpanded,
}: SelectedVehicleCardProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container className={classes.cardContainer} onClick={onCardClick}>
            {isSelectedHeaderVisible && (
                <Grid className={classes.headingContainer}>
                    <Typography variant="h3" component="h4" className={classes.heading}>
                        {t('pages.singleRide.selectedVehicle.title')}
                    </Typography>
                    {renderChevrons}
                </Grid>
            )}
            {isExpanded && (
                <Grid
                    sx={{
                        padding: 0,
                        marginTop: isExpanded ? '1rem' : '0',
                    }}
                >
                    <Grid container flex="row" justifyContent="space-between">
                        <Grid item xs={6} lg={6} md={6}>
                            <Typography
                                sx={{
                                    color: COLORS.BLACK,
                                    paddingBottom: '.6rem',
                                    fontWeight: 600,
                                }}
                            >
                                {vehicle?.vehicleClass
                                    ? `${vehicle?.vehicleClass} ${vehicle?.vehicleType}`
                                    : `${data?.vehicle?.class} ${data?.vehicle?.type}`}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '1rem',
                                }}
                            >
                                <IconedData
                                    dataTextBold
                                    icon={AirlineSeatReclineExtra}
                                    data={vehicle?.travellers || data?.vehicle?.seats}
                                    dataLabelColor={COLORS.BLACK}
                                    iconRightMargin="0"
                                    dataColor={COLORS.SLATE_GREY}
                                />
                                <IconedData
                                    dataTextBold
                                    icon={Work}
                                    data={vehicle?.luggages || data?.vehicle?.luggage}
                                    dataLabelColor={COLORS.BLACK}
                                    iconRightMargin="0.5rem"
                                    dataColor={COLORS.SLATE_GREY}
                                />
                            </Box>
                            {isPassengerOverMax && (
                                <Typography
                                    style={{
                                        color: COLORS.ORANGE_WARNING,
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                    }}
                                >
                                    {t('pages.rides.maxCapacity')}
                                </Typography>
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: '2rem',
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    style={{
                                        color: COLORS.BLACK,
                                        fontWeight: 700,
                                        fontSize: '1rem',
                                    }}
                                >
                                    {vehicle?.priceCurrency ? `${vehicle?.priceCurrency} ` : `${data?.price?.isoCurrency} `}
                                    {data ? calculateCancelledPrice(data) : vehicle?.price.toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} lg={6} md={6} display="flex" justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
                            <img src={vehicle?.vehicleImage || data?.vehicle?.imageUrl} alt="Vehicle" className={classes.image} />
                        </Grid>
                        <Typography
                            variant="caption"
                            style={{
                                color: COLORS.SLATE_GREY,
                                lineHeight: '.9rem',
                                paddingTop: '.5rem',
                            }}
                        >
                            {data && calculateCancelledPrice(data) !== data?.price?.amount?.toFixed(2)
                                ? t('pages.singleRide.priceAfterCancellation')
                                : t('pages.singleRide.priceDescription')}
                        </Typography>
                        {isSelected && SelectIcon()}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default withExpandable(SelectedVehicleCard);
