import { RideStatus } from '../../../../helpers/rideStatusUtil';

export enum RideChangeStatusKey {
    CONFIRM_RIDE,
    CLOSE_RIDE,
    DOWNLOAD_RIDE_ORDER,
    REFERENCES_AND_REMARKS,
    ADD_DRIVER,
    UNASSIGN_DRIVER,
    CHANGE_DRIVER,
    DRIVER_AND_VEHICLE,
}

export interface RideChangeStatusOptionsMap {
    mainButton: keyof typeof RideChangeStatusKey;
    options: Array<keyof typeof RideChangeStatusKey>;
}

export const rideChangeStatusLabel: Record<keyof typeof RideChangeStatusKey, string> = {
    CONFIRM_RIDE: 'buttonName.confirm',
    CLOSE_RIDE: 'buttonName.close',
    DOWNLOAD_RIDE_ORDER: 'pages.rides.downloadRideOrder',
    REFERENCES_AND_REMARKS: 'pages.rides.reference&remarks',
    ADD_DRIVER: 'buttonName.addDriver',
    UNASSIGN_DRIVER: 'pages.rides.unassignDriver',
    CHANGE_DRIVER: 'buttonName.changeDriver',
    DRIVER_AND_VEHICLE: 'modals.driverAndVehicle.title',
};

export const rideChangeStatusTooltip: Record<keyof typeof RideChangeStatusKey, string | null> = {
    CONFIRM_RIDE: 'pages.rides.changeStatusConfirm',
    CLOSE_RIDE: 'pages.rides.changeStatusToClose',
    DOWNLOAD_RIDE_ORDER: null,
    REFERENCES_AND_REMARKS: null,
    ADD_DRIVER: 'pages.rides.changeStatusConfirmNoDriver',
    UNASSIGN_DRIVER: null,
    CHANGE_DRIVER: 'pages.rides.changeStatusConfirmWithDriver',
    DRIVER_AND_VEHICLE: null,
};

export const getRideChangeStatusOptionsMap = (
    status: keyof typeof RideStatus,
    isDriverAssigned: boolean
): RideChangeStatusOptionsMap | null => {
    switch (status) {
        case 'TO_CONFIRM':
        case 'TO_CONFIRM_CHANGE':
            return {
                mainButton: 'CONFIRM_RIDE',
                options: ['DOWNLOAD_RIDE_ORDER', 'DRIVER_AND_VEHICLE'],
            };
        case 'CONFIRMED':
            return {
                mainButton: isDriverAssigned ? 'CHANGE_DRIVER' : 'ADD_DRIVER',
                options: isDriverAssigned
                    ? ['UNASSIGN_DRIVER', 'REFERENCES_AND_REMARKS', 'DOWNLOAD_RIDE_ORDER']
                    : ['REFERENCES_AND_REMARKS', 'DOWNLOAD_RIDE_ORDER'],
            };
        case 'DRIVER_ON_THE_WAY':
        case 'DRIVER_AT_PICKUP':
        case 'PASSENGER_ON_BOARD':
        case 'ACTIVE':
            return {
                mainButton: isDriverAssigned ? 'CHANGE_DRIVER' : 'ADD_DRIVER',
                options: isDriverAssigned
                    ? ['UNASSIGN_DRIVER', 'REFERENCES_AND_REMARKS', 'DOWNLOAD_RIDE_ORDER']
                    : ['REFERENCES_AND_REMARKS', 'DOWNLOAD_RIDE_ORDER'],
            };
        case 'TO_CLOSE':
            return {
                mainButton: 'CLOSE_RIDE',
                options: ['REFERENCES_AND_REMARKS', 'DOWNLOAD_RIDE_ORDER'],
            };
        default:
            return null;
    }
};

export const getRideChangeStatusOptionsMapNew = (status: keyof typeof RideStatus): RideChangeStatusOptionsMap | null => {
    switch (status) {
        case 'TO_CONFIRM':
            return {
                mainButton: 'CONFIRM_RIDE',
                options: ['DOWNLOAD_RIDE_ORDER', 'DRIVER_AND_VEHICLE'],
            };
        case 'TO_CONFIRM_CHANGE':
            return {
                mainButton: 'CONFIRM_RIDE',
                options: ['DOWNLOAD_RIDE_ORDER', 'DRIVER_AND_VEHICLE', 'REFERENCES_AND_REMARKS'],
            };
        case 'CONFIRMED':
        case 'DRIVER_ON_THE_WAY':
        case 'DRIVER_AT_PICKUP':
        case 'PASSENGER_ON_BOARD':
        case 'ACTIVE':
            return {
                mainButton: 'DRIVER_AND_VEHICLE',
                options: ['REFERENCES_AND_REMARKS', 'DOWNLOAD_RIDE_ORDER'],
            };
        case 'TO_CLOSE':
            return {
                mainButton: 'CLOSE_RIDE',
                options: ['REFERENCES_AND_REMARKS', 'DOWNLOAD_RIDE_ORDER'],
            };
        default:
            return null;
    }
};
