export const getPercentageChange = (currentPercentage: number, previousPercentage: number, decimals = 2) => {
    if (previousPercentage === 0) {
        return currentPercentage;
    }

    if (currentPercentage === 0) {
        return -previousPercentage;
    }

    const increase = currentPercentage - previousPercentage;
    const changePercentage = increase / previousPercentage;

    return Number((changePercentage * 100).toFixed(decimals));
};
