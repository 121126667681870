import { useMemo } from 'react';
import { getPercentage } from '../../../../helpers/getPercentage';
import { QualityReport } from '../api/types';

interface DriversAddedPercentage {
    driversAddedCurrentPeriodPercentage: string;
    driversAddedPreviousPeriodPercentage: string;
}

export function useDriversAddedPercentage(
    currentPeriod: QualityReport | undefined,
    pastPeriod: QualityReport | undefined
): DriversAddedPercentage {
    const driversAddedCurrentPeriodPercentage = useMemo(() => {
        return getPercentage(
            currentPeriod?.summary.totalTripsWithDriversAdded ?? 0,
            currentPeriod?.summary.totalRides ?? 0,
            0
        ).toString();
    }, [currentPeriod]);

    const driversAddedPreviousPeriodPercentage = useMemo(() => {
        return getPercentage(
            pastPeriod?.summary.totalTripsWithDriversAdded ?? 0,
            pastPeriod?.summary.totalRides ?? 0,
            0
        ).toString();
    }, [pastPeriod]);

    return {
        driversAddedCurrentPeriodPercentage,
        driversAddedPreviousPeriodPercentage,
    };
}
