import { PortalLocale } from '../context/LocaleContext';

export type DayjsLocale = 'en' | 'es';

const findDayjsLocale = (value: PortalLocale): DayjsLocale => {
    switch (value) {
        case 'en-GB':
            return 'en';
        case 'es-ES':
            return 'es';
        default:
            console.log(`${value as string} is unsupported`);
            return 'en';
    }
};

export default findDayjsLocale;
