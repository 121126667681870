import { Grid, useMediaQuery } from '@mui/material';
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_FIELD, GridColDef } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getPercentage } from '../../../../helpers/getPercentage';

import useDataGridStyles from '../../../../styles/DataGrid';
import theme from '../../../../styles/theme';
import { ReportTabProps } from '../api/types';
import { useSituationsColumns } from '../hooks/useSituationsColumns';
import { useSituationsDetailsContent } from '../hooks/useSituationsDetailsContent';
import SummaryBasicCard from '../SummaryBasicCard';
import SummaryListCard from '../SummaryListCard';

const SituationsTab = ({ currentPeriod, pastPeriod, period }: ReportTabProps) => {
    const { t } = useTranslation();
    const dataGridClases = useDataGridStyles();
    const columns = useSituationsColumns();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const sortedSituations = useMemo(
        () =>
            currentPeriod?.situations
                .sort((sortA, sortB) => sortB.pickupTime.getTime() - sortA.pickupTime.getTime())
                .map(item => ({
                    ...item,
                    id: item.rideNumber,
                })),
        [currentPeriod?.situations]
    );

    const columnVisibilityModel = useMemo(() => {
        return {
            rideNumber: !isMobile,
            driver: !isMobile,
            [GRID_DETAIL_PANEL_TOGGLE_FIELD]: isMobile,
        };
    }, [isMobile]);

    return (
        <Grid container width="100%">
            <Grid container columnSpacing={3} marginTop={2}>
                <Grid item xs={12} md={4} lg={4}>
                    <SummaryBasicCard
                        title={t('pages.reports.rateOfSituations')}
                        percentage1={getPercentage(
                            currentPeriod?.summary.situations.totalSituations ?? 0,
                            currentPeriod?.summary.totalRides ?? 0
                        ).toString()}
                        text1={`${getPercentage(
                            pastPeriod?.summary.situations.totalSituations ?? 0,
                            pastPeriod?.summary.totalRides ?? 0
                        ).toString()}% ${t('inPreviousPeriod')}`}
                        text2={period}
                        titleTooltip={t('pages.reports.rateOfSituationsTooltip')}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <SummaryListCard
                        title={t('pages.reports.situationsByType')}
                        driverLate={currentPeriod?.summary.situations.totalDriverLate}
                        driverNoShow={currentPeriod?.summary.situations.totalDriverNoShow}
                        vehicleCondition={currentPeriod?.summary.situations.totalVehicleCondition}
                        serviceQuality={currentPeriod?.summary.situations.totalServiceQuality}
                        safetyIncident={currentPeriod?.summary.situations.totalSafetyIncident}
                        onRouteDelay={currentPeriod?.summary.situations.totalOnRouteDelay}
                        wrongVehicle={currentPeriod?.summary.situations.totalWrongVehicle}
                        other={currentPeriod?.summary.situations.totalOther}
                    />
                </Grid>
            </Grid>
            <DataGridPro
                className={dataGridClases.dataGrid}
                autoHeight
                hideFooter
                disableColumnSelector
                disableColumnFilter
                rows={sortedSituations ?? []}
                columns={columns as GridColDef[]}
                getDetailPanelContent={useSituationsDetailsContent()}
                getDetailPanelHeight={() => 'auto'}
                columnVisibilityModel={columnVisibilityModel}
            />
        </Grid>
    );
};

export default SituationsTab;
