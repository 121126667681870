import config from '../config';
import { AssignDriverToRide } from '../pages/rides/supplier/api/types';
import { FileType } from './types';

const ROUTE_PREFIX = 'portal-api/';

export const CSRF_COOKIE = '/sanctum/csrf-cookie';

export const LOGOUT = `${ROUTE_PREFIX}logout`;

export const LOGIN = `${ROUTE_PREFIX}login`;

const RIDE_ID_PARAM = 'unid';

const ACCOUNT_ID_PARAM = 'accountId';

const AIRLINE_IATA_IMAGE_BASE_URL = 'https://pics.avs.io';

export const USERS = `${ROUTE_PREFIX}users`;

export const INVITED_USERS = `${ROUTE_PREFIX}invited-users`;

export const USER_PROFILE = `${ROUTE_PREFIX}users/profile`;

export const USER_INVITE = `${ROUTE_PREFIX}users/invite`;

export const ACCEPT_INVITE = `${ROUTE_PREFIX}users/accept-invite`;

export const FORGOT_PASSWORD = `${ROUTE_PREFIX}forgot-password`;

export const RESET_PASSWORD = `${ROUTE_PREFIX}reset-password`;

export const RIDES = `${ROUTE_PREFIX}trips`;

export const RIDE = `${ROUTE_PREFIX}trips/${RIDE_ID_PARAM}`;

export const RIDE_CHANGE_INFORMATION = `${RIDE}/change-information`;

export const RIDE_CHANGE_PASSENGERS = `${RIDE}/change-passengers`;

export const RIDE_AVAILABILITIES = `${ROUTE_PREFIX}availabilities`;

export const SEARCH_LOCATION = `${ROUTE_PREFIX}locations/search`;

export const RIDE_SWAP_ESTIMATE_OPTION = `${RIDES}/${RIDE_ID_PARAM}/swap-estimate-option`;

export const DOWNLOAD_BOOKING_CONFIRMATION = `${RIDE}/get-booking-confirmation-pdf`;
export const DOWNLOAD_RIDE_ORDER = `${RIDE}/get-order-pdf`;

export const DOWNLOAD_RECEIPT_ROUTE = `${RIDE}/get-receipt-pdf`;

export const SEARCH_FLIGHT = `${ROUTE_PREFIX}flights/search`;

export const GET_FLIGHT_INFO = `${RIDES}/${RIDE_ID_PARAM}/flights/show`;

export const EXPORT_RIDES_TO_EXCEL = `${RIDES}/export-to-excel`;

const REPORTS = 'modules/quality-reports/';

export const SUMMARY_REPORT = `${REPORTS}summary/supplier`;

export const DRIVERS_REPORT = `${REPORTS}driver-analysis/supplier`;

export const SITUATIONS_REPORT = `${REPORTS}situation-details/supplier`;

export const REVIEWS_REPORT = `${REPORTS}reviews/supplier`;

export const STOP_SALES_RULE = `/api/internal/accounts/${ACCOUNT_ID_PARAM}/stop-sales-rules`;

export const DRIVERS = `${ROUTE_PREFIX}drivers`;
export const DRIVERS_SEARCH = `${ROUTE_PREFIX}drivers-search`;

export const VEHICLES = `${ROUTE_PREFIX}vehicles`;
export const VEHICLE_BRANDS = `${ROUTE_PREFIX}vehicle-brands`;
export const VEHICLE_MODELS = `${ROUTE_PREFIX}vehicle-models`;

export const PUSHER_AUTH = 'portal/pusher/auth';

export const DOCUMENTS = `${ROUTE_PREFIX}account-documents`;
export const FILES = `${ROUTE_PREFIX}account-files`;

export const BOOKING_TOOL_URL = `${ROUTE_PREFIX}booking-tool-url`;

export const ERROR_TOO_MANY_REQUESTS = '/too-many-requests';
export const ERROR_NOT_AUTHORIZED = '/not-authorized';
export const ERROR_NOT_FOUND = '/not-found';

export const getSupplierInvoicesRoute = (page: number) => `${ROUTE_PREFIX}supplier-invoices?page=${page}`;

export const getUsersRoute = (isDeactivated: boolean) => `${USERS}?isDeactivated=${isDeactivated}`;

export const getRideRoute = (id: string) => RIDE.replace(RIDE_ID_PARAM, id);

export const getCancelRideRoute = (id: string) => `${RIDE.replace(RIDE_ID_PARAM, id)}/cancel`;

export const getUpdateRideInformation = (id: string) => RIDE_CHANGE_INFORMATION.replace(RIDE_ID_PARAM, id);

export const getUpdateTravellerInformation = (id: string) => RIDE_CHANGE_PASSENGERS.replace(RIDE_ID_PARAM, id);

export const getUpdateVehicleRideRoute = (id: string) => RIDE_SWAP_ESTIMATE_OPTION.replace(RIDE_ID_PARAM, id);

export const getFlightInfoRoute = (id: string) => GET_FLIGHT_INFO.replace(RIDE_ID_PARAM, id);

export const getUpdateFlightNumberRoute = (id: string) => `${RIDE.replace(RIDE_ID_PARAM, id)}/change-flight`;

export const getSendBookingConfirmationEmailRoute = (id: string) =>
    `${RIDE.replace(RIDE_ID_PARAM, id)}/send-booking-confirmation-email`;

export const getDownloadBookingConfirmationRoute = (id: string) => `${DOWNLOAD_BOOKING_CONFIRMATION.replace(RIDE_ID_PARAM, id)}`;

export const getDownloadReceiptRoute = (id: string) => `${DOWNLOAD_RECEIPT_ROUTE.replace(RIDE_ID_PARAM, id)}`;

export const getStopSalesRulesRoute = (accountId: string) => `${STOP_SALES_RULE.replace(ACCOUNT_ID_PARAM, accountId)}`;

export const getPastStopSalesRulesRoute = (accountId: string) =>
    `${STOP_SALES_RULE.replace(ACCOUNT_ID_PARAM, accountId)}?withPastPeriods=true`;

export const getStopSalesRuleRoute = (accountId: string, ruleId: string) =>
    `${STOP_SALES_RULE.replace(ACCOUNT_ID_PARAM, accountId)}/${ruleId}`;

export const getDriverRoute = (id: string) => `${DRIVERS}/${id}`;

export const getDriverVehiclesRoute = (id: number) => `${DRIVERS}/${id}/vehicles`;

export const getRideVehicleRoute = (id: string) => `${RIDES}/${id}/driver-vehicle`;

export const getToggleDriverStatusRoute = (id: string) => `${DRIVERS}/${id}/activation-status `;

export const getAirlineIataImage = (imageName: string, imageWidth = 300, imageHeight = 50) =>
    `${AIRLINE_IATA_IMAGE_BASE_URL}/${imageWidth}/${imageHeight}/${imageName.toUpperCase()}.png`;

export const getUserRoute = (id: string) => `${USERS}/${id}`;

export const getRevokeUserRoute = (id: string) => `${INVITED_USERS}/${id}/revoke`;

export const getUpdateUserStatusRoute = (id: string) => `${USERS}/${id}/status`;

export const getFileRoute = (id: string) => `${FILES}/${id}`;

export const getDownloadRideOrderRoute = (id: string) => `${DOWNLOAD_RIDE_ORDER.replace(RIDE_ID_PARAM, id)}`;

export const getAssignDriverToRide = ({ rideId, driverId }: AssignDriverToRide) =>
    `${DRIVERS}/${driverId}/assign-to-trip/${rideId}`;

export const getUnassignDriverFromRide = (rideId: string) => `${RIDE.replace(RIDE_ID_PARAM, rideId)}/unassign-driver`;

export const getConfirmRideRoute = (id: string) => `${RIDE.replace(RIDE_ID_PARAM, id)}/confirm`;

export const getCloseRideRoute = (id: string) => `${RIDE.replace(RIDE_ID_PARAM, id)}/close`;

export const getRideAddRemarksRoute = (id: string) => `${RIDE.replace(RIDE_ID_PARAM, id)}/add-remarks`;

export const assignVehicleAndDriverRoute = (id: string) => `${RIDE.replace(RIDE_ID_PARAM, id)}/assign`;

export const getSupplierInvoiceFile = (invoiceNumber: string, fileType: FileType) => {
    return `${config.apiUrl}${ROUTE_PREFIX}supplier-invoices/${invoiceNumber}/${fileType}`;
};

export const getSearchBrands = (search: string, limit?: number) => `${VEHICLE_BRANDS}?search=${search}`;
export const getSearchVehicles = (search: string, limit?: number) =>
    `${VEHICLES}?search=${search}${limit ? `&limit=${limit}` : ''}`;
export const getFindVehicleRoute = (search: string) => `${VEHICLES}/find-by-license-plate/${search}`;
export const getVehicleRoute = (id: number) => `${VEHICLES}/${id}`;
export const getDriversRoute = () => `${DRIVERS}`;
export const getDriversSearch = (search?: string) => `${DRIVERS_SEARCH}?search=${search}`;

export const IMAGE_UPLOAD = `${ROUTE_PREFIX}image-upload`;
