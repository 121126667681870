/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import PersonRemoveAlt1OutlinedIcon from '@mui/icons-material/PersonRemoveAlt1Outlined';
import StarIcon from '@mui/icons-material/Star';
import { Box, Typography, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import {
    GridRenderCellParams,
    GridRowParams,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridActionsCellItem,
    GridRowId,
} from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import useDataGridStyles from '../../../styles/DataGrid';
import theme from '../../../styles/theme';
import SmallVehicleImage from '../../vehicles/components/SmallVehicleImage';
import { ChangeStatus, Driver } from '../api/types';

const useStyles = makeStyles(styleTheme => ({
    driverNameMobile: {
        fontWeight: '700',
        fontSize: '1rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingLeft: '0.375rem',
    },
}));

const COLUMN_WIDTH = 200;
const COLUMN_MOBILE_WIDTH = 150;

export const useDriversColumns = (onToggleStatus: (driver: Driver) => void, onEdit: (driver: Driver) => void) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dataGridClases = useDataGridStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { useDriverProfilePicture } = useFlags();

    const onActionItemClick = useCallback(
        (driver: Driver, action: ChangeStatus) => {
            switch (action) {
                case ChangeStatus.EDIT_DRIVER:
                    onEdit(driver);
                    break;
                case ChangeStatus.DEACTIVATE_DRIVER:
                case ChangeStatus.ACTIVATE_DRIVER:
                    onToggleStatus(driver);
                    break;
                default:
                    throw new Error('Driver action not supported');
            }
        },
        [onEdit, onToggleStatus]
    );

    return useMemo(() => {
        const columns: any = [
            {
                field: 'name',
                headerName: t('name'),
                minWidth: isMobile ? COLUMN_MOBILE_WIDTH : 400,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                    const rating = (params.row as Driver).feedbacks?.averageRate;

                    return (
                        <Box display="flex">
                            <Typography className={clsx({ [classes.driverNameMobile]: isMobile })}>{params.row.name}</Typography>
                            {rating && (
                                <>
                                    <Typography paddingLeft="0.625rem" color={COLORS.SLATE_GREY}>
                                        {rating.toFixed(1)}
                                    </Typography>
                                    <StarIcon
                                        style={{
                                            color: COLORS.SLATE_GREY,
                                            fontSize: '1.25rem',
                                            padding: '0 0.25rem',
                                        }}
                                    />
                                    <Typography color={COLORS.SLATE_GREY}>({(params.row as Driver).feedbacks?.count})</Typography>
                                </>
                            )}
                        </Box>
                    );
                },
            },
            {
                field: 'phoneNumber',
                headerName: t('form.fields.phoneNumber'),
                minWidth: isMobile ? COLUMN_MOBILE_WIDTH : COLUMN_WIDTH,
                flex: 1,
            },
            {
                field: 'status',
                headerName: t('status'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRowParams) => (
                    <Typography>{t((params.row as Driver).isDeactivated ? 'deactivated' : 'active')}</Typography>
                ),
            },
            {
                field: 'rideAssigned',
                headerName: t('pages.drivers.rideAssigned'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRowParams) => <Typography>{(params.row as Driver).tripsCount ?? 0}</Typography>,
            },
            {
                field: 'actions',
                type: 'actions',
                width: 20,
                headerClassName: dataGridClases.hideSeparator,
                getActions: (params: GridRowParams) => {
                    const driver = params.row as Driver;

                    return [
                        <GridActionsCellItem
                            key={ChangeStatus.EDIT_DRIVER}
                            className={dataGridClases.menuItem}
                            icon={<CreateOutlinedIcon style={{ color: COLORS.BLUE_DARK }} />}
                            label={t('buttonName.edit')}
                            onClick={() => onActionItemClick(driver, ChangeStatus.EDIT_DRIVER)}
                            showInMenu
                            onResize={undefined}
                            onResizeCapture={undefined}
                            nonce=""
                        />,
                        <GridActionsCellItem
                            key={driver.isDeactivated ? ChangeStatus.ACTIVATE_DRIVER : ChangeStatus.DEACTIVATE_DRIVER}
                            className={dataGridClases.menuItem}
                            icon={
                                driver.isDeactivated ? (
                                    <PersonAddAlt1OutlinedIcon style={{ color: COLORS.BLUE_DARK }} />
                                ) : (
                                    <PersonRemoveAlt1OutlinedIcon style={{ color: COLORS.BLUE_DARK }} />
                                )
                            }
                            label={t(driver.isDeactivated ? 'buttonName.activate' : 'buttonName.deactivate')}
                            onClick={() =>
                                onActionItemClick(
                                    driver,
                                    driver.isDeactivated ? ChangeStatus.ACTIVATE_DRIVER : ChangeStatus.DEACTIVATE_DRIVER
                                )
                            }
                            showInMenu
                            onResize={undefined}
                            onResizeCapture={undefined}
                            nonce=""
                        />,
                    ];
                },
            },
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                headerClassName: dataGridClases.hideSeparator,
                renderCell: (params: { id: GridRowId; value: boolean }) => (
                    <MobileViewPanelToggle id={params.id} value={params.value} />
                ),
            },
        ];

        if (useDriverProfilePicture) {
            columns.unshift({
                field: 'photo',
                headerName: '',
                width: 42,
                renderCell: (params: GridRowParams) => <SmallVehicleImage photo={params.row?.pictureUrl} boxSize="36px" />,
            });
        }

        return columns;
    }, [
        t,
        isMobile,
        dataGridClases.hideSeparator,
        useDriverProfilePicture,
        dataGridClases.menuItem,
        classes.driverNameMobile,
        onActionItemClick,
    ]);
};

function MobileViewPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
    const { value: isExpanded } = props;

    return (
        <IconButton size="small" tabIndex={-1} aria-label={isExpanded ? 'Close' : 'Open'}>
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: () => theme.transitions.create('transform', { duration: theme.transitions.duration.shortest }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}
