import { IconedData } from '@get-e/react-components';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import LocalTaxiOutlinedIcon from '@mui/icons-material/LocalTaxiOutlined';
import { Typography, useMediaQuery, Box } from '@mui/material';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../../../constants/colors';
import theme from '../../../../../../styles/theme';
import { RideSummaryAccordions } from '../RideSummaryCardContext';
import informationSummaryStyles from './InformationSummary.styles';
import SummaryAccordion from './SummaryAccordion';

interface VehicleInformationSummaryProps {
    unid: keyof typeof RideSummaryAccordions;
    vehicleClass?: string;
    vehicleType?: string;
    vehicleModel?: string;
    meetingInstructions?: string;
    vehicleImageUrl?: string;
    displayAsAcccordion?: boolean;
}

const VehicleInformationSummary = ({
    unid,
    vehicleClass,
    vehicleType,
    vehicleModel,
    meetingInstructions,
    vehicleImageUrl,
    displayAsAcccordion = true,
}: VehicleInformationSummaryProps) => {
    const classes = informationSummaryStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const vehicleClassAndType = useMemo(
        () => `${vehicleClass ?? ''} ${vehicleType ?? ''} ${vehicleModel ? `(${vehicleModel})` : ''}`,
        [vehicleClass, vehicleModel, vehicleType]
    );

    const content = useMemo(
        () => (
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" justifyContent="space-between">
                <Box width="100%">
                    <IconedData
                        icon={LocalTaxiOutlinedIcon}
                        dataLabel={`${t('model')}:`}
                        data={vehicleClassAndType}
                        dataLabelColor={COLORS.SLATE_GREY}
                    />
                    <IconedData
                        icon={HandshakeOutlinedIcon}
                        data={meetingInstructions ?? t('notAvailable')}
                        dataLabel={`${t('pages.rides.pickupInstructions')}:`}
                        dataLabelColor={COLORS.SLATE_GREY}
                    />
                </Box>
                <img
                    src={vehicleImageUrl}
                    alt="Vehicle"
                    className={clsx(classes.vehicleImage, { [classes.vehicleImageMobile]: isMobile })}
                />
            </Box>
        ),
        [classes.vehicleImage, classes.vehicleImageMobile, isMobile, meetingInstructions, t, vehicleClassAndType, vehicleImageUrl]
    );

    return isMobile && displayAsAcccordion ? (
        <SummaryAccordion unid={unid} summaryTitle={t('vehicle')} detailsContent={content} />
    ) : (
        <>
            <Typography variant="h3" component="h4" className={classes.subtitle}>
                {t('vehicle')}
            </Typography>
            {content}
        </>
    );
};

export default VehicleInformationSummary;
