import { parse, isValid, format } from 'date-fns';

import { Validated } from '../Validator';

const isValidDateFormat = <TError>(value: Date | null, dateFormat: string, error: TError): Validated<Date, TError> => {
    const isValidDate = isValid(value);
    const formattedDate = isValidDate && value ? format(value, dateFormat) : null;

    const parsedDate = formattedDate ? parse(formattedDate, dateFormat, new Date()) : null;

    const isValidFormattedDate = parsedDate ? isValid(parsedDate) : false;

    return value === null || !isValidFormattedDate
        ? {
              isValid: false,
              error,
          }
        : {
              isValid: true,
              value,
          };
};

export default isValidDateFormat;
