import React, { FunctionComponent } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { CustomerExportProps } from '../../pages/customerExport/CustomerExport';
import PageContainer from '../../pages/pageContainer/PageContainer';
import { UserRoles } from '../../pages/users/api/types';
import { AccountType } from '../../services/types';

interface PrivateRouteProps {
    path: string;
    component: React.FunctionComponent<RouteComponentProps & CustomerExportProps>;
    mainClassName?: string;
    allowedAccountType?: AccountType[];
    allowedUserRoles?: UserRoles[];
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    component: Component,
    mainClassName,
    allowedAccountType,
    allowedUserRoles,
    ...rest
}) => (
    <Route
        {...rest}
        render={(props): JSX.Element => (
            <PageContainer
                mainClassName={mainClassName}
                allowedAccountType={allowedAccountType}
                allowedUserRoles={allowedUserRoles}
            >
                <Component {...props} />
            </PageContainer>
        )}
    />
);

export default PrivateRoute;
