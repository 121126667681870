import { TextField, ModalAction as Modal } from '@get-e/react-components';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { Checkbox, FormControlLabel, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MuiTelInput } from 'mui-tel-input';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import {
    PASSENGERS_MODAL_FIRST_NAME,
    PASSENGERS_MODAL_EMAIL,
    PASSENGERS_MODAL_LAST_NAME,
    PASSENGERS_MODAL_PHONE,
    PASSENGERS_MODAL_ADD_PASSENGER,
} from '../../../constants/amplitude/customerKeys';
import { getCountryCode } from '../../../helpers/getCountryCode';
import { InputError } from '../../../helpers/inputValidation/InputError';
import and from '../../../helpers/inputValidation/validators/and';
import isEmail from '../../../helpers/inputValidation/validators/isEmail';
import isFilledString from '../../../helpers/inputValidation/validators/isFilledString';
import { RideContext } from '../customer/context/RideContext';
import { Traveller } from './travellerInformation/TravellerInformation';

const useStyles = makeStyles({
    formField: {
        marginBottom: '1rem',
        width: '100%',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    multipleSelectItem: {
        width: '100% !important',
        padding: '0.5rem !important',
        '& .MuiListItemText-root': { paddingLeft: '0.5rem !important' },
    },
});

interface AddTravellerErrors {
    firstName: InputError | null;
    lastName: InputError | null;
    email: InputError | null;
    phone: InputError | null;
}

interface AddTravellerModalProps {
    onClose: () => void;
    onSubmit: (values: Traveller) => void;
    isOpen: boolean;
    traveller?: Traveller;
}

const AddTravellerModal = ({ onClose, onSubmit, isOpen, traveller }: AddTravellerModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { amplitudeEventProperties } = useContext(RideContext);

    const [values, setValues] = useState<Traveller>(
        traveller ?? {
            id: uuidv4(),
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            isLead: false,
        }
    );

    const [formErrors, setFormErrors] = useState<AddTravellerErrors>({
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
    });

    const validateFields = (): boolean => {
        const validated = {
            firstName: isFilledString(values.firstName, InputError.Empty),
            lastName: isFilledString(values.lastName, InputError.Empty),
            email: and(isFilledString(values.email, InputError.Empty), () => isEmail(values.email, InputError.InvalidEmail)),
            phone: isFilledString(values.phone, InputError.Empty),
        };

        const fieldErros: AddTravellerErrors = {
            firstName: validated.firstName.isValid ? null : validated.firstName.error,
            lastName: validated.lastName.isValid ? null : validated.lastName.error,
            email: validated.email.isValid || !values.isLead ? null : validated.email.error,
            phone: validated.phone.isValid || !values.isLead ? null : validated.phone.error,
        };

        const isValid = Object.values(fieldErros).every(error => error === null);

        !isValid && setFormErrors(fieldErros);

        return isValid;
    };

    const handleSubmit = (): void => {
        if (!validateFields()) {
            return;
        }

        logAmplitudeEvent(PASSENGERS_MODAL_ADD_PASSENGER, amplitudeEventProperties);
        onSubmit(values);
    };

    const handleChange = <T extends keyof Traveller>(key: T, newValue: Traveller[T] & (string | boolean)): void => {
        setValues({
            ...values,
            [key]: newValue,
        });

        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            [key]: null,
        }));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title={t(traveller ? 'pages.rides.editPassenger' : 'pages.rides.addPassenger')}
            confirmButtonLabel={t(traveller ? 'buttonName.update' : 'buttonName.add')}
            cancelButtonLabel={t('buttonName.cancel')}
        >
            <TextField
                className={classes.formField}
                error={formErrors.firstName !== null}
                helperText={formErrors.firstName ? t(formErrors.firstName) : null}
                label={t('form.fields.firstName')}
                name="firstName"
                onChange={event => handleChange('firstName', event.target.value)}
                required
                autoComplete="off"
                value={values.firstName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
                onBlur={() =>
                    values.firstName !== '' ? logAmplitudeEvent(PASSENGERS_MODAL_FIRST_NAME, amplitudeEventProperties) : {}
                }
            />
            <TextField
                className={classes.formField}
                error={formErrors.lastName !== null}
                helperText={formErrors.lastName ? t(formErrors.lastName) : null}
                label={t('form.fields.lastName')}
                name="lastName"
                onChange={event => handleChange('lastName', event.target.value)}
                required
                autoComplete="off"
                value={values.lastName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
                onBlur={() =>
                    values.lastName !== '' ? logAmplitudeEvent(PASSENGERS_MODAL_LAST_NAME, amplitudeEventProperties) : {}
                }
            />
            <MuiTelInput
                style={{ marginBottom: formErrors?.phone !== null ? '1.25rem' : '2.5rem' }}
                value={values.phone}
                defaultCountry={getCountryCode()}
                label={t('form.fields.mobile')}
                className={classes.formField}
                name="mobile"
                onChange={value => handleChange('phone', value)}
                required={values.isLead}
                error={values.isLead && formErrors?.phone !== null}
                helperText={values.isLead && formErrors?.phone && t(formErrors.phone)}
                variant="filled"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PhoneOutlinedIcon />
                        </InputAdornment>
                    ),
                }}
                onBlur={() => (values.phone !== '' ? logAmplitudeEvent(PASSENGERS_MODAL_PHONE, amplitudeEventProperties) : {})}
            />
            <TextField
                className={classes.formField}
                error={values.isLead && formErrors.email !== null}
                helperText={values.isLead && formErrors.email ? t(formErrors.email) : null}
                label={t('email')}
                name="email"
                onChange={event => handleChange('email', event.target.value)}
                required={values.isLead}
                autoComplete="off"
                value={values.email}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <EmailOutlinedIcon />
                        </InputAdornment>
                    ),
                }}
                onBlur={() => (values.email !== '' ? logAmplitudeEvent(PASSENGERS_MODAL_EMAIL, amplitudeEventProperties) : {})}
            />
            <FormControlLabel
                control={<Checkbox checked={values.isLead} onChange={event => handleChange('isLead', event.target.checked)} />}
                label={t('pages.rides.mainPassenger')}
                sx={{
                    width: '100%',
                    marginRight: 0,
                }}
            />
        </Modal>
    );
};

export default AddTravellerModal;
