import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { COLORS } from '../../../constants/colors';
import theme from '../../../styles/theme';
import { useRide } from '../api/useRide';
import RideHeader from './components/RideHeader';
import RideViewLoadingSkeleton from './components/RideViewLoadingSkeleton';
import { renderDesktopView, renderMobileView } from './helpers/renderRideView';

const useStyles = makeStyles(styleTheme => ({
    contentContainer: {
        paddingLeft: '2rem',
        marginBottom: '0.5rem',
        [styleTheme.breakpoints.down('lg')]: { padding: '0' },
    },
    cancellationPolicies: {
        marginTop: '1.5rem',
        width: '96%',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '0.5rem',
        padding: '1rem',
        marginLeft: '2rem',
        position: 'relative',
        [styleTheme.breakpoints.down('lg')]: {
            marginLeft: '0',
            width: '100%',
        },
        '& h4': { color: `${COLORS.BLUE} !important` },
    },
    googleMapContainer: {
        height: '20rem',
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '1rem',
        borderRadius: '.5rem',
        overflow: 'hidden',
        '& > *': {
            borderRadius: '.5rem',
            overflow: 'hidden',
        },
    },
    googleMapContainerMobile: {
        height: '12.5rem',
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '1rem',
        marginBottom: '-1rem',
        '& > *': {
            borderTopLeftRadius: '.5rem',
            borderTopRightRadius: '.5rem',
            overflow: 'hidden',
        },
    },
}));

const RideView = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { rideId }: { rideId: string } = useParams();
    const { data, refetch, isLoading } = useRide(rideId);
    const [showUtcTime, setShowUtcTime] = useState(false);

    if (isLoading) {
        return <RideViewLoadingSkeleton />;
    }

    return (
        <>
            <RideHeader data={data} refetch={refetch} rideId={rideId} />
            {isMobile
                ? renderMobileView(data, classes, refetch, showUtcTime, setShowUtcTime)
                : renderDesktopView(data, classes, refetch, showUtcTime, setShowUtcTime)}
        </>
    );
};

export default RideView;
